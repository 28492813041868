import React from "react";
import "../../CSS/SecondScreen.css";
import container from "../../assets/container.png";
import shop from "../../assets/shop.png";
import vegetables from "../../assets/vegetables.png";
import { CardComponent } from "../CommonComponents/CardComponent";
import HeaderTextComponent1 from "./HeaderAndText/HeaderTextComponent1";
import { useSelector } from "react-redux";

export default function ThirdSection({navigate}){
    const {internationalization} = useSelector(state=>state.language)
    const cardData = [
        {href:'aboutbuyer',image: vegetables, title:internationalization.buyer, content:internationalization.buyer_detail},
        {href:'aboutseller',image: shop, title:internationalization.seller, content:internationalization.seller_detail},
        {href:'aboutvas',image: container, title:internationalization.value_added_services, content:internationalization.value_added_services_detail},
    ]

    return(
        <div
            id="LearnMore"
            className="div-height"
            style={{
                backgroundColor:'rgba(217, 217, 217, 0.2)'
                }}
        >
            <HeaderTextComponent1 
                    header={internationalization.businesses_ecosystem_stakeholders} 
                    textContent={internationalization.bes_detail}
             />
                                  
            <div className="card-img-layout">
                {cardData.map((data, key)=>(
                    <CardComponent key={key} image={data.image} title={data.title} content={data.content} href={data.href} navigate={navigate}/>
                ))}
            </div>
    </div>
    )
}