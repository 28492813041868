import React, { useEffect, useState } from 'react';
import ScreenHeader from '../../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from '../ScreenLayout';
import moment from 'moment';
import userService from '../../Services/user.service';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../../CSS/TableComponent.css';

function SelectProductForAuction() {
  const navigate = useNavigate();
  const {user} = useSelector(state=>state.auth);
  const [product, setProduct] = useState();

  useEffect(()=>{
    userService.getProductByUserId()
         .then((response)=>{
          setProduct(response.data)
         })
         .catch((error)=>{
          console.log(error)
         })
  },[1])


  const ProductListComponent=()=>{
    return(
       <div>
       <ScreenHeader headerTitle={'Select Product For Auction'}/>
       <hr style={{width:'100%'}}/>
        <p style={{fontSize:20, fontWeight:500, }} className="dashboard-product-list-marginLeft">
          All Product({product && product.length})
        </p>
        <hr width={'100%'} className="hr-line-all-prod" />
        <div>
          <p style={{fontSize:15, fontWeight:500, }} className="dashboard-product-list-marginLeft">Select any of your product to add in auctioning</p>
        </div>
        <div className='add-product-table-div'>
            <table className="table m-2 add-product-table w-100">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Stock</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Price</th>
                  <th scope="col">Date</th>
                  <th scope="col">Auction</th>
                </tr>
              </thead>
             
              {product && product.length===0 && <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>No Product Added for Auction.</td>}
              <tbody>
               { product && product.map((product,key)=>(
                <tr key={key} className={key%2==0?'table-even-row':'table-odd-row'}>
                    <td><img src={product.newProduct.icon} alt="..." style={{width:35, height:35, borderRadius:5}}/> </td>
                    <td>{product.product}</td>
                    <td>{product.count_in_stock}</td>
                    <td>{product.unit}</td>
                    <td>Rs.{product.price_min} - {product.price}</td>
                    <td>{moment(product.created_at).utc().format('DD/MM/YYYY')}</td>
                    <td>
                      <button
                       className='order-buttons'
                       type="button"
                       onClick={()=> product.in_auction ? navigate('auctionview', {state:{productId:product.id, productName:product.product}}) : navigate('addtoauction',{state:{productId:product.id}})}
                      >
                        { product.in_auction ? 'view' : 'Auction'}
                      </button>
                    </td>
                </tr>
               ))
               }
              </tbody>
            </table>
        </div>
        <Outlet/>
       </div>
    )
  }
  return (
    <ScreenLayout component={<ProductListComponent/>} />
  );
}

export default SelectProductForAuction;
