import React, {useEffect} from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import userService from '../../Services/user.service';
import { useState } from 'react';

// const BASE_URL_HOSTED = "http://127.0.0.1:8000/";
const BASE_URL_HOSTED = "https://seller-studio-test-dot-cropwaybackend.el.r.appspot.com/";

const Razorpay = ({orderEnquiryId, amount}) => {
  const [loading, setLoading] = useState(false)
    const RAZORPAY_KEY_ID = 'rzp_test_JOC0wRKpLH1cVW';
    const {user} = useSelector(state=>state.auth)
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        }, []);

    const UpdateOrderEnquiry=(e)=>{
        e.preventDefault()
        console.log(e.target.value, orderEnquiryId)
        let data ={
            order_enquiry_id : orderEnquiryId,
            status : e.target.value
        }
    
        userService.updateOrderEnquiryStatus(data)
                    .then((res)=>{
                    alert('payment success')
                    console.log(res.data)
                    window.location.reload()
                    })
                    .catch((error)=>{
                    console.log(error)
                    })
        }

    const RZpay = async (e) => {
      setLoading(true)
        const RZdata = {
          userInfo: user,
          amount: amount * 100,
        };
        let order_id = ''
        await axios
          .post(`${BASE_URL_HOSTED}api/sellerstudio/razorpay/`, RZdata)
          .then((res) => {
            order_id = res.data["order_id"];
            // dataUpload(order_id, paymentMethod); //upload the data in order table with payment order_id (razorpay or paytm) and payment method.
    
            const options = {
              key: RAZORPAY_KEY_ID,
              amount: RZdata.amount, //  100 paise = INR 1
              name: "Seller Studio Checkout",
              description: "",
              image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_large.png",
              order_id: order_id,
              handler: async function (response) {
                let razordata = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  order_id: order_id,
                };
                let status = false;
                // setLoading(true);
                await axios
                  .post(`${BASE_URL_HOSTED}api/sellerstudio/razorpay/status/`, razordata)
                  .then((res) => {
                    status = res.data["status"];
                    UpdateOrderEnquiry(e)
                    setLoading(false);
                    // history.push({
                    //   pathname: "/confirmation",
                    //   search: "?order_id=" + order_id,
                    //   state: { status: status, order_id: order_id },
                    // });
                  })
                  .catch((error) => {
                    setLoading(false);
                    // history.push({
                    //   pathname: "/confirmation",
                    //   search: "?query=" + error,
                    //   state: { status: false, order_id: order_id },
                    // });
                  });
              },
              prefill: {
                name: RZdata.name,
                contact: RZdata.userInfo.phone,
                email: RZdata.userInfo.email,
              },
              notes: {
                address: "Test Address",
              },
              theme: {
                color: "black",
                hide_topbar: false,
              },
            };
    
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
          })
          .catch(async (error) => {
            console.log(error)
            // var crypto = require("crypto");
            // var id = crypto.randomBytes(10).toString("hex");
            // dispatch(
            //   createOrder({
            //     // order_id: "order_id_not_created_" + id,
            //     orderItems: CartItems,
            //     shippingAddress: ShippingAddress,
            //     billingAddress: BillingAddress,
            //     paymentMethod: paymentMethod,
            //     itemsPrice: itemPrice,
            //     shippingPrice: shippingPrice,
            //     // taxPrice: tax,
            //     totalPrice: totalPrice,
            //   })
            // );
    
            // history.push({
            //   pathname: "/confirmation",
            //   search: "?order_id=" + "order_id_not_created! " + error,
            //   state: { status: false, order_id: "order_id_not_created_" + id },
            // });
          });
        // setLoading(false);
        //console.log("after  RZP paytmpay::>");
      };

  return (
    <>
      {!loading ? <button className='sign-button' value='Confirmed' onClick={(e)=>RZpay(e)} >Make Payment</button> : 'Loading...'}
    </>
  )
}

export default Razorpay