import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import userService from '../../Services/user.service'
import auctionService from '../../Services/auction.service'
import moment from 'moment'
import { useSelector } from 'react-redux'

const CategoryDetailCard = () => {
  const {user} = useSelector(state=> state.auth)
  const [productToBuy, setProductToBuy] = useState([]);
  const [productForSell, setProductForSell] = useState([]);
  const [auctionProduct, setAuctionProduct] = useState([]);
  const [loading, SetLoading] = useState(false);
  const navigate = useNavigate();
  const { internationalization } = useSelector(state=>state.language)

  useEffect(() => {
    SetLoading(true);
    userService
      .getSellProduct()
      .then((response) => {
        setProductToBuy(response.data);
        SetLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetLoading(false);
      });

    userService
      .getRequirementProduct()
      .then((response) => {
        setProductForSell(response.data);
        SetLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetLoading(false);
      });

      user && auctionService
      .getAuctionProduct()
      .then((response) => {
        setAuctionProduct(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [1]);

  return (
    <div>
      {productToBuy.length > 0 && (
        <>
          <h2 className="text-center" style={{ paddingTop: "50px" }}>
            <b>{internationalization.latest_product}{"-("+internationalization.to_buy+")"}</b>
          </h2>
          {loading && <h3>Loading...</h3>}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              cursor: "pointer",
            }}
          >
            {productToBuy &&
              productToBuy.slice(0, 12).map((product, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 260,
                    height: 150,
                    margin: 10,
                    padding: 10,
                    background: "white",
                    border: "0.1rem solid grey",
                    borderRadius: 10,
                  }}
                  onClick={() => {
                    navigate("/dashboard/productview", {
                      state: {
                        productId: product.id,
                        productName: product.product,
                      },
                    });
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      src={product.newProduct.icon}
                      style={{ width: 100, height: 100, borderRadius: 10 }}
                    />
                  </div>
                  <div style={{ width: "50%", whiteSpace: "nowrap" }}>
                    <div>{product.product}</div>
                    <div style={{overflow:'hidden',textOverflow:'ellipsis'}}>{product.productDetail.product_type}</div>
                    <div>{product.count_in_stock ? product.count_in_stock + ' ' +product.unit : null}</div>
                    <div>{product.count_in_stock && product.price ? 'Rs. '+product.count_in_stock * product.price : null }</div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}

      {productForSell.length > 0 && (
        <>
          <h2 className="text-center" style={{ paddingTop: "50px" }}>
            <b>{internationalization.latest_product}{"-("+internationalization.to_sell+")"}</b>
          </h2>
          {loading && <h3>Loading...</h3>}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              cursor: "pointer",
            }}
          >
            {productForSell &&
              productForSell.slice(0, 12).map((product, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 260,
                    height: 150,
                    margin: 10,
                    padding: 10,
                    background: "white",
                    border: "0.1rem solid grey",
                    borderRadius: 10,
                  }}
                  onClick={() => {
                    navigate("/dashboard/requirementproductview", {
                      state: {
                        productId: product.id,
                        productName: product.product,
                      },
                    });
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      src={product.newProduct.icon}
                      style={{ width: 100, height: 100, borderRadius: 10 }}
                    />
                  </div>
                  <div style={{ width: "50%", whiteSpace: "nowrap" }}>
                    <div>{product.product}</div>
                    <div>{product.productDetail.product_type}</div>
                    <div>{product.count_in_stock ? product.count_in_stock + ' ' +product.unit : null}</div>
                    <div>Rs.{product.count_in_stock * product.price}</div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}

      {auctionProduct.length > 0 && (
        <>
          <h2 className="text-center" style={{ paddingTop: "50px" }}>
            <b>Auction Product</b>
          </h2>
          <div
           style={{display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
            cursor: 'pointer'}}
          >
            {loading && <h3>Loading...</h3>}
            {auctionProduct &&
              auctionProduct.slice(0, 5).map((data, key) => (
                <div
                  key={key}
                  style={{
                    padding:5,
                    background: "#BBEEF0",
                    height: 320,
                    width: 250,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    margin: 10,
                    cursor: "pointer",
                    
                  }}
                >
                  <div style={{ fontWeight: 500 }}> 
                    {data.product.product} (
                    {data.product.productDetail.product_type})
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 12, color: "#5DA7CA" }}>
                      starting price
                    </div>
                    {data.starting_price}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 12, color: "#5DA7CA" }}>
                      current price
                    </div>
                    {data.current_amount ? data.current_amount : "Not started"}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 12, color: "#5DA7CA" }}>
                      start date
                    </div>
                    {moment(data.start_date_time)
                      .utc()
                      .format("DD/MM/YYYY - hh:mm:ss")}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 12, color: "#5DA7CA" }}>
                      end date
                    </div>
                    {moment(data.end_date_time)
                      .utc()
                      .format("DD/MM/YYYY - hh:mm:ss")}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 12, color: "#5DA7CA" }}>
                      upload date
                    </div>
                    {moment(data.created_at)
                      .utc()
                      .format("DD/MM/YYYY - hh:mm:ss")}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 12, color: "#5DA7CA" }}>
                      auction type
                    </div>
                    {data.auction_type}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryDetailCard;
