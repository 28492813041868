import React from 'react';
import "../CSS/Menu.css";
import Menu from '../Components/HomeScreenComponents/Menu';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';
function HomeScreen() {
  const navigate = useNavigate()

return(
      <div className='show-desktop'>
            <Menu navigate={navigate}/>
            <Outlet/>
      </div>
    );
  }


export default HomeScreen;
