import React from 'react'
import fadedLogo from '../assets/Cropway -faded.png'
import './ScreenLayout.css'

export const ScreenLayout = ({component, props}) => {
  return (
    <div className='layout-dashboard'>
      <div style={{height:'fit-content', padding:2, paddingBottom:30}}>
          {component}
      </div>
      <div className='background-logo'>
        <img src={fadedLogo}/>
      </div>
    </div>
  )
}
