import React, { useState } from "react";
import "../../CSS/SevanthSection.css";
import userService from "../../Services/user.service";
import { useSelector } from "react-redux";

export default function SevanthSection() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const {internationalization} = useSelector(state=>state.language)

  const handleSubmit=(e)=>{
    e.preventDefault()
    let Data={
      name:name,
      email:email,
      phone:phone,
      message:message
    }

    userService.postGetInTouch(Data)
              .then(()=>{
                alert('Message send')
                window.location.reload()
              })
             .catch((error)=>{
              alert('something went wrong, please try again later.')
             })
  }

  return (
      <div className="sev-background">
        <div className="sev-mainbox">
          <div className="sev-left">
            <h1 className="our-tech-second-img-heading"><b>{internationalization.get_in_touch}</b></h1>
            <p className="our-tech-second-img-text">{internationalization.leave_us_message}</p>
          </div>
          <div className="sev-right">
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="exampleInputName">{internationalization.name}</label>
                    <input
                    type="text"
                    className="form-control leave-us-msg-form-width"
                    id="exampleInputname"
                    placeholder={internationalization.name}
                    minLength={5}
                    required
                    onChange={(e)=>setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{internationalization.email}*</label>
                    <input
                    type="email"
                    className="form-control leave-us-msg-form-width"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={internationalization.enter_email}
                    required
                    onChange={(e)=>setEmail(e.target.value)}
                    />
                
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">{internationalization.contact_number}*</label>
                    <input
                    type="number"
                    className="form-control leave-us-msg-form-width"
                    id="exampleInputNumber"
                    placeholder={internationalization.number}
                    required
                    minLength={10}
                    onChange={(e)=>setPhone(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                    {internationalization.your_message}*
                    </label>
                    <textarea
                    className="form-control leave-us-msg-form-width"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    required
                    minLength={10}
                    onChange={(e)=>setMessage(e.target.value)}
                    />
                </div>
                <button type="submit" className="sev-btn">
                    {internationalization.send_message}
                </button>
                </form>
             
        </div>
      </div>
    </div>
  );
}
