import React from "react";
import "../../../CSS/mainFrame.css";
export default function FirstViewScreen({image, header, textContent}){
    return(
             <div style={{height:'93vh'}}>
                <img src={image}
                    className="img-layout-buyer-seller"
                />
                <div className="header-content-buyer-seller">
                    <div className="header-buy-sell">
                        {header}
                    </div>
                    <div className="text-content-buyer-seller" style={{whiteSpace:'pre-line'}}>
                        {textContent}
                    </div>
                </div>
            </div>
    )
}