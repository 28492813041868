import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  const {internationalization} = useSelector(state=>state.language)
    const TextComponent=({ header, href, childHeader })=>{
        return(
            <div style={{margin:"10px"}}>
            <div className="text-uppercase footer-home-page-heading">{header}</div>
            <ul className="list-unstyled">
           { childHeader.map((child, index)=>(
              <li key={index}>
                <a
                  href={href[index]}
                  target="_blank"
                  className="text-white footer-home-page-paraa"
                 
                >
                  {child}
                </a>
              </li>
            ))}
            </ul>
          </div>
        )
    }
    const SocialMediaComponent=({href, logoClass})=>{
        return(
          <a
          style={{color:'#ffffff', margin:'2vw', cursor:'pointer', textDecoration:'none'}}
          href={href}
          role="button"
        >
            <div  className="footer-social-logo-div">
              <i className={`${logoClass} footer-social-logo-div-logo`} />
            </div>
          </a>
        )
      }
      return (
        <footer className="text-center text-white" style={{backgroundColor:'#52B047'}}>
        <div className="container p-4">
          <section style={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
              <SocialMediaComponent href={"https://www.facebook.com/Cropway-103542688746807/"} logoClass={"fab fa-facebook-f"} />
              <SocialMediaComponent href={"https://twitter.com/cropway_in"} logoClass={"fab fa-twitter"} />
              <SocialMediaComponent href={"mailto:info@cropway.com"} logoClass={"fab fa-google"} />
              <SocialMediaComponent href={"https://www.instagram.com/cropway__in/"} logoClass={"fab fa-instagram"} />
              <SocialMediaComponent href={"https://www.linkedin.com/company/cropway/"} logoClass={"fab fa-linkedin"} />
          </section>

            <div style={{ justifyContent:'space-around'}} className=" d-lg-flex">
                <TextComponent
                    header={internationalization.support}
                    href={["https://www.cropway.com/returnandrefundpolicy"]}
                    childHeader={[internationalization.return_and_exchange]}
                />
                <TextComponent 
                         header={internationalization.company}
                         href={["https://www.cropway.com/about/", "https://www.cropway.com/contact/"]}
                         childHeader={[internationalization.about_us, internationalization.contact_us]}
                />
                <TextComponent 
                         header={internationalization.legal}
                         href={["https://www.cropway.com/privacypolicy", "https://www.cropway.com/termsandconditions" ]}
                         childHeader={[internationalization.privacy_policy, internationalization.terms_and_conditions]}
                />
                <TextComponent 
                         header={internationalization.sell_with_us}
                         href={["https://www.cropway.com/termsandconditions", "https://www.cropway.com/agriculture-services/"]}
                         childHeader={[internationalization.sell_on_cropway, internationalization.avail_services]}
                />

            </div>
        </div>

        <div
          className="text-center p-3 footer-copy-right"
          styles="background-color: rgba(0, 0, 0, 0.2);"
          style={{fontSize:'1vw'}}
        >
          {`© 2023, `}
          <a className="text-white" href="https://www.cropway.com/">
            cropway.com
          </a>
        </div>
      </footer>
  );
}

export default Footer;