import React from "react";

export default function LastValueAddedDetailComponents({internationalization}) {
  return (
    <>
      <div
        style={{
          width: "50%",
          display:'flex',
          fontFamily: 'poppins',
        }}
      >
        <div
          className="last-value-added-details-main"
          >
          <div className="card last-value-added-details-main-div" style={{ background: "#E6F4F8" }}>
            <div className="card-body last-value-added-details-card-body" style={{whiteSpace:'pre-line'}}>
              <p>
                <b>{internationalization.vas_text_header_4}</b>
              </p>
              {internationalization.vas_text_4}
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
}
