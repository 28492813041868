// actions types

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Internationalization 
export const LANGUAGE_EN = "LANGUAGE_EN";
export const LANGUAGE_HI = "LANGUAGE_HI";