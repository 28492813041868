import React from 'react';
import { ScreenLayout } from './ScreenLayout';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';

function AddUserScreen() {

  const InputFieldData=[
    {
      lableName:'User Name',
      required:true,
      placeholder:'*required',
      type:'text',
    },
    {
      lableName:'Email',
      required:true,
      placeholder:'*required',
      type:'email',
    },
    {
      lableName:'First Name',
      required:false,
      placeholder:'',
      type:'text',
    },
    {
      lableName:'Last Name',
      required:false,
      placeholder:'',
      type:'text',
    },
    {
      lableName:'Website',
      required:false,
      placeholder:'',
      type:'text',
    },
  ]

  const InputComponent=({lableName, required, type, placeholder})=>{
    return(
      <div style={{width:'100%',borderRadius:5,height:40,display:'flex',alignItems:'center',margin:10}}>
        <label style={{width:'20%',margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>{lableName} :</label>
        <input 
          required={required} 
          style={{width:'40%',height:'100%',borderRadius:5,border:'1px solid #BDBDBD'}}
          type={type}
          placeholder={placeholder}
        />
      </div>
    )
  }
  const AddUserComponent=()=>{
    return (
    <>
      <ScreenHeader headerTitle={'Add New User'}/>
      <div style={{display:'flex',flexDirection:'column'}}>
        <form onSubmit={()=>console.log('sunmit')}>
        {InputFieldData.map((data, key)=>(
          <InputComponent
              key={key}
              lableName={data.lableName}
              required={data.required}
              type={data.type}
              placeholder={data.placeholder}
              onChange={e=>console.log(e.target.value)}
          />
        ))}

        <hr style={{width:'100%', }}/>

        <div style={{width:'100%',borderRadius:5,height:40,display:'flex',alignItems:'center',margin:10}}>
            <label style={{width:'20%',margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Password :</label>
            <input style={{width:'40%',height:'100%',borderRadius:5,border:'1px solid #BDBDBD'}} type='text' placeholder=''/>
            <button
                className='button-add'
                >
                Generate
            </button>
        </div>

        <div style={{width:'100%',borderRadius:5,height:40,display:'flex',alignItems:'center',margin:10}}>
            <label style={{width:'20%',margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Role :</label>
            <select style={{width:'40%',height:'100%',borderRadius:5,border:'1px solid #BDBDBD'}}>
              <option value=''>Select Role</option>
              <option>Shop Manager</option>
              <option>Contributor</option>
              <option>Administrator</option>
            </select>
        </div>

        <div style={{width:'100%',borderRadius:5,height:40,display:'flex',alignItems:'center',margin:10}}>
            <label style={{width:'20%',margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}/>
            <input id='notify' type='checkbox' style={{width:'1vw',height:'1vw',margin:'0.5vw'}}/>
            <div style={{fontStyle:'italic'}}>
              Notify new user about their account.
            </div>
        </div>

        <div style={{width:'46vw',borderRadius:5,height:40,display:'flex',alignItems:'center',justifyContent:'center',margin:10}}>
            <button
            type='submit'
             style={{width:'15vw',height:'3vw', fontSize: '1vw',
             borderRadius:5, border:'1px solid #5CA3C5',
             backgroundColor:'#5CA3C5',color:'#ffffff'}}
            >Add New User</button>
        </div>
        </form>
      </div>
    </>
    )
  }

  return (
    <ScreenLayout
    component={<AddUserComponent/> }
    />
  );
  
}

export default AddUserScreen;
