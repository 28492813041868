import React, { useEffect, useState } from 'react';
import { StatusButton } from './HSTableComponents/StatusButton';
import userService from '../../Services/user.service';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import '../../CSS/MarketPriceDashboard.css';

function ActiveListingSeller({navigate}) {
   const {user} = useSelector((state)=> state.auth)
   const {internationalization} = useSelector((state)=> state.language)
  const [orderEnquiryDetails, SetOrderEnquiryDetails] = useState([])
  const [orderEnquiryError, SetOrderEnquiryError] = useState('')
  const [loading, SetLoading] = useState(false)
  const [count, SetCount] = useState(true)

   useEffect(()=>{
         SetLoading(true)
         userService.getOrderEnquiry() //need to filter by user
         .then((res)=>{
               SetOrderEnquiryDetails(res.data)
               SetLoading(false)
            })
            .catch((error)=>{
               SetOrderEnquiryDetails([])
               SetOrderEnquiryError(error.message)
               SetLoading(false)
            })

      },[])
   
   // pagination
   const [currentItems, setCurrentItems] = useState(null);
   const [pageCount, setPageCount] = useState(0);
   const itemsPerPage = 5
   // Here we use item offsets; we could also use page offsets
   // following the API or data you're working with.
   const [itemOffset, setItemOffset] = useState(0);

   useEffect(() => {
     // Fetch items from another resources.
     const endOffset = itemOffset + itemsPerPage;
   //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     setCurrentItems(orderEnquiryDetails && orderEnquiryDetails.slice(itemOffset, endOffset));
     setPageCount(Math.ceil(orderEnquiryDetails && orderEnquiryDetails.length / itemsPerPage));
   }, [itemOffset, itemsPerPage, orderEnquiryDetails]);
 
   // Invoke when user click to request another page.
   const handlePageClick = (event) => {
     const newOffset = (event.selected * itemsPerPage) % orderEnquiryDetails.length;
   //   console.log(
   //     `User requested page number ${event.selected}, which is offset ${newOffset}`
   //   );
     setItemOffset(newOffset);
   };
   // pagination


   const ActiveListTable=({serialNo, orderEnquiryId, product, maxPrice, minPrice, quantity, unit, orderStatus})=>{
      return(
            <tr>
               <td className='table-data-cell'>{serialNo}</td>
               <td className='table-data-cell'>{product}</td>
               <td className='table-data-cell'>Rs. {maxPrice}</td>
               <td className='table-data-cell'>Rs. {minPrice}</td>
               <td className='table-data-cell'>{quantity}</td>
               <td className='table-data-cell'>{unit}</td>
               <td className='table-data-cell'>
               <StatusButton orderStatus={orderStatus} orderEnquiryId={orderEnquiryId} navigate={navigate}/>
               </td>
            </tr>
         )
      }

  return (
   <>
   <div className='live-market-price' >
      <text className='sub-text-header'>
         {internationalization.to_buy}
      </text>
      {/* <ScreenHeader  headerTitle={internationalization.to_buy} color={'green'}/> */}
         <table className='live-market-price-table' >
           <tr className='table-header' >
             <th className='market-price-table-heading'>#</th>
             <th className='market-price-table-heading'>{internationalization.commodity}</th>
             <th className='market-price-table-heading'>{internationalization.max_price}</th>
             <th className='market-price-table-heading'>{internationalization.min_price}</th>
             <th className='market-price-table-heading'>{internationalization.quantity}</th>
             <th className='market-price-table-heading'>{internationalization.unit}</th>
             <th className='market-price-table-heading'>{internationalization.status}</th>
          </tr>
          {
            loading &&
          <tr>
             <td colSpan='7' className='table-loading' >loading...</td>
         </tr>
          }
         {!loading && orderEnquiryError && <td className='recieved-enquiries-table-data3' >{orderEnquiryError}</td>}
         {count && <td className='recieved-enquiries-table-data4'>{internationalization.no_product}</td>}

       { currentItems && currentItems.map((order, key)=>{
         if(order.user !== user.id){
            if(count===true){
               SetCount(false)
            }
            return(
                  <ActiveListTable
                     key={key}
                     serialNo={key+1+itemOffset}
                     orderEnquiryId={order.id}
                     product={order.product && order.product.product} 
                     maxPrice={order.product && order.product.price}
                     minPrice={order.product && order.product.price_min}
                     quantity={order.required_quantity}
                     unit={order.unit}
                     orderStatus={order.order_status.status}
                  />
            )
         }
         })
         }
         </table>

         <ReactPaginate
            previousLabel={"<"+internationalization.previous}
            breakLabel="..."
            nextLabel={internationalization.next+">"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            renderOnZeroPageCount={null}
            className="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
        />
        </div>
      </>
  );
}

export default ActiveListingSeller;
