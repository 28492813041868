import React from 'react'
import '../../CSS/Header.css'
import cropwayLogo from '../../assets/CropwayLogo.png'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/auth'
import { LANGUAGE_HI, LANGUAGE_EN } from '../../actions/type'
import { updateLanguage } from '../../actions/language'

export default function WelcomeHeader() {
  const navigate = useNavigate()
  const [click, setClick] = useState(false)
  const {user} = useSelector(state=>state.auth)
  const {lang, internationalization} = useSelector(state=>state.language)
  const dispatch = useDispatch()

  const languageHandler=(e)=>{
    console.log(e.target.value)
    dispatch(updateLanguage(e.target.value))
    
  }
  return (
      <nav>
          <a className="nav-logo">
            <img src={cropwayLogo} alt="img..." onClick={() => navigate('/')}/>
          </a>
            <ul className={click ? 'menu-navbar-section active' : 'menu-navbar-section'}>
              <li>
                <a href="/" className="nav-link active">
                  {internationalization.home}
                </a>
              </li>
              <li>
                <a
                  href="https://www.cropway.com/our-story/"
                  className="nav-link"
                >
                  {internationalization.about_us}
                </a>
              </li>
              <li>
                <a href="login" className="nav-link">
                  {internationalization.buy_sell}
                </a>
              </li>
              <li>
                <a href="https://www.cropway.com/contact/" className="nav-link">
                  {internationalization.contact}
                </a>
              </li>
              <li>
                <select onChange={(e)=>languageHandler(e)} defaultValue={lang} style={{border:'none', backgroundColor:'transparent'}}>
                  <option value={undefined}>{internationalization.language}</option>
                  <option value={LANGUAGE_HI}>{internationalization.hindi}</option>
                  <option value={LANGUAGE_EN}>{internationalization.english}</option>
                </select>
              </li>
              <li>
                {
                  user ? 
                  <a onClick={()=>dispatch(logout())} className="nav-link">
                    {internationalization.logout}
                  </a>
                  :
                  <a href="login/" className="nav-link">
                    {internationalization.login}
                  </a>
                }
              </li>
            </ul>
            {/* burger-bar */}
            <div className='mobile' onClick={() => setClick(!click)}>
              <i id="bar" className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
      </nav>
  )
}
