import React, { useEffect, useState } from 'react';
import { ScreenLayout } from '../ScreenLayout';
import ScreenHeader from '../../Components/CommonComponents/ScreenHeader';
import { Outlet, useNavigate } from 'react-router-dom';
import auctionService from '../../Services/auction.service';
import { useSelector } from 'react-redux';
import authHeader from '../../Services/auth-header';
import moment from 'moment';
import '../../CSS/ManageAuctionScreen.css'

function ManageAuctionScreen() {
  const navigate = useNavigate()
  const [auctionProduct, setAuctionProduct] = useState([])
  const [isHover, setIsHover] = useState(false)

  useEffect(()=>{
    auctionService.getAuctionProductByUser()
                  .then((res)=>{
                    setAuctionProduct(res.data)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
  },[1])
  const AddToAuctionComponent=()=>{
    return (
    <>
      <div>
      <ScreenHeader headerTitle={'Manage Auction'}/>
      <hr style={{width:'100%'}}/>
      <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{borderRadius:5,height:40,display:'flex',alignItems:'center',margin:'2vw'}}>
            <button
             onClick={()=>navigate('selectproductforauction')}
             className="auction-add-new-btn"
            >Add New</button>
        </div>
        <hr className='hr-line'/>
        {auctionProduct && auctionProduct.length>0 &&
          <>
            <ScreenHeader headerTitle={'In Auction'}/>
            <hr style={{width:'100%'}}/>
          </>
        }
        <div style={{borderRadius:5,display:'flex',alignItems:'center',margin:'2vw', flexWrap:'wrap'}}
        >
            { auctionProduct && auctionProduct.map((data)=>(
                  <div
                      onClick={()=>navigate('selectproductforauction/addtoauction', {state:{productId:data.product.id}})}
                      onMouseEnter={()=>setIsHover(true)}
                      onMouseLeave={()=>setIsHover(false)}
                      style={{
                        background:'#d4edf8' ,
                        border: isHover ? '1px solid black' : '1px dotted gray',
                        height:320,
                        width:250,
                        borderRadius:10,
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'space-around',
                        margin:10,
                        cursor:'pointer',
                        }}     
                  >
                    <div style={{fontWeight:500}}>{data.product.product} ({data.product.productDetail.product_type})</div>
                    <div
                      style={{fontWeight:500, width:'100%', textAlign:'center', marginBottom:5}}
                    >
                          <div style={{fontSize:12, color:'#5DA7CA'}}>starting price</div>
                          {data.starting_price}
                    </div>
                    <div
                      style={{fontWeight:500, width:'100%', textAlign:'center', marginBottom:5}}
                    >
                          <div style={{fontSize:12, color:'#5DA7CA'}}>current price</div>
                          {data.current_amount ? data.current_amount : 'Not started'}
                    </div>
                    <div
                      style={{fontWeight:500, width:'100%', textAlign:'center', marginBottom:5}}
                    >
                          <div style={{fontSize:12, color:'#5DA7CA'}}>start date</div>
                          {moment(data.start_date_time).utc().format('DD/MM/YYYY - hh:mm:ss')}
                    </div>
                    <div
                      style={{fontWeight:500, width:'100%', textAlign:'center', marginBottom:5}}
                    >
                          <div style={{fontSize:12, color:'#5DA7CA'}}>end date</div>
                          {moment(data.end_date_time).utc().format('DD/MM/YYYY - hh:mm:ss')}
                    </div>
                    <div
                      style={{fontWeight:500, width:'100%', textAlign:'center', marginBottom:5}}
                    >
                          <div style={{fontSize:12, color:'#5DA7CA'}}>upload date</div>
                          {moment(data.created_at).utc().format('DD/MM/YYYY - hh:mm:ss')}
                    </div>
                    <div
                      style={{fontWeight:500, width:'100%', textAlign:'center', marginBottom:5}}
                    >
                          <div style={{fontSize:12, color:'#5DA7CA'}}>auction type</div>
                          {data.auction_type}
                    </div>
                  </div>
            ))
            }
        </div>
     </div>
      </div>
     <Outlet/>
    </>
    )
  }

  return (
    <ScreenLayout
    component={<AddToAuctionComponent/> }
    />
  );
  
}

export default ManageAuctionScreen;
