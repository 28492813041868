import React from "react";
import ScreenHeader from "../Components/CommonComponents/ScreenHeader";
import { ScreenLayout } from "./ScreenLayout";
import ScrollableTableDashboard from "./ScrollableTableDashboard";

function HomeScreen() {
  const HomeScreenComponent = () => {
    return (
        <ScrollableTableDashboard/>
    );
  };

  return <ScreenLayout component={<HomeScreenComponent/>} />;
}

export default HomeScreen;
