import React from 'react';
import { Link } from 'react-router-dom';

function SelectBar({index, logo, title, description, link, setClick, setSelectedMenu, selectedMenu}) {
    console.log(selectedMenu, index)
  return (
        <div className='menu-selectbar' onClick={()=>{setClick(false); setSelectedMenu(index)}}>
            <Link 
                className={`menu-selectbar-link ' + ${selectedMenu==index?'menu-selectbar-link-selected':null}`}
                to={link}
            >
                <div className='menu-icon'>
                    <img src={logo} alt='cropway-logo'/>
                </div>
                <div className='menu-text-section'>
                    <div className='select-bar-title'>
                        {title}
                    </div> 
                    <div className='selectbar-description' >
                        {description}
                    </div>
                </div>
            </Link>
        </div>
  );
}

export default SelectBar;
