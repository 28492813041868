import React from "react";
import {  Link } from "react-router-dom";

const LowerNavbar = () => {
  return (
    <div >
      <div className='row'>
        <div className='col-sm-12 col-2'>
        <nav className="navbar navbar-expand-lg navbar-light  " style={{ background: "#bfe2ba" }}>

<div className="collapse navbar-collapse">
  <div className='container'>
  <ul className="navbar-nav ">
    <li className="nav-item active px-5">
      <a className="nav-link" href="#postbuy" style={{marginLeft:"150px"}}>Post Buy Requirements</a>
    </li>
    
    <li className="nav-item active px-3">
      <a className="nav-link" href="#categoris">Categories</a>
    </li>

    <li className="nav-item active px-3 ">
      <Link className="nav-link" to="/">For Buyers</Link>
    </li>

    <li className="nav-item active px-3 ">
      <Link className="nav-link" to="/">For Seller</Link>
    </li>
  </ul>
  </div>
</div>
</nav>

        </div>

      </div>
     
    </div>
  );
};

export default LowerNavbar;
