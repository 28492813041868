import React, { useState } from 'react'
import '../../CSS/Menu.css'
import SelectBar from '../MenuComponents/SelectBar'
import CropwayLogo from '../../assets/CropwayLogo.svg'
import home from '../../assets/home-alt.png'
import profile from '../../assets/profile.png'
import addmember from '../../assets/user-add.png'
import addproduct from '../../assets/add.png'
import productlist from '../../assets/list.png'
import orderhistory from '../../assets/file-document.png'
import cropwayguide from '../../assets/CropwayGuide.png'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/auth'

function Menu({ navigate }) {
  const [click, setClick] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState(0)
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { internationalization } = useSelector((state) => state.language)

  let menuData=[
    {
      logo:home,
      title:internationalization.home,
      description:internationalization.analytics_report,
      link:''
    },
    {
      logo:profile,
      title:internationalization.profile,
      description:internationalization.name +', '+ internationalization.email +', '+ internationalization.address,
      link:'profile'
    },
    {
      logo:productlist,
      title:internationalization.product_list,
      description:internationalization.product_list_detail,
      link:'productlist'
    },
    {
      logo:addproduct,
      title:internationalization.add_product,
      description:internationalization.add_product_detail,
      link:'chooseproductcategory'
    },
    {
      logo:productlist,
      title:internationalization.auction,
      description:internationalization.auction_detail,
      link:'auction'
    },
    {
      logo:orderhistory,
      title:internationalization.order_history,
      description:internationalization.order_history_detail,
      link:'orderhistory'
    },
    {
      logo:cropwayguide,
      title:internationalization.cropway_guide,
      description:internationalization.cropway_guide_detail,
      link:'cropwayguide'
    },
  ]

  return (
    <div className='float-menu'>
    <div className="menu">
      <div id="mobile" onClick={() => setClick(!click)}>
        <i id="bar" className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
      <div onClick={() => navigate('/')} className="menu-logo-div">
        <div className="menu-logo">
          <img src={CropwayLogo} alt="cropway_logo"/>
          <div className="menu-tag">
            <p>{internationalization.seller_studio}</p>
          </div>
        </div>
      </div>
      <div className={click ? 'nav-menu active' : 'nav-menu'} style={{width:'100%'}}>
        {user && menuData.map((menu, key)=>(
            <SelectBar
              key={key}
              index={key}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              logo={menu.logo}
              title={menu.title}
              description={menu.description}
              link={menu.link}
              setClick={setClick}
            />
        ))}
        <div className="div-sign">
          {user ? (
            <button
              className="sign-button"
              onClick={() => {
                dispatch(logout())
                navigate('/login')
              }}
            >
              {internationalization.sign_out}
            </button>
          ) : (
            <button
              className="sign-button"
              onClick={() => {
                navigate('/login')
              }}
            >
              {internationalization.sign_in}
            </button>
          )}
        </div>
      </div>
    </div>
    </div>
  )
}

export default Menu