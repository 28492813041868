import React from 'react'
import { useState } from 'react'

const Modal = ({visible, children}) => {
    console.log("visible :: ", visible)

  return (
    <div
      style={{
        display:visible ? 'block' : 'none',
        height:'100%',
        width:'100%',
        position:'fixed',
        zIndex:9999,
        borderColor:'black',
        textAlign:'center',
        left:0,
        right:0,
        top:0,
        bottom:0,
        margin:'auto',
        padding:10,
        background:'rgba(0,0,0,0.5)'
      }}
    >
        <div style={{
            position:'absolute',
            height:300,
            width:500,
            background:'white',
            left:0,
            right:0,
            bottom:0,
            top:0,
            margin:'auto',
            padding:10,
            border: '1px solid gray',
            borderRadius:10,
            transition: '1.1s ease-out',
            }}>
            {children}
        </div>

    </div>
  )
}

export default Modal