import React from 'react';

const SixithSectionCardComponent = ({image,title1,title2}) => {
  return (
    <div className='SixithSection-card'>
        <div className='SixithSection-cardColor'>
            <img src={image} alt="clock.png" className='our-tech-img-logo-width'/>
          <div style={{maxWidth:'100%'}}>
           { title1}
          </div>
        </div>
      
    </div>
  )
}

export default SixithSectionCardComponent
