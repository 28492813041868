// register/login/logout
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "../actions/type";

  const user = JSON.parse(localStorage.getItem("user"));
  // const role = (localStorage.getItem("role"));
  // console.log('reducer :: ', user,role)
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      default:
        return state;
    }
  }