import React from "react";
import welcomeImage from "../../assets/Seller Studio Welcome Page BG.webp";
import welcomeImage2 from "../../assets/carousel-img2.png";
import welcomeImage3 from "../../assets/carousel-img3.png";
import welcomeImage4 from "../../assets/carousel-img4.png";
import '../../CSS/FirstSection.css'
import { useSelector } from "react-redux";


export default function FirstSection({ navigate }) {
  const {internationalization} = useSelector(state=>state.language)
  
  let carouselData = [
    {
      'class' : 'carousel-item',
      'state' : '',
      'img' : welcomeImage2,
      'title': internationalization.carousel_text_2_1,
      'description':internationalization.carousel_text_2_2,
      'button1': internationalization.carosel_button_2_1,
      'button2':  internationalization.carosel_button_common,
      'href':'aboutbuyer'
    },
    {
      'class' : 'carousel-item',
      'state' : '',
      'img' : welcomeImage3,
      'title': internationalization.carousel_text_3_1,
      'description':internationalization.carousel_text_3_2,
      'button1': internationalization.carosel_button_3_1,
      'button2':  internationalization.carosel_button_common,
      'href':'aboutseller',
    },
    {
      'class' : 'carousel-item',
      'state' : '',
      'img' : welcomeImage4,
      'title': internationalization.carousel_text_4_1,
      'description':internationalization.carousel_text_4_2,
      'button1': internationalization.carosel_button_4_1,
      'button2':  internationalization.carosel_button_common,
      'href':'aboutvas',
    },
  ]

  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-ride="carousel"
    >
      <div>
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleCaptions"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
        </ol>
      </div>
{/* ------------------------------ start carousel ------------------------------------------------ */}

      <div className="carousel-inner">
        <div className="carousel-item active"> 
          <div className="carousel-image-section"/>
            <img src={welcomeImage} alt="main-carousel-image" className="carousel-image-style"/>
          <div className="caption-section">
            <div className="carousel-home-image-content-div">
                <p className="carousel-home-image-heading">
                  {internationalization.carousel_text_1_1}
                </p>
                <p className="carousel-home-image-para">
                {internationalization.carousel_text_1_2}
                </p>
                <div className="carousel-home-image-button-section">
                    <button
                      onClick={()=>navigate('login')}
                      className="carousel-home-image-btn"
                    >
                      {internationalization.register_now}
                    </button>

                    <button
                      onClick={() => {
                        navigate("login");
                      }}
                      className="carousel-home-image-btn-buy-sell"
                    >
                      {internationalization.carosel_button_1_1}
                    </button>
                </div>
            </div>
          </div>
        </div>

         {
          carouselData.map((data, key)=>(
            <div className={`carousel-item`} key={key}>
            <div className="carousel-image-section"/>
              <img src={data.img} alt="main-carousel-image" className="carousel-image-style" />
            <div className="caption-section">
              <div className="carousel-home-image-content-div">
                <p className="carousel-home-image-heading">
                   {data.title}
                </p>
                <p className="carousel-home-image-para">
                  {data.description}
                </p>
              <div className="carousel-home-image-button-section">
                  <button
                    onClick={()=>navigate('login')}
                    className="carousel-home-image-btn"
                  >
                    {data.button1}
                  </button>
                
  
                <button
                  onClick={()=>navigate(data.href, {state:{page:data.href}})}
                  className="carousel-home-image-btn-buy-sell"
                  
                >
                  {data.button2}
                </button>
              </div>
              </div>
            </div>
          </div>
          ))
         }

      </div>
      {/* <button
        className="carousel-control-prev"
        type="button"
        data-target="#carouselExampleCaptions"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button> */}
      <button
        className="carousel-control-next"
        type="button"
        data-target="#carouselExampleCaptions"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
}
