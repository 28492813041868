import React, { useEffect, useState } from 'react';
import { StatusButton } from './HSTableComponents/StatusButton';
import userService from '../../Services/user.service';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import '../../CSS/MarketPriceDashboard.css';

function RecievedQuatation({navigate}) {
  const {user} = useSelector((state)=> state.auth)
  const {internationalization} = useSelector((state)=> state.language)
  const [orderEnquiryDetails, SetOrderEnquiryDetails] = useState([])
  const [orderEnquiryError, SetOrderEnquiryError] = useState('')
  const [loading, SetLoading] = useState(false)
  const [count, SetCount] = useState(true)

   useEffect(()=>{
         SetLoading(true)
         userService.getRequirementProductByUser()
         .then((res)=>{
            // console.log(res.data)
               SetOrderEnquiryDetails(res.data)
               SetLoading(false)
            })
            .catch((error)=>{
               SetOrderEnquiryDetails([])
               SetOrderEnquiryError(error.message)
               SetLoading(false)
            })

      },[])
   
   // pagination
   const [currentItems, setCurrentItems] = useState(null);
   const [pageCount, setPageCount] = useState(0);
   const itemsPerPage = 5
   // Here we use item offsets; we could also use page offsets
   // following the API or data you're working with.
   const [itemOffset, setItemOffset] = useState(0);

   useEffect(() => {
     // Fetch items from another resources.
     const endOffset = itemOffset + itemsPerPage;
   //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     setCurrentItems(orderEnquiryDetails && orderEnquiryDetails.slice(itemOffset, endOffset));
     setPageCount(Math.ceil(orderEnquiryDetails && orderEnquiryDetails.length / itemsPerPage));
   }, [itemOffset, itemsPerPage, orderEnquiryDetails]);
 
   // Invoke when user click to request another page.
   const handlePageClick = (event) => {
     const newOffset = (event.selected * itemsPerPage) % orderEnquiryDetails.length;
   //   console.log(
   //     `User requested page number ${event.selected}, which is offset ${newOffset}`
   //   );
     setItemOffset(newOffset);
   };
   // pagination


   const ActiveListTable=({serialNo, orderEnquiryId, product, price, quantity, unit})=>{
      return(
            <tr>
               <td className='table-data-cell'>{serialNo}</td>
               <td className='table-data-cell'>{product}</td>
               <td className='table-data-cell'>{quantity}</td>
               <td className='table-data-cell'>{unit}</td>
               <td className='table-data-cell'>Rs. {price}</td>
               <td className='table-data-cell'>
               <div
                    style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center'}}>
                <button
                onClick={()=>navigate('requirementproductview', {state:{productId:orderEnquiryId}})}
                  style={{
                    width:100,
                    height:30, 
                    cursor:'pointer',
                    backgroundColor: '#FFEDEF',
                    borderRadius:5,
                    border: '1px solid #FFEDEF',
                    color:'#D1293D',
                    display:'flex',
                    justifyContent:'center'
                }}
                >
                    {internationalization.show}
                </button>
                </div>
               </td>
            </tr>
         )
      }

  return (
   <div className='live-market-price'>
   {/* <ScreenHeader  headerTitle={internationalization.to_sell} color={'green'}/> */}
         <text className='sub-text-header'>
               {internationalization.to_sell}
         </text>
         <table className='live-market-price-table' >
               <tr className='table-header' >
                  <th className='market-price-table-heading'>#</th>
                  <th className='market-price-table-heading'>{internationalization.commodity}</th>
                  <th className='market-price-table-heading'>{internationalization.required_quantity}</th>
                  <th className='market-price-table-heading'>{internationalization.unit}</th>
                  <th className='market-price-table-heading'>{internationalization.demanding_price}</th>
                  <th className='market-price-table-heading'>{internationalization.action}</th>
               </tr>
               {
                  loading &&
                  <tr>
                     <td colSpan='7' className='table-loading' >loading...</td>
                  </tr>
               }
               {!loading && orderEnquiryError && <td className='recieved-enquiries-table-data3' >{orderEnquiryError}</td>}
               {count && <td className='recieved-enquiries-table-data4' >{internationalization.no_product}</td>}
            { currentItems && currentItems.map((order, key)=>{
               if(currentItems.length>0){
                  if(count===true){
                     SetCount(false)
                  }
                  return(
                        <ActiveListTable
                           key={key}
                           serialNo={key+1+itemOffset}
                           orderEnquiryId={order.id}
                           product={order.product && order.product}
                           quantity={order.count_in_stock}
                           unit={order.unit}
                           price={order.price}
                        />
                  )
               }
               })
               }
         </table>

         <ReactPaginate
            previousLabel={"<"+internationalization.previous}
            breakLabel="..."
            nextLabel={internationalization.next+">"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            renderOnZeroPageCount={null}
            className="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
        />
      </div>
  );
}

export default RecievedQuatation;
