// Data service
import axios from "axios";
import authHeader from "./auth-header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/sellerstudio/";

const postProductForAuction = (data) => {
   return axios.post(API_URL+'auctionproductupload/', 
   data,
   {headers : authHeader()}
   )
};

const getAuctionProduct = () => {
   return axios.get(API_URL+'auctionproductupload/',
   {headers : authHeader()}
   )
};

const getAuctionProductByProductId = (productId) => {
   return axios.get(API_URL+`getauctionproductbyproduct/${productId}`)
};

const getAuctionProductByUser = () => {
   return axios.get(API_URL+`getauctionproductbyuser/`,
   {headers : authHeader()}
   )
};

///////////

const getAuctionTransectioById = (productId) => {
   return axios.post(API_URL+`getauctiontransectionbyid/`, {productId : productId})
};

const postAuctionTransection = (data) => {
   return axios.post(API_URL+`postauctiontransection/`,
   data,
   {headers : authHeader()}
   )
};

const updateAuction = (data) => {
   return axios.post(API_URL+`updateauction/`,
   data,
   {headers : authHeader()}
   )
};

export default {
  postProductForAuction,
  getAuctionProduct,
  getAuctionProductByProductId,
  getAuctionProductByUser,
  getAuctionTransectioById,
  postAuctionTransection,
  updateAuction,
};