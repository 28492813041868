import React from "react";
import "../../../CSS/SecondScreen.css";
export default function HeaderTextComponent1({header, textContent}){
    return(
        <div className="div-layout pt-3 mt-4">
            <div className="div-header text-center bus-Eco-Stake-heading my-2" >{header}</div>
            <div className="div-content bus-Eco-Stake-sub-heading">
                {textContent}
            </div>
        </div>
    )
}