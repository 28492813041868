import React, { useEffect, useState } from 'react';
import '../../CSS/Allcategories.css';
import ScreenHeader from '../../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from '../ScreenLayout';
import moment from 'moment';
import userService from '../../Services/user.service';
import AuctionService from '../../Services/auction.service';
import { useLocation } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import auctionService from '../../Services/auction.service';

function AddToAuctionScreen({}) {
  const location = useLocation();
  const [product, setProduct] = useState();
  const [condition, setCondition] = useState('');
  const [startingPrice, setStartingPrice] = useState('');
  const [minIncrementPrice, setMinIncrementPrice] = useState();
  const [reservePrice, setReservePrice] = useState();
  const [makeSealed, setMakeSealed] = useState(true);
  const [auctionType, setAuctionType] = useState('Normal');
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date(Date.parse(new Date()) + (6*60*60*1000)));
  const [editMode, setEditMode] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  
  useEffect(()=>{
    userService.getProductById(location.state.productId)
         .then((response)=>{
          setProduct(response.data)
          setEditMode(response.data.in_auction)
          return response.data.in_auction
         })
         .then((data)=>{
              data && auctionService.getAuctionProductByProductId(location.state.productId)
              .then((res)=>{
                setCondition(res.data.condition)
                setStartingPrice(res.data.starting_price)
                setMinIncrementPrice(res.data.min_increment_amount)
                setReservePrice(res.data.reserve_price)
                setMakeSealed(res.data.make_sealed)
                setAuctionType(res.data.auction_type)
                setStartDateTime(res.data.start_date_time)
                setEndDateTime(res.data.end_date_time)
              })
              .catch((error)=>{
                console.log(error)
              })
         })
         .catch((error)=>{
          console.log(error)
         })
         
  },[1])

  let auctionProductData={
    'product' : location.state.productId,
    'condition' : condition,
    'auctionType' : auctionType,
    'makeSealed' : makeSealed,
    'startingPrice' : startingPrice,
    'minIncrementAmount' : minIncrementPrice,
    'reservePrice' : reservePrice,
    'startDateTime' : startDateTime,
    'endDateTime' : endDateTime

  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    console.log(auctionProductData)
    if(editMode){
       console.log('editMode')
       AuctionService.updateAuction(auctionProductData)
       .then((res)=>{
        console.log(res.data)
        alert('Value updated.')
        window.location.reload();
      })
    }
    else{
      AuctionService.postProductForAuction(auctionProductData)
                    .then((res)=>{
                      console.log(res.data)
                      alert('Auction is listed.')
                      window.location.reload();
                    })
    }
  }

  return (
    <ScreenLayout component={
      <>
      { editMode?
           <button
              className='sign-button'
              style={{position:'absolute', right:40, height:40, width:100}}
              onClick={()=>setUpdateMode(!updateMode)}
           >
              {updateMode? 'Cancle' : 'Edit'}
           </button> 
        : null}
       <ScreenHeader headerTitle={editMode ? 'Update Auction' :'Add To Auction'}/>
       <hr style={{width:'100%'}}/>
        <div>
          <div>
            <div style={{display:'flex', flexDirection:'row'}}>
              <p style={{width:'20%', color:'#808080'}}>Product</p>
              <p style={{marginRight:15}}>:</p>
              <p style={{fontWeight:500}}>{product && product.product}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              <p style={{width:'20%', color:'#808080'}}>Type</p>
              <p style={{marginRight:15}}>:</p>
              <p style={{fontWeight:500}}>{product && product.productDetail.product_type}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              <p style={{width:'20%', color:'#808080'}}>Price</p>
              <p style={{marginRight:15}}>:</p>
              <p style={{fontWeight:500}}>₹{product && product.price} / {product && product.unit}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              <p style={{width:'20%', color:'#808080'}}>Quantity</p>
              <p style={{marginRight:15}}>:</p>
              <p style={{fontWeight:500}}>{product && product.count_in_stock} {product && product.unit}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              <p style={{width:'20%', color:'#808080'}}>Upload Date</p>
              <p style={{marginRight:15}}>:</p>
              <p style={{fontWeight:500}}>{product && moment(product.created_at).utc().format('DD/MM/YYYY')}</p>
            </div>
          </div>

          <div>
            <p>Add to auction form</p>
            <form onSubmit={handleSubmit}>
             <fieldset disabled={editMode ? !updateMode : false}>
              <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Condition</label>
                    <input style={{width:'75%',height:'100%',borderRadius:5, border:'1px solid black'}}
                          type='text'
                          onChange={e=>setCondition(e.target.value)}
                          defaultValue={condition}
                    />
              </div>
              <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                  <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>
                    Auction Type
                  </label>
                  <div style={{display:'flex',flexDirection:'row'}}>
                      <label
                      style={{width:'50%',borderRadius:5,display:'flex', alignItems:'center',padding:5}}
                      >Normal
                          <input
                              type='radio'
                              style={{margin:20}}
                              name='auction-type'
                              value='Normal'
                              onChange={(e)=>setAuctionType(e.target.value)}
                              checked={auctionType==='Normal'? true : false}
                          />
                      </label>
                      <label
                      style={{width:'50%',borderRadius:5,display:'flex', alignItems:'center',padding:5}}
                      >Reverse
                          <input
                              type='radio'
                              style={{margin:20}}
                              name='auction-type'
                              value='Reverse'
                              onChange={(e)=>{setAuctionType(e.target.value)}}
                              checked={auctionType==='Reverse'? true : false}
                          />
                      </label>
                  </div>
              </div>
              <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                  <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>
                    Make Sealed
                  </label>
                  <div style={{display:'flex',flexDirection:'row', flexWrap:'wrap'}}>
                      <label
                      style={{width:'50%',borderRadius:5,display:'flex', alignItems:'center',padding:5}}
                      >Yes
                          <input
                              type='radio'
                              style={{margin:20}}
                              name='make-sealed'
                              onChange={(e)=>{setMakeSealed(true)}}
                              checked={makeSealed}
                          />
                      </label>
                      <label
                      style={{width:'50%',borderRadius:5,display:'flex', alignItems:'center',padding:5}}
                      >No
                          <input
                              type='radio'
                              style={{margin:20}}
                              name='make-sealed'
                              onChange={(e)=>{setMakeSealed(false)}}
                              checked={!makeSealed}
                          />
                      </label>
                  </div>
              </div>
              <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Starting Price</label>
                    <input style={{width:'75%',height:'100%',borderRadius:5, border:'1px solid black'}}
                          type='number'
                          onChange={(e)=>setStartingPrice(e.target.value)}
                          defaultValue={startingPrice}
                          required
                    />
              </div>
              <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>
                      Minimun {auctionType && auctionType==='Normal'? 'Increment' : 'Decrement'} Amount
                    </label>
                    <input style={{width:'75%',height:'100%',borderRadius:5, border:'1px solid black'}}
                          type='text'
                          onChange={(e)=>setMinIncrementPrice(e.target.value)}
                          defaultValue={minIncrementPrice}
                          required
                    />
              </div>
              {auctionType && auctionType==='Normal' ? 
                <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Reserve Price</label>
                    <input style={{width:'75%',height:'100%',borderRadius:5, border:'1px solid black'}}
                          type='text'
                          onChange={(e)=>setReservePrice(e.target.value)}
                          defaultValue={reservePrice}
                          required
                    />
                </div>
                :
                null
              }
              <div style={{width:'38%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Start Date</label>
                    <DateTimePicker
                        onChange={(datetime)=>setStartDateTime(datetime)}
                        value={startDateTime}
                        format='dd/MM/yyyy hh:mm:ss a'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        secondPlaceholder='ss'
                        isClockOpen={false}
                        disableClock
                        className='calender'
                        openWidgetsOnFocus={false}
                        returnValue='start'
                        required
                    />
              </div>
              <div style={{width:'38%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>End Date</label>
                    <DateTimePicker
                        onChange={(datetime)=>setEndDateTime(datetime)}
                        value={endDateTime}
                        format='dd/MM/yyyy hh:mm:ss a'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        secondPlaceholder='ss'
                        isClockOpen={false}
                        disableClock
                        className='calender'
                        openWidgetsOnFocus={false}
                        returnValue='start'
                        required
                    />
              </div>
              <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
                  <button className='sign-button' type='submit' style={{cursor:editMode ? updateMode ?'pointer':'not-allowed' : 'pointer'}}>
                    { product && product.in_auction ? 'Update' :'Add Product'}
                  </button>
              </div>
             </fieldset>
            </form>
          </div>
        </div>
      </>
    } />
  );
}

export default AddToAuctionScreen;
