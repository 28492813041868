import React, { useState } from 'react';
import { ScreenLayout } from './ScreenLayout';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import userService from '../Services/user.service';
import '../CSS/Addproduct.css';

function ChooseProductCategoryScreen() {
  const navigate = useNavigate()
  const [productcategory, setProductCategory] = useState('')
  const [productCategoryData, setProductCategoryData] = useState([])

  useEffect(()=>{
        userService.getProductCategory()
        .then((res)=>{
          setProductCategoryData(res.data)
        })
        .catch((error)=>{
          console.log(error)
        })
  },[1])

  productCategoryData && productCategoryData.map((res)=>{

  })

  const otherData = [
                       {others:'Vegetables'}
                          ]

  const ChooseProductComponent=()=>{
    return (
     <div>
       <ScreenHeader headerTitle={'Select Category'}/>
       <hr style={{width:'100%'}}/>
      <div style={{display:'flex', flexWrap: 'wrap', alignItems:'center',marginTop:'10px'}}>
        {[...new Set(productCategoryData.map(({category})=>category))].map((res, key)=>(
            <div key={key} style={{borderRadius:5, display:'flex',alignItems:'center',justifyContent:'center',margin:'2vw 5vw 2vw 0'}}>
                <button
                onClick={(e)=>setProductCategory(e.target.value)}
                value={res}
                className='category-button'
                style={{backgroundColor: productcategory===res ? 'crimson': null, borderColor: productcategory===res ? 'crimson': null}}
                >
                  {res}
                </button>
            </div>
        ))}
     </div>
     <hr />
     {
        productcategory &&
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {
            [...new Set(productCategoryData.filter((data)=>data.category==productcategory))].map((data,key)=>(
            <div key={key} style={{borderRadius:5,height:40,display:'flex',alignItems:'center',margin:'2vw 5vw 2vw 0'}}>
                <button
                onClick={(e)=>navigate('addproduct', {state:{product : e.target.value}})}
                value={data.name}
                className='category-button'
                >{data.name}</button>
            </div>
            ))
          }
        </div>
    }

  </div>
    )
  }

  return (
    <ScreenLayout
    component={<ChooseProductComponent/> }
    />
  );
  
}

export default ChooseProductCategoryScreen;
