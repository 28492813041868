import { Image } from 'react-bootstrap';
import '../../CSS/CardComponent.css';

export const CardComponent =({image, href, title, content, navigate})=>{
    return(
        <div className='card-component-layout mt-3'>
            <div className="card-component "
                 onClick={()=> navigate(href, {state:{page:href}})}
            >
                <Image src={image} className="bus-Eco-Stake-img" />
            </div>
            <div className='card-text'>
                {title}
            </div>
            <div className='card-content'>
                {content}
            </div>
     </div>
    )
}