import React from 'react'

export default function WhiteCircle(){

    const WhiteCircleComponent=({radius})=>{
          return(
            <div className='white-circle' style={{height:radius, width:radius, position:'absolute'}}/>
          )
    }
  return (
    <>
    <WhiteCircleComponent radius={'36vw'} />
    <WhiteCircleComponent radius={'34vw'} />
    <WhiteCircleComponent radius={'32vw'} />
    </>
  )
}
