import React, { useEffect, useState } from 'react';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from './ScreenLayout';
import moment from 'moment';
import userService from '../Services/user.service';
import paddy_icon from '../assets/paddy.jpg';
import maize from '../assets/maize.jpg';
import barley from '../assets/barley.jpg';
import sugar from '../assets/sugar.jpg';
import millet from '../assets/millet.jpg';
import daal from '../assets/daal.jpg';
import tilli from '../assets/tilli.jpg';
import vegetables from '../assets/vegetables.jpg';
import { useNavigate } from 'react-router-dom';
import '../CSS/TableComponent.css';
import { useSelector } from 'react-redux';

function ProductListScreen() {
  const navigate = useNavigate();
  const {internationalization} = useSelector((state)=> state.language)
  const [product, setProduct] = useState();
  const [isUpdate, setIsUpdate] = useState();
  
  useEffect(()=>{
    userService.getProductByUserId()
         .then((response)=>{
          setProduct(response.data)
         })
         .catch((error)=>{
          console.log(error)
         })
  },[isUpdate])


  const ProductListComponent=()=>{
    const [isPublished, setIsPublished] = useState([]);
    const [updates, setUpdates] = useState(false);

    const handleIsPublished=(value, checked)=>{
      setUpdates(true)
      const isPublishedNext = [...isPublished]
      const product = isPublishedNext.find(
        abc=> abc.id === value
      )
      product.value = checked
      setIsPublished(isPublishedNext)
    }

    const handleIsDeleted=(value)=>{
      userService.deleteProduct(value)
                 .then((res)=>{
                   if(res.status==200){
                    setIsUpdate(value)
                    alert('product deleted')
                   }
                   else {
                    alert(res.status)
                  }
                })
                .catch((error)=>{
                  if(error.response.status){
                    alert('can not delete this item. Product in use')
                  }
                  console.log(error)
                 })

    }

    const updateChanges=()=>{
      setUpdates(false)
      userService.updateProductState(isPublished)
       .then((res)=>{
         console.log(res.data)
       })
    }
    useEffect(()=>{
      product && setIsPublished(product.map((product)=>(
        {id : product.id, value:product.is_active}
      )))
    },[product])

    return(
      <div>
       <ScreenHeader headerTitle={internationalization.product_list} />
       <hr style={{width:'100%'}}/>
       <div>
       <div className='bashboard-product-list' >
          <>{internationalization.all}({product && product.length})</>
            <button
              type='button'
              className={!updates ? "sign-button-disable" : "sign-button"} 
              onClick={()=>updateChanges()}
              disabled={!updates}
            >
              {internationalization.save_changes}
            </button>
        </div>
        <div>
        {/* <button type="button" className="btn btn-outline-primary m-1">Bulk actions</button> */}
        {/* <button type="button" className="btn btn-outline-primary m-1">Apply</button> */}
        {/* <button type="button" className="btn btn-outline-primary m-1">Select a category</button> */}
        </div>
        <div className="text-scrolleble">
            <table className="table m-2 product-list-table-width">
              <thead >
                <tr >
                  {/* <th scope="col"><input type="checkbox" aria-label="Checkbox for following text input"/></th> */}
                  <th scope="col">{internationalization.image}</th>
                  <th scope="col">{internationalization.name}</th>
                  <th scope="col">{internationalization.sku}</th>
                  <th scope="col">{internationalization.stock}</th>
                  <th scope="col">{internationalization.unit}</th>
                  <th scope="col" style={{width:"200px"}}>{internationalization.price_min_max}</th>
                  <th scope="col">{internationalization.categories}</th>
                  <th scope="col">{internationalization.tags}</th>
                  <th scope="col">{internationalization.date}</th>
                  <th scope="col" style={{width:"200px"}}>{internationalization.is_published}</th>
                  <th scope="col">{internationalization.delete}</th>
                </tr>
              </thead>
              <tbody>
               { product && product.map((product,key)=>(
                <tr
                   key={key}
                   className={key%2==0?'table-even-row':'table-odd-row'}
                   onClick={(e)=>(e.target.className==="")? navigate("editlistedproduct", {state : {editProductId : product.id}} ): false}
                >
                {/* <th scope="row"><input type="checkbox" aria-label="Checkbox for following text input"/></th> */}
                <td ><img src={product.newProduct.icon} alt="..." style={{width:50, height:50, borderRadius:5}}/> </td>
                <td>{product.product}</td>
                <td >{product.SKU ? product.SKU : "--" }</td>
                <td >{product.count_in_stock}</td>
                <td >{product.unit}</td>
                <td >Rs.{product.price_min} - {product.price}</td> 
                <td >{product.category ? product.category : "--" }</td>
                <td >{product.tag ? product.tag : "--" }</td>
                <td >{moment(product.created_at).utc().format('DD/MM/YYYY')}</td>
                <td >
                    <input 
                        id={"published_"+key}
                        type='checkbox'
                        value={product.id}
                        style={{margin:20,zIndex:22}}
                        onClick={(e)=>{e.stopPropagation(); handleIsPublished(Number(e.target.value), e.target.checked)}}
                        defaultChecked={ product.is_active===true ? product.is_active : null  }
                      />
                </td>
                <td >
                  <button id={"delete_"+key}
                  type='button'
                  value={product.id}
                  style={{marginTop:10,marginBottom:10,zIndex:22 ,borderRadius:5,background:'rgb(255, 207, 239)',color:'rgb(209, 41, 61)',border:"none"}}
                  onClick={(e)=>{e.stopPropagation(); handleIsDeleted(Number(e.target.value))}}
                 >{internationalization.delete}</button>
                
                </td>
              </tr>

               ))
               }
               
              </tbody>
            </table>
            </div>
       </div>
     </div>
    )
  }
  return (
    <ScreenLayout component={<ProductListComponent/>} />
  );
}

export default ProductListScreen;
