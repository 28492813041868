import React from 'react'
import HeaderTextComponent1 from './HeaderAndText/HeaderTextComponent1'
import '../../CSS/SixithSection.css'
import SixithSectionCardComponent from './SixithSectionCardComponent/SixithSectionCardComponent';
import clockimg from '../../assets/clock.png'
import shop from '../../assets/shop2.png'
import blockchain from '../../assets/blockchain3.png'
import planet from '../../assets/planet.png'
import weatherforecast from '../../assets/weather-forecast.png'
import automated from '../../assets/automated.png'
import techBG from '../../assets/Technology-BG.jpg';
import { useSelector } from 'react-redux';


export default function SixithSection() {
  const {internationalization} = useSelector(state=>state.language)
  return (

    <div>
      <div className="our-technology-main-div"> 
        <div> 
            <HeaderTextComponent1 header={internationalization.our_technology}/>
        </div>
        <div className='our-tech-bottom-img-div'>
          <SixithSectionCardComponent image={clockimg} title1={internationalization.real_time_monitoring}/>
          <SixithSectionCardComponent image={shop} title1={internationalization['360_marketplace']}/>
          <SixithSectionCardComponent image={blockchain} title1={internationalization.ai_ml_iot_blockchain}/>
          <SixithSectionCardComponent image={planet} title1={internationalization.geospatial_analytics}/>
          <SixithSectionCardComponent image={weatherforecast} title1={internationalization.smart_weather_agri_advisory}/>
          <SixithSectionCardComponent image={automated} title1={internationalization.automated_decision_making}/>
        </div>
      </div>
    </div>
  )
}
