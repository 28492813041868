import React from "react";
import CropwayServices1 from "../../assets/Cropway Services1.png";
import HeaderTextComponent1 from "./HeaderAndText/HeaderTextComponent1";
import ImageAndTextComponent from "./ImageAndText/ImageAndTextComponent";
import { useSelector } from "react-redux";

export default function FifthSection(){
    const {internationalization} = useSelector(state=>state.language)
    return(
        <div style={{backgroundColor:'rgba(217, 217, 217, 0.2)'}} >
            <div> 
             <HeaderTextComponent1 header={internationalization.why_choose_us}/>
            </div>
            <div> 
             <ImageAndTextComponent/>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
             <img src={CropwayServices1} className="seller-studio-marketing-studio my-5" />
            </div>
        </div>
    )
}