import React from 'react';
import '../../CSS/Info.css';
const Info = ({infoText}) => {

  return (
    <span 
     className='infoHeader'
    >
    i
     <p
      className='infoText'
     >
        {infoText}
     </p>
    </span>
  )
}

export default Info