import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import buyer from '../../../assets/Buyer_BG.webp';
import seller from '../../../assets/Seller_BG.webp';
import HeaderTextComponent1 from "../HeaderAndText/HeaderTextComponent1";
import FirstViewScreen from "./FirstViewScreen";
import { CardComponent } from "../../CommonComponents/CardComponent";
import securePayment from "../../../assets/secure-payment.png";
import VAS from "../../../assets/VAS.png";
import medal from "../../../assets/medal.png";
import shopping from "../../../assets/shopping.png";
import dtd from '../../../assets/door-to-door.png';
import downloader from '../../../assets/Downloader.png';
import tomato from '../../../assets/Downloader2.png';
import farmerBusinessman from '../../../assets/farmer-businessman.png';
import secure from '../../../assets/secure.png';
import transport from '../../../assets/transport.png';
import "../../../CSS/mainFrame.css";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
export default function BuyerSellerDetailScreen(){
    const location = useLocation()
    const {internationalization} = useSelector((state)=> state.language)
    const [renderData, setRenderData] = useState([])
    const navigate = useNavigate()
    
    // console.log(navigate)
    useEffect(()=>{
        location.state.page === 'aboutbuyer' ?  setRenderData(buyerData) : setRenderData(sellerData)
        window.scrollTo(0,0)
    },[internationalization])
    
    const buyerData=
    {
        header:(internationalization.buyer),
        image:buyer,
            textContent:[
                <> 
                {internationalization.buyer_page_detail}
                </>
            ],
        header2: internationalization.buyer_header,
        header2_text: internationalization.buyer_text_1
            ,
            
        cardData: [
            {href:'!#',image: dtd, content:internationalization.buyer_point_1},
            {href:'!#',image: securePayment, content:internationalization.buyer_point_2},
            {href:'!#',image: medal, content:internationalization.buyer_point_3},
            {href:'!#',image: VAS, content:internationalization.buyer_point_4},
            {href:'!#',image: shopping, content:internationalization.buyer_point_5},
        ]

        }

      const sellerData=  {
            header:internationalization.seller.toUpperCase(),
            image:seller,
            textContent:[
                <> 
                {internationalization.seller_page_detail}
                </>
            ],
            header2: internationalization.seller_header,
            header2_text: internationalization.seller_text_1
                           ,
            cardImage: [
            farmerBusinessman,
            transport,
            secure,
            downloader,
            tomato,
        ]
        }
        

    return(
    <>   
            <FirstViewScreen
                    header={renderData.header}
                    image={renderData.image}
                    textContent={renderData.textContent} 
            />
            
            <div className="user-description-layout">
                <HeaderTextComponent1
                    header={renderData.header2}
                    textContent={renderData.header2_text}
                />

            <div className="buyer-content-layout" >

                {location.state.page === 'aboutbuyer' && buyerData['cardData'].map((data)=>(
                    <CardComponent image={data.image} title={data.title} content={data.content}
                     href={data.href} navigate={navigate}/>
                ))}

                {location.state.page === 'aboutseller' && 
                <div className="seller-img-layout">
                    <div className="seller-img-content">
                        <img src={sellerData.cardImage[0]}  className="seller-page-top-img" />
                        {/* <img src={sellerData.cardImage[1]} style={{width:'14.2%',margin:'2vw', borderRadius:5}} /> */}
                        <div>
                        <p  className="seller-page-top-img-text">{internationalization.seller_text_2}</p>
                        <img src={sellerData.cardImage[4]} className="seller-img-size "/>
                        </div>

                    </div>
                    <div className="seller-img-content">
                        {/* <img src={sellerData.cardImage[2]} className="seller-img-size"/> */}
                        {/* <img src={sellerData.cardImage[3]} className="seller-img-size"/> */}
                        {/* <img src={sellerData.cardImage[4]} className="seller-img-size"/> */}
                        {/* <img src={sellerData.cardImage[4]} className="seller-img-size"/> */}
                    </div>
                </div>
                }
            </div>
            </div>
            <div  className="buyer-screen-main-div-btn">
                <button
                onClick={()=>navigate('/login')}
                className="buyer-screen-reg-btn">
                    {internationalization.reg_buyer_seller_button}
                </button>
                <button
                onClick={()=>navigate('/')}
                className="buyer-screen-touch-btn"
                >
                    {internationalization.get_in_touch}
                </button>
            </div>  

    </>
    )
}

