import React from 'react';
import { ScreenLayout } from './ScreenLayout';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { Outlet, useNavigate } from 'react-router-dom';

function UserScreen() {
  const navigate = useNavigate()
  const AddUserComponent=()=>{
    return (
    <>
      <ScreenHeader headerTitle={'Manage User'}/>
      <hr style={{width:'100%'}}/>
      <div style={{display:'flex',alignItems:'center',}}>
        <div style={{borderRadius:5,height:40,display:'flex',alignItems:'center',justifyContent:'center',margin:'2vw'}}>
            <button
             style={{width:'10vw',height:'3vw', fontSize: '1vw',
             borderRadius:5, border:'1px solid #5CA3C5',
             backgroundColor:'#5CA3C5',color:'#ffffff'}}
            >All User</button>
        </div>
        <div style={{borderRadius:5,height:40,display:'flex',alignItems:'center',justifyContent:'center',margin:'2vw'}}>
            <button
             onClick={()=>navigate('addnewuser')}
             style={{width:'10vw',height:'3vw', fontSize: '1vw',
             borderRadius:5, border:'1px solid #5CA3C5',
             backgroundColor:'#5CA3C5',color:'#ffffff'}}
            >Add New User</button>
        </div>
     </div>
     <Outlet/>
    </>
    )
  }

  return (
    <ScreenLayout
    component={<AddUserComponent/> }
    />
  );
  
}

export default UserScreen;
