import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ScreenHeader from "../../Components/CommonComponents/ScreenHeader";
import { ScreenLayout } from "../ScreenLayout";
import productImage from "../../assets/fertilizer.png";
import moment from "moment";
import { useSelector } from "react-redux";
import auctionService from "../../Services/auction.service";
import { Countdown } from "../../Components/Timer/Countdown";
import "../../CSS/AuctionViewScreen.css";

function AuctionViewScreen() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [bidAmount, setBidAmount] = useState();
  const [auctionProduct, setAuctionProduct] = useState();
  const [auctionTransection, setAuctionTransection] = useState();
  const [bidAmountError, setBidAmountError] = useState("");
  const [showcaseAmountInWords, setShowcaseAmountInWords] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [timer, setTimer] = useState("00:00:00:00");
  let converter = require("number-to-words");

  useEffect(() => {
    setLoading(true);
    auctionService
      .getAuctionProductByProductId(location.state.productId)
      .then((res) => {
        setAuctionProduct(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location.state.productId]);

  useEffect(() => {
    setLoading(true);
    auctionProduct &&
      auctionService
        .getAuctionTransectioById(auctionProduct.id)
        .then((res) => {
          setAuctionTransection(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [auctionProduct]);

  let last_bid_amount =
    auctionTransection && auctionTransection.length > 0
      ? auctionTransection[auctionTransection.length - 1].bid_amount
      : 0;
  let min_increment_amount =
    auctionProduct && auctionProduct.min_increment_amount;

  let f1 =
    auctionProduct && auctionProduct.auction_type == "Normal"
      ? auctionProduct &&
        Number(auctionProduct.starting_price) + Number(min_increment_amount)
      : auctionProduct &&
        Number(auctionProduct.starting_price) - Number(min_increment_amount);

  let f2 =
    auctionProduct && auctionProduct.auction_type == "Normal"
      ? Number(last_bid_amount) + Number(min_increment_amount)
      : Number(last_bid_amount) - Number(min_increment_amount);

  let currentBidAmountShouldBe = Number(last_bid_amount) === 0 ? f1 : f2;

  let f3 =
    auctionProduct && auctionProduct.auction_type == "Normal"
      ? "higher"
      : "lower";

  let f4 =
    auctionProduct && auctionProduct.auction_type == "Normal"
      ? bidAmount >= currentBidAmountShouldBe
      : bidAmount <= currentBidAmountShouldBe;

  const handleAmount = (e) => {
    let f5 =
      auctionProduct && auctionProduct.auction_type == "Normal"
        ? e.target.value >= currentBidAmountShouldBe
        : e.target.value <= currentBidAmountShouldBe;
    setBtnDisable(true);
    if (f5) {
      setBidAmount(e.target.value);
      setBidAmountError("");
    } else {
      auctionProduct && auctionProduct.auction_type == "Normal"
        ? setBidAmountError("Amount is less then required amount.")
        : setBidAmountError("Amount is higher then required amount.");
    }

    if (e.target.value) {
      setShowcaseAmountInWords(converter.toWords(e.target.value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      auctionTransection.length > 0 &&
      user.id === auctionTransection[auctionTransection.length - 1].user
    ) {
      alert(`Can't bid now.Last bid was yours.`);
      window.location.reload();
    } else if (f4) {
      let bidData = {
        auctionProductId: auctionProduct.id,
        bidAmount: bidAmount,
      };
      bidAmount &&
        auctionService
          .postAuctionTransection(bidData)
          .then((res) => {
            if (res.request.status === 208) {
              alert(res.request.statusText);
              setLoading(false);
              window.location.reload();
            } else if (res.request.status === 200) {
              alert(`Bid updated.`);
              setLoading(false);
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
    } else {
      alert("please enter valid amount.");
      setBidAmountError("Amount is less then required amount.");
    }
  };

  return (
    <ScreenLayout
      component={
        <div>
        < >
          <ScreenHeader
            headerTitle={
              loading
                ? "Loading..."
                : auctionProduct && auctionProduct.product.product
            }
          />
          <hr style={{width:'100%'}}/>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%" }}>
              <div className="product-info-container">
                <div className="product-img">
                  <img
                    src={productImage}
                    alt="product"
                    style={{ height: 200, width: 200 }}
                  />
                </div>
                <div className="product-info">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <text style={{ width: "50%", color: "#808080" }}>
                      Product Type
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct &&
                        auctionProduct.product.productDetail.product_type}
                    </p>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <text style={{ width: "50%", color: "#808080" }}>
                      Available Quantity
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct && auctionProduct.product.count_in_stock}{" "}
                      {auctionProduct && auctionProduct.product.unit}
                    </p>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <text style={{ width: "50%", color: "#808080" }}>
                      Quality
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct &&
                        auctionProduct.product.quality &&
                        auctionProduct.product.quality.quality_types}
                    </p>
                  </div>
                </div>
                <div className="product-info">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ width: "50%", color: "#14804A" }}>
                      Current Price
                    </p>
                    <p style={{ marginRight: 15 }}>:</p>
                    <p style={{ fontWeight: 500 }}>
                      {last_bid_amount > 0
                        ? last_bid_amount
                        : auctionProduct && auctionProduct.starting_price}
                    </p>
                  </div>
                  <div className="time-remain">
                    <p style={{ width: "50%", color: "#14804A" }}>
                      Remaining Time
                    </p>
                    <p style={{ marginRight: 15 }}>:</p>
                    <p style={{ fontWeight: 500 }}>
                      <Countdown
                        setTimer={setTimer}
                        timer={timer}
                        start_date_time={
                          auctionProduct && auctionProduct.start_date_time
                        }
                        end_date_time={
                          auctionProduct && auctionProduct.end_date_time
                        }
                      />
                    </p>
                  </div>
                </div>
              </div>

              <hr style={{ width: "100%" }} />

              <div className="bid-product">
                <div style={{ width: "100%"  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                   
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <text style={{ width: "50%", color: "#808080" }}>
                        Starting Price
                      </text>
                      <text style={{ marginRight: 15 }}>:</text>
                      <p style={{ fontWeight: 500 }}>
                        {auctionProduct && auctionProduct.starting_price}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <text style={{ width: "50%", color: "#808080" }}>
                      Auction Type
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct && auctionProduct.auction_type}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <text style={{ width: "50%", color: "#808080" }}>
                      Condition
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct && auctionProduct.condition}
                    </p>
                  </div>
                  {auctionProduct && auctionProduct.reserve_price && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <text style={{ width: "50%", color: "#808080" }}>
                        Reserve Price
                      </text>
                      <text style={{ marginRight: 15 }}>:</text>
                      <p style={{ fontWeight: 500 }}>
                        {auctionProduct.reserve_price}
                      </p>
                    </div>
                  )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <text style={{ width: "50%", color: "#808080" }}>
                      Min Increment Amount
                      </text>
                      <text style={{ marginRight: 15 }}>:</text>
                      <p style={{ fontWeight: 500 }}>
                        {auctionProduct && auctionProduct.min_increment_amount}
                      </p>
                    </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <text style={{ width: "50%", color: "#808080" }}>
                      Start date
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct &&
                        moment(auctionProduct.start_date_time).format(
                          "DD/MM/YYYY - hh:mm:ss a"
                        )}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <text style={{ width: "50%", color: "#808080" }}>
                      End Date
                    </text>
                    <text style={{ marginRight: 15 }}>:</text>
                    <p style={{ fontWeight: 500 }}>
                      {auctionProduct &&
                        moment(auctionProduct.end_date_time).format(
                          "DD/MM/YYYY - hh:mm:ss a"
                        )}
                    </p>
                  </div>
                </div>

                {timer === "Time is over" ? (
                  <div className="autction-bid">
                    <>auction Complete</>
                    {auctionProduct &&
                    auctionProduct.reserve_price <= last_bid_amount ? (
                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <text style={{ width: "50%", color: "#808080" }}>
                            Sold to user
                          </text>
                          <text style={{ marginRight: 15 }}>:</text>
                          <p style={{ fontWeight: 500 }}>{"user_" + user.id}</p>
                        </div>
                      </div>
                    ) : (
                      <p style={{ width: "50%", color: "#808080" }}>
                        Not sold. Auction amount is not higher than Reserve
                        Price
                      </p>
                    )}
                  </div>
                ) : (
                  <div className='autction-bid'>
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      onSubmit={handleSubmit}
                    >
                      <label
                        style={{
                          margin: 5,
                          height: "100%",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Bid
                      </label>
                      <input
                        style={{
                          borderRadius: 5,
                          border: "1px solid black",
                          margin: 10,
                        }}
                        type="number"
                        required
                        placeholder="Enter amount"
                        onChange={(e) => handleAmount(e)}
                      />
                      <label
                        style={{
                          margin: 5,
                          height: "100%",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          color: "gray",
                        }}
                      >
                        {showcaseAmountInWords}
                      </label>
                      <input
                        style={{
                          borderRadius: 5,
                          border: "1px solid black",
                          margin: 10,
                        }}
                        type="text"
                        required
                        placeholder="Enter amount in words"
                        onChange={(e) => {
                          e.target.value === showcaseAmountInWords
                            ? setBtnDisable(false)
                            : setBtnDisable(true);
                        }}
                      />
                      <button
                        disabled={btnDisable}
                        className='button-bid'

                        type="submit"
                      >
                        Bid
                      </button>
                    </form>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {bidAmountError && (
                        <p style={{ width: "50%", color: "#D1293D" }}>
                          {bidAmountError}
                        </p>
                      )}
                      <p className="p-amount">
                        Enter amount {currentBidAmountShouldBe} or {f3}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <hr style={{ width: "100%" }} />

              <div style={{ display: "flex", flexDirection: "column" }}>
                <ScreenHeader headerTitle={"All Biddings"} />
                <table
                  style={{
                    border: "solid 1px #BDBDBD",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                >
                  <tr
                    style={{
                      backgroundColor: "rgba(93, 167, 202, 0.2)",
                      textAlign: "center",
                      height: 55,
                    }}
                  >
                    <th>User</th>
                    <th>Bid</th>
                    <th>Date - Time</th>
                  </tr>
                  {auctionTransection &&
                    [...auctionTransection].reverse().map((data, key) => (
                      <tr style={{ height: 40 }} key={key}>
                        <td
                          style={{
                            borderTop: "1px solid #BDBDBD",
                            textAlign: "center",
                          }}
                        >
                          {auctionProduct && auctionProduct.make_sealed
                            ? data && data.user
                            : data && data.user}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid #BDBDBD",
                            textAlign: "center",
                          }}
                        >
                          {data && data.bid_amount}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid #BDBDBD",
                            textAlign: "center",
                          }}
                        >
                          {data &&
                            moment(data.created_at)
                              .utcOffset("+05:30")
                              .format("DD/MM/YYYY - hh:mm:ss a")}
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          </div>
        </>
        </div>
      }
    />
  );
}

export default AuctionViewScreen;
