import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from './ScreenLayout';
import productImage from '../assets/fertilizer.png';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userService from '../Services/user.service';
import PaymentComponent from '../Components/ProductViewComponents/PaymentComponent';
import ProductStatusBaseAction from '../Components/ProductViewComponents/ProductStatusBaseAction';
import '../CSS/ProductViewScreen.css'


function ProductViewScreen() {
    const location = useLocation()
    const navigate = useNavigate()
    const {user} = useSelector((state)=> state.auth)
    const [loading, setLoading] = useState(false)
    const [dateofdelivery, setDateOfDelivery] = useState('');
    const [requiredquantity, setRequiredQuantity] = useState('');
    const [unit, setUnit] = useState('');
    const [deliverylocation, setDeliveryLocation] = useState('');
    const [originAddress, setOriginAddress] = useState('');
    const [regionOfOrigin, setRegionOfOrigin] = useState('');
    const [countryOfOrigin, setCountryOfOrigin] = useState('');
    const [message, setMessage] = useState('');
    const [submitresponse, setSubmitResponse] = useState([])
    const [product, setProduct] = useState([])
    const [orderEnquiry, setOrderEnquiry] = useState([])
    const [status, setStatus] = useState('')
    const [subtotal, setSubtotal] = useState()
    const [qualityCheck, setQualityCheck] = useState('no')
    const [logisticsCheck, setLogisticsCheck] = useState('no')
    const [logisticsVAS, setLogisticsVAS] = useState([]);  //service quatation array-logistics
    const [qualityControlCertification, setQualityControlCertification] = useState([]); //service quatation array-quality
    const [farmerWarehouse, setFarmerWarehouse] = useState([]); //service quatation array-quality
    const [logisticBuyerDelivery, setLogisticBuyerDelivery] = useState([]); //service quatation array-quality
    const handleQCC=(value, checked )=>{
        if(checked){
          setQualityControlCertification([...qualityControlCertification, value])
        }
        else{
          setQualityControlCertification(qualityControlCertification.filter((QCC)=> QCC !== value))
        }
      }

    const handleFW=(value, checked )=>{
        if(checked){
          setFarmerWarehouse([...farmerWarehouse, value])
        }
        else{
          setFarmerWarehouse(farmerWarehouse.filter((FW)=> FW !== value))
        }
      }

    const handleLBD=(value, checked )=>{
        if(checked){
          setLogisticBuyerDelivery([...logisticBuyerDelivery, value])
        }
        else{
          setLogisticBuyerDelivery(logisticBuyerDelivery.filter((LBD)=> LBD !== value))
        }
      }
    const qualityFunc = [handleQCC, handleFW, handleLBD]

    const vasData = {
        "logistics" :['Loading Costs','Origin Terminal Handling Charge','Origin Inland Haulage','Export Customs Formalities', 'Insurance',
                       'Destination Warehouseing','Import Customs Formalities','Destination Terminal Handling Charge',
                      'Discharge Costs','Main Carriage Freight'],
        "quality" : [
                      {'Quality control and Certification':['Fit for Animal consumption','Fit for Human consumption',
                                                            'Free from undesirable substances','Non-GMO certification']
                      },
                      {'Farmer\'s Warehouse':['Cleanliness inspections','Sampling and Quality assessment',
                                                            'Metering services','Loading and discharge supervision']
                      },
                      {'Logistics and Buyers delivery':['Loading and discharge servision','Quality control','Damage Servey']}
      ]
      }
      
    useEffect(()=>{
                  setLoading(true)
                  userService.getProductById(location.state.productId)
                  .then((res)=>{
                      setProduct(res.data)
                      setLoading(false)
                    })
                    .catch((error)=>{
                        console.log(error)
                        setLoading(false)
                  })

   
                  userService.getProductStatusByUserId(location.state.productId)
                  .then((res)=>{
                      if(res.data){
                        setStatus(res.data.status)
                       }
                      })
                  .catch((error)=>{
                      console.log(error)
                  })

                  userService.getOrderEnquiryByUserProductId({productId:location.state.productId})
                  .then((res)=>{
                    setOrderEnquiry(res.data)
                      })
                  .catch((error)=>{
                      console.log(error)
                  })

            if(submitresponse === 200){
                alert(`Your Quote successfully sent to the seller.You can track the order details in Order History page.`)
            }
    },[submitresponse, location.state.productId])

    useEffect(()=>{
        setSubtotal(requiredquantity * product.price)
    },[requiredquantity, unit, product.price])

    useEffect(()=>{
        window.scrollTo(0,0)
      },[status])
    
    const orderEnquiryData={
        productId : location.state.productId,
        dateOfDelivery: dateofdelivery,
        requiredQuantity: requiredquantity,
        unit : unit,
        message:message,
        zipcode: deliverylocation,
        originAddress:originAddress,
        regionOfOrigin:regionOfOrigin,
        countryOfOrigin:countryOfOrigin,
        logisticsVAS:logisticsVAS,
        qualityControlCertification:qualityControlCertification,
        farmerWarehouse:farmerWarehouse,
        logisticBuyerDelivery:logisticBuyerDelivery
    }

    const handleLogisticsVAS=(value, checked )=>{
        if(checked){
          setLogisticsVAS([...logisticsVAS, value])
        }
        else{
          setLogisticsVAS(logisticsVAS.filter((LBD)=> LBD !== value))
        }
      }

    const submitEnquiryForm=(event)=>{
            event.preventDefault();
            setLoading(true)
            console.log('form submitted');
            alert(JSON.stringify(orderEnquiryData))

            userService.postOrderEnquiry(orderEnquiryData)
            .then((response)=>{
                setSubmitResponse(response.status)
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error)
                setLoading(false)
            })
    }

    return (
        <ScreenLayout component={
            <div>
                <ScreenHeader headerTitle={loading ? 'Loading...' :product && product.product}/>
                <div className='main-div'>
                    <div style={{width:'100%'}}>
                            <div className='df-dir-row abcd'>  
                                <img src={product && product.newProduct && product.newProduct.icon} alt='product-icon'
                                    style={{height:150, objectFit:'contain', borderRadius:10, alignSelf:'center'}}
                                />
                                <div className='product-img-sib-div' >
                                    <div className='df-dir-row abcdaa'>
                                        <text className='product-text-style'>Available Quantity</text>
                                        <text className='text-colon'>:</text>
                                        <p className='para-font-weight'>{product.count_in_stock ? product.count_in_stock + " " + product?.unit : '---'}</p>
                                    </div>
                                    <div className='df-dir-row'>
                                        <text className='product-text-style'>Minimum Order Quantity</text>
                                        <text className='text-colon'>:</text>
                                        <p className='para-font-weight'>{product.min_order_quantity ? product.min_order_quantity+ " " + product?.unit : '---'}</p>
                                    </div>
                                    <div className='df-dir-row'>
                                        <text className='product-text-style'>Price Range per {product.unit}</text>
                                        <text className='text-colon'>:</text>
                                        <p className='para-font-weight'>{product.price ? 'Rs. '+product.price : '---'}</p> 
                                    </div>
                                    <div className='df-dir-row'>
                                        <text className='product-text-style'>Gross Amount</text>
                                        <text className='text-colon'>:</text>
                                        <p className='para-font-weight'>{product.price ? 'Rs. '+ product.price * product.count_in_stock : '---'}</p> 
                                    </div>
                                    <div className='df-dir-row'>
                                    <text className='product-text-style'>Status</text>
                                    <text className='text-colon'>:</text>
                                    <p className='para-font-weight'>{status && status}</p>
                                </div>
                                </div>
                            </div>
                        <hr style={{width:'100%',}} />

                            <div className='date-wrap'>
                                <div className='df-dir-row'>
                                    <text className='product-text-style'>Initial Delivery Date</text>
                                    <text className='text-colon'>:</text>
                                    <p className='para-font-weight'>
                                        {
                                        product.logistics && product.logistics.initial_delivery_date ?
                                        moment(product.logistics.initial_delivery_date)
                                        .utc()
                                        .format('DD/MM/YYYY')
                                        : 'Not-Define'
                                        }
                                    </p> 
                                </div>

                                <div className='df-dir-row'>
                                    <text className='product-text-style'>Final Delivery Date</text>
                                    <text className='text-colon'>:</text>
                                    <p className='para-font-weight'>
                                        {
                                        product.logistics && product.logistics.final_delivery_date ? 
                                        moment()
                                        .utc(product.logistics.final_delivery_date)
                                        .format('DD/MM/YYYY')
                                        : 'Not-Define'
                                        }
                                    </p> 
                                </div>

                                <div className='df-dir-row'>
                                <text className='product-text-style'>Offer Expiry Date</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>
                                    {
                                    product.logistics && product.logistics.offer_expiration_date ? 
                                    moment(product.logistics.offer_expiration_date && product.logistics.offer_expiration_date)
                                    .utc()
                                    .format('DD/MM/YYYY')
                                    : 'Not-Define'
                                    }
                                </p> 
                                </div>

                                <div className='df-dir-row'>
                                <text className='product-text-style'>Logistics</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>
                                    {product.logistics && product.logistics.logistics_incoterms ? product.logistics.logistics_incoterms : 'Not-Define' }
                                </p> 
                                </div>
                                
                                <div className='df-dir-row'>
                                <text className='product-text-style'>Region/Country of Origin</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>{
                                    product.logistics && product.logistics.logistics_incoterms ? product.logistics.logistics_incoterms : 'Not-Define'
                                }</p> 
                                </div>
                            </div>

                        <hr style={{width:'100%',}} />

                            <div className='df-dir-row'>
                                <text className='product-details'>Product details</text>
                            </div>
                            <div className='df-dir-row'>
                                <text className='product-text-style'>Product type</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>{product?.productDetail?.product_type ? 
                                                                product?.productDetail?.product_type : 
                                                                'Not-Define'
                                                            }
                                </p> 
                            </div>
                            <div className='df-dir-row'>
                                <text className='product-text-style'>Production Mode</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>{product?.productDetail?.production_mode ? 
                                                                product?.productDetail?.production_mode : 
                                                                'Not-Define'
                                                            }
                                </p> 
                            </div>
                            <div className='df-dir-row'>
                                <text className='product-text-style'>Variety</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>{product?.productDetail?.variety ? 
                                                                product?.productDetail?.variety : 
                                                                'Not-Define'
                                                            }
                                </p> 
                            </div>
                            <div className='df-dir-row'>
                                <text className='product-text-style'>Genetically Modified?</text>
                                <text className='text-colon'>:</text>
                                <p className='para-font-weight'>{product?.productDetail?.genetically_modyfied ? 
                                                                product?.productDetail?.genetically_modyfied : 
                                                                'Not-Define'
                                                            }
                                </p> 
                            </div>

                        <hr style={{width:'100%',}} />

                            <div style={{display:'flex', flexDirection:'row'}}>
                                {
                                 product && product.product_image && product.product_image.map((image,key)=>(
                                        <img key={key} src={image.media} style={{margin:5, width:200, borderRadius:10}}/>
                                    ))
                                }
                            </div>
                        <hr style={{width:'100%',}} />
                            <img src={product && product.quality && product.quality.document} style={{margin:5, width:200, borderRadius:10}}/>
                        <hr style={{width:'100%',}} />

                        </div>
                
                        <div style={{width:'100%'}}>
                        <div className='main-div'>
                            <div id='quotation-form' style={{display:status==='Available' ? 'block' : 'none',}}>
                                <div className='date-wrap'>
                                <text className='quotation-text'>Send  a quote</text>

                                <div className='df-dir-row send-a-quote-responsive'>
                                    <div className='wrap-label-input'>
                                    <label className='label-width-full'>Date of Delivery</label>
                                    <input className='date-input'
                                        type='date'
                                        onChange={(e)=>setDateOfDelivery(e.target.value)}
                                    />
                                    </div>
                                </div>
                                <div className='df-dir-row send-a-quote-responsive'>
                                    <div className='wrap-label-input'>
                                        <label className='label-width-full-margin'>Quantity</label>
                                        <input className='height-full' type='number'
                                            placeholder='Quantity'
                                            onChange={(e)=>setRequiredQuantity(e.target.value)}
                                        />
                                    </div>

                                    <div className='wrap-label-input'>
                                        <label className='label-width-half-height-full'>Unit</label>
                                        <select className='height-full' 
                                                onChange={e=>setUnit(e.target.value)}> 
                                            <option value=''>select</option>
                                            <option value={product.unit}>{product.unit}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='df-dir-row send-a-quote-responsive'>
                                    <div className='wrap-label-input'>
                                    <label className='label-width-full-margin'>Origin Address</label>
                                    <input className='width-height-full' 
                                            type='text'
                                            placeholder='Origin address'
                                            onChange={(e)=>setOriginAddress(e.target.value)}
                                    />
                                    </div>

                                    <div className='wrap-label-input'>
                                    <label className='label-width-full-margin'>Region of Origin</label>
                                    <input className='width-height-full'
                                            type='text'
                                            placeholder='Region of Origin'
                                            onChange={(e)=>setRegionOfOrigin(e.target.value)}

                                    />
                                    </div>
                                </div>

                                <div className='df-dir-row send-a-quote-responsive'>
                                    <div className='wrap-label-input'>
                                        <label className='label-width-full-margin'>Zipcode</label>
                                        <input className='width-height-full' 
                                            type='number'
                                            onChange={(e)=>setDeliveryLocation(e.target.value)}
                                        />
                                    </div>

                                    <div className='wrap-label-input'>
                                        <label className='label-width-full-margin'>Country of Origin</label>
                                        <select
                                            className='width-height-full'
                                            onChange={e=>setCountryOfOrigin(e.target.value)}
                                        > 
                                            <option value=''>select</option>
                                            <option value='India'>India</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='msg-div'>
                                        <label className='label-width-full'>Message</label>
                                        <textarea 
                                            rows={5}
                                            placeholder='Message for Seller (if any)' 
                                            className='msg-textarea'
                                            onChange={(e)=>setMessage(e.target.value)}
                                        />
                                </div> 
                                </div> 

                            <hr style={{width:'100%'}}/>

                                <div className='log-main-div'>
                                    <div className='log-tag'>
                                        <label className='label-width-full-margin'>Logistics Incoterms</label>
                                        <input
                                            className='input-third'
                                            defaultValue={product.logistics && product.logistics.logistics_incoterms}
                                            readOnly
                                        />   
                                    </div>

                                    <div className='service-div'>
                                    <label className='service-label'>Would you like to have service quotation?(Optional)</label>
                                    <div  className='df-dir-row'>
                                        <label
                                        className='service-yes-no'
                                        >Yes
                                            <input
                                                type='radio'
                                                style={{margin:20}}
                                                name='service-logistics'
                                                value='yes'
                                                onClick={(e)=>setLogisticsCheck(e.target.value)}
                                            />
                                        </label>
                                        <label
                                        className='service-yes-no'
                                        >No
                                            <input
                                                type='radio'
                                                style={{margin:20}}
                                                name='service-logistics'
                                                value='no'
                                                onClick={(e)=>{setLogisticsCheck(e.target.value); setLogisticsVAS([])}}
                                                defaultChecked
                                            />
                                        </label>
                                    </div>
                                    </div>

                                    <div 
                                    style={{pointerEvents: logisticsCheck==='no' ? 'none' : 'auto', opacity: logisticsCheck==='no' ? 0.6 : 1}}
                                    >
                                        <label className='req-val-label'>Optional - request values for :</label>
                                        <div className='wrap-box-div'>
                                            <div className='wrap-box'>
                                            {vasData && vasData['logistics'].map((value, key)=>(
                                                <label
                                                        key={key}
                                                        htmlFor={"logistics_vas_"+key}
                                                        className='wrap-box-label'>
                                                    <input 
                                                    id={"logistics_vas_"+key}
                                                    type='checkbox'
                                                    value={value}
                                                    style={{margin:20}}
                                                    onClick={(e)=>handleLogisticsVAS(e.target.value, e.target.checked)}
                                                    checked={logisticsCheck==='no' ? false : null}
                                                    />
                                                    {value}
                                                </label>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            <hr style={{width:'100%'}}/>

                                <label className='quality-label'>QUALITY</label>
                                <div className='service-div'>
                                    <label className='service-label'>Would you like to have service quotation?(Optional)</label>
                                    <div  className='df-dir-row'>
                                        <label
                                        className='service-yes-no'
                                        >Yes
                                            <input
                                                id='radio-A'
                                                type='radio'
                                                style={{margin:20}}
                                                name='service-qutation'
                                                value='yes'
                                                onClick={(e)=>setQualityCheck(e.target.value)}
                                            />
                                        </label>
                                        <label
                                        className='service-yes-no'
                                        >No
                                            <input
                                                id='radio-B'
                                                type='radio'
                                                style={{margin:20}}
                                                name='service-qutation'
                                                value='no'
                                                onClick={(e)=>{setQualityCheck(e.target.value);
                                                                setQualityControlCertification([]);
                                                                setFarmerWarehouse([]);
                                                                setLogisticBuyerDelivery([])
                                                                }}
                                                defaultChecked
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div style={{pointerEvents: qualityCheck==='no' ? 'none' : 'auto', opacity: qualityCheck==='no' ? 0.6 : 1}}>
                                    
                                    <div className='quality-div'>
                                        <div className='quality-div-child'>
                                        {
                                            vasData && vasData.quality.map((value,key1)=>{
                                            return (
                                            <div key={key1}className="main-div">
                                                <label className='req-val-label'>{Object.keys(value)} :</label>
                                                <div className='quality-div-wrap'>
                                                {
                                                    Object.keys(value).map((res)=>(
                                                    value[res].map((qualityValue,key2)=>(
                                                        <label
                                                                key={key2}
                                                                htmlFor={"quality_vas_"+key1+key2}
                                                                className='wrap-box-label'>
                                                            <input 
                                                                id={"quality_vas_"+key1+key2}
                                                                type='checkbox'
                                                                value={qualityValue}
                                                                style={{margin:20}}
                                                                onClick={(e)=>qualityFunc[key1](e.target.value, e.target.checked)}
                                                                checked={qualityCheck==='no' ? false : null}
                                                            />
                                                                {qualityValue}
                                                        </label> 
                                                        )
                                                        )
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                            )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                                <hr style={{width:'100%'}}/>
                            </div>

                            { user  ?
                                        product.user === user.id 
                                    ?
                                        <div className='product-div'>
                                            This product belongs to you.
                                        </div>
                                    :
                                        <ProductStatusBaseAction
                                        orderEnquiryId = {orderEnquiry && orderEnquiry.id}
                                        status={status && status}
                                        subtotal={orderEnquiry && orderEnquiry.product ? (orderEnquiry.required_quantity * orderEnquiry.product.price) : subtotal}
                                        quantity={orderEnquiry && orderEnquiry.required_quantity +" "+ orderEnquiry.unit}
                                        submitEnquiryForm={submitEnquiryForm}
                                        />
                                    :
                             
                                        <div className='btn-div'>
                                            <button className='sign-button' onClick={()=>navigate('/login')}>
                                                Sign in
                                            </button> 
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        } 
      />
    );
    }
    
    export default ProductViewScreen;