import React, { useState,useEffect } from 'react';
import { TableDate } from './HSTableComponents/TableDate';
import moment from "moment";
import { useSelector } from 'react-redux';
import userService from '../../Services/user.service';
import ReactPaginate from 'react-paginate';
import '../../CSS/MarketPriceDashboard.css';

function RequirementProductList({navigate}) {
  const {user} = useSelector((state)=> state.auth)
  const {internationalization} = useSelector((state)=> state.language)
  const [requirementProduct, SetRequirementroduct] = useState([])
  const [orderEnquiryError, SetOrderEnquiryError] = useState('')
  const [loading, SetLoading] = useState(false)
  const [count, SetCount] = useState(true)
     useEffect(()=>{
      SetLoading(true)
      userService.getRequirementProduct()
                 .then((res)=>{
                  // console.log('requirement product :: ',res.data)
                  SetRequirementroduct(res.data)
                  SetLoading(false)
                  })
               .catch((error)=>{
                  SetOrderEnquiryError(error.message)
                  SetRequirementroduct([])
                  SetLoading(false)
                  })
      },[])

   // pagination
   const [currentItems, setCurrentItems] = useState(null);
   const [pageCount, setPageCount] = useState(0);
   const itemsPerPage = 5
   // Here we use item offsets; we could also use page offsets
   // following the API or data you're working with.
   const [itemOffset, setItemOffset] = useState(0);

   useEffect(() => {
     // Fetch items from another resources.
     const endOffset = itemOffset + itemsPerPage;
   //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     setCurrentItems(requirementProduct && requirementProduct.slice(itemOffset, endOffset));
     setPageCount(Math.ceil(requirementProduct && requirementProduct.length / itemsPerPage));
   }, [itemOffset, itemsPerPage, requirementProduct]);
 
   // Invoke when user click to request another page.
   const handlePageClick = (event) => {
     const newOffset = (event.selected * itemsPerPage) % requirementProduct.length;
   //   console.log(
   //     `User requested page number ${event.selected}, which is offset ${newOffset}`
   //   );
     setItemOffset(newOffset);
   };
   // pagination

   const ActiveListTable=({index, productId, productName, uploadDate, ExpiryDate, maxPrice, quantity, unit})=>{
      if(index===0){
         return <td className='table-data-cell'>{'orderEnquiryError'}</td>
      }
      return(
         <tr>
        <td className='table-data-cell'>{index}</td>
        <td className='table-data-cell'>{productName}</td>
        <td className='table-data-cell'>
           <TableDate date={uploadDate}/>
        </td>
        <td className='table-data-cell'>
           <TableDate date={ExpiryDate}/>
        </td>
        <td className='table-data-cell'>Rs. {maxPrice}</td>
        <td className='table-data-cell'>{quantity}</td>
        <td className='table-data-cell'>{unit}</td>
        <td className='table-data-cell'>
           <button 
            style={{
                  width:90,
                  height:30,
                  cursor:'pointer',
                  backgroundColor: 'rgba(93, 167, 202, 0.4)',
                  borderRadius:5,
                  border: '1px solid #E1FCEF',
                  color:'#000000',
               }} 
            onClick={()=>navigate('requirementproductview', {state:{productId:productId, productName:productName}})}>{internationalization.enquiry}</button>
         </td>
      </tr>
      )
   }

  return (
   <div className='live-market-price'>
   {/* <ScreenHeader headerTitle={'To Sell'} /> */}
   <text className='sub-text-header'>
         {internationalization.to_sell}
   </text>
    <table className='live-market-price-table'>
      <tr className='table-header'>
        <th  className='market-price-table-heading'>#</th>
        <th  className='market-price-table-heading'>{internationalization.product}</th>
        <th  className='market-price-table-heading'>{internationalization.upload_date}</th>
        <th  className='market-price-table-heading'>{internationalization.expiry_date}</th>
        <th  className='market-price-table-heading'>{internationalization.target_price}</th>
        <th  className='market-price-table-heading'>{internationalization.quantity_require}</th>
        <th  className='market-price-table-heading'>{internationalization.unit}</th>
        <th  className='market-price-table-heading'>{internationalization.show}</th>
     </tr>
     {
         loading &&
         <tr>
            <td colSpan='8' className='table-loading' >loading...</td>
         </tr>
      }
     {!loading && orderEnquiryError && <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>{orderEnquiryError}</td>}
     {count && <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>No Product Found</td>}
     {  currentItems && currentItems.map((commodity, key)=>{
        if(commodity.user !== user.id){
            if(count===true){
               SetCount(false)
            }
            return(
               <ActiveListTable
                  key={key}
                  index={key+1+itemOffset}
                  productId={commodity.id}
                  productName={commodity.product}
                  uploadDate={moment(commodity.created_at).utc().format('DD/MM/YYYY')}
                  ExpiryDate={moment(commodity.expiry_date).utc().format('DD/MM/YYYY')}
                  maxPrice={commodity.price}
                  quantity={commodity.count_in_stock}
                  unit={commodity.unit}
               />
            )
         }
         })
         }
     
    </table>

    <ReactPaginate
            previousLabel={"<"+internationalization.previous}
            breakLabel="..."
            nextLabel={internationalization.next+">"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            renderOnZeroPageCount={null}
            className="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
        />
   </div>
  );
}

export default RequirementProductList;
