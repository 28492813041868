import React, { useState } from 'react'
import sea from '../../../assets/sea.png'
import '../../../CSS/Requriments.css'
import india from '../../../assets/india.png';
import userService from '../../../Services/user.service';
import AG_BG from '../../../assets/AG_BG.webp'
import { useSelector } from 'react-redux';
const Requriments = () => {
    const [psName, setPSname] = useState()
    const [phone, setPhone] = useState()
    const [name, setName] = useState()
    const {internationalization} = useSelector(state=>state.language)

    const statistics = [
        {name:internationalization.product_categories, data:6},
        {name:internationalization.brand, data:100},
        {name:internationalization.active_suppliers, data:1000},
        {name:internationalization.deliveries, data:2000},
    ]

    const handleSubmit=(e)=>{
        e.preventDefault()
        let Data = {
            psName:psName,
            phone:phone,
            name:name
        }

        userService.postRequirementQuote(Data)
                   .then((res)=>{
                    console.log(res.data)
                    alert('Message send')
                    window.location.reload()
                   })
                   .catch((error)=>{
                    alert('something went wrong, please try again later.')
                   })
  }
  return (
            <div className="requirement-image-div">
                <img
                    src={AG_BG}
                    alt="Background"
                    className="bottom-bg-img-hidd"
                /> 
                <div style={{position:'relative'}} >
                    <div className='our-solution-bottom-image-child-img-shadow'>
                        <div className='our-sol-left-img-sub-img'>
                            {statistics.map((stats, key)=>(
                            <div
                                key={key}
                                style={{
                                width:'50%',
                                height:'50%',
                                borderRadius:10,
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                alignItems:'center'
                                }}
                            >
                                <div className='ex-smal-d-font-size'>{stats.data}+</div>
                                <div className='ex-smal-d-font-size'><small><strong>{stats.name}</strong></small></div>
                            </div>
                            ))}
                        </div>
                    </div>
                    <img src={sea} alt="sea" className="our-solution-bottom-image-child-img"/>
                </div>

                <div style={{ textAlign:'center'}}>
                        <form
                            onSubmit={handleSubmit}
                            className="our-solution-bottom-rightside-imag"
                        >
                            <div className="form-group" style={{padding:15, margin:5,display:'flex', flexDirection:'column',alignItems:'center'}}>
                             <h3 className="card-title" style={{marginBottom:"50px"}}>{internationalization.request_quote}</h3>
                                     <label htmlFor="exampleInputEmail1">{internationalization.enter_product_service_name}
                                     <input
                                         type="text"
                                         className="form-control"
                                         id="exampleInputEmail1"
                                         aria-describedby="emailHelp"
                                         placeholder={internationalization.for_example}
                                         required
                                         onChange={(e)=>setPSname(e.target.value)}
                                     />
                                     </label>
                                     <label htmlFor="exampleInputPassword1">{internationalization.enter_your_mobile_number}
                                         <div className="input-group">
                                             <div style={{
                                                border:'1px solid #ced4da',
                                                borderRadius:'5px 0px 0px 5px',
                                                display:'flex',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                padding:2,
                                             }}
                                             >
                                             <img src={india} width="32vw" alt="india.png"/>   
                                             </div>
                                         <input
                                             type="number"
                                             className="form-control"
                                             placeholder="+91"
                                             aria-label="Username"
                                             aria-describedby="basic-addon1"
                                             required
                                             onChange={(e)=>setPhone(e.target.value)}
                                         />
                                         </div>
                                     </label>
                                     <label htmlFor="exampleInputPassword1">{internationalization.full_name}
                                     <input
                                         type="name"
                                         className="form-control"
                                         placeholder={internationalization.enter_your_name}
                                         required
                                         onChange={(e)=>setName(e.target.value)}
                                     />
                                     </label>
                                 <button type="submit" className="submit-req-btn">{internationalization.submit_requirement}</button>
                                </div>
                             </form>
                        </div>
                    </div>
  )
}

export default Requriments
