import React from 'react';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from './ScreenLayout';

function CropwayGuide() {

  const CropwayGuideComponent=()=>{
      return(
          <div>
          <ScreenHeader headerTitle={'Cropway Guide'}/>
          <hr style={{width:'100%'}}/>
          <div className='mt-3'>
            <h5 className='my-1'>Status</h5>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#14804A'}}>Active</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>Buyer sends quatation to seller.</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#FB8700'}}>Pending</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>Seller accepted the buyer's order.</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#D1293D'}}>Cancelled</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>Seller Declined buyer's order.</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>Fulfilled</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>Seller complited buyer's order.</p>
            </div>
          </div>
        </div>
      )
  }
  return (
    <ScreenLayout component={<CropwayGuideComponent/>}/>
  );
}

export default CropwayGuide;
