// Data service
import axios from "axios";
import authHeader from "./auth-header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/sellerstudio/";

const getDistricts = () => {
   return axios.get('https://recent---pricepred-jkl2gczn2a-el.a.run.app/getDistricts/Chhattisgarh')
};

const getMarketPriceUpdate = (lang, limit, page, state, district, market, commodity) => {
   // https://mandi---pricepred-jkl2gczn2a-el.a.run.app/getNewPreviousPrices/en/20/1?state=Chhattisgarh&district=Dhamtari&market=Dhamtari&commodity=Paddy(Dhan)(Common)
   return axios.get(`https://mandi---pricepred-jkl2gczn2a-el.a.run.app/getNewPreviousPrices/${lang}/${limit}/${page}`,
   {
      headers : {'ngrok-skip-browser-warning':"69420"},
      params: {
         state:state,
         district:district,
         market:market,
         commodity:commodity,
      }
   }
   )
};

const getMarketPrice = (district, lang ,page) => {
   return axios.get(`https://recent---pricepred-jkl2gczn2a-el.a.run.app/getPreviousPrices/Chhattisgarh/${district}/${lang}/10/${page}`)
   // return axios.get(`https://pricepred-jkl2gczn2a-el.a.run.app/getPreviousPrices/Chhattisgarh/${district}`)
};

const getProduct = () => {
   return axios.get(API_URL+'productupload/',
   {
      headers: authHeader()
   }
   )
};

const getSellProduct = () => {
   return axios.get(API_URL+'getsellproduct/')
};

const getRequirementProduct = () => {
   return axios.get(API_URL+'getrequirementproduct/')
};

const getRequirementProductByUser = () => {
   return axios.get(API_URL+'getrequirementproductbyuser/',
   {
      headers: authHeader()
   }
   )
};

const getProductById = (productId) => {
   return axios.get(API_URL+`getproductbyid/${productId}/`)
};

const getProductByUserId = () => {
   return axios.get(API_URL+`getproductsbyuserid/`,
   {
      headers: authHeader()
   }
   )
};

const getProductStatusByUserId = (productId) => {
   return axios.post(API_URL+'checkproductstatus/',
       {"productId" : productId}, 
       {headers: authHeader()}
                    )
};

const getProductCategory = () => {
   return axios.get(API_URL+'productcategory/',
       {headers: authHeader()}
                    )
};

const postProductData = (productData) => {
   return axios.post(API_URL+'productupload/', 
   productData, 
   {headers: authHeader()}
   )
};

const deleteProduct = (productId) => {
   return axios.delete(API_URL+'productupload/',
   {
      headers: authHeader(),
      params: {productId : productId}
   }
   )
};

const postOrderEnquiry = (orderEnquiryData) => {
   return axios.post(API_URL+'generateorderenquiry/',
   orderEnquiryData,
   {headers : authHeader()}
   )
};

const getOrderEnquiry = () => {
   return axios.get(API_URL+'generateorderenquiry/',
   {headers : authHeader()}
   )
};

const postRequirementEnquiry = (requirementEnquiryData) => {
   return axios.post(API_URL+'generaterequirementenquiry/',
   requirementEnquiryData,
   {headers : authHeader()}
   )
};

const getRequirementEnquiry = (productId) => {
   return axios.get(API_URL+'generaterequirementenquiry/',
   {
     headers : authHeader(),
     params : {productId : productId}
   }
   )
};

const getOrderEnquiryById = (orderEnquiryId) => {
   return axios.get(API_URL+`getorderenquirybyid/${orderEnquiryId}/`)
};

const getOrderEnquiryByUserId = () => {
   return axios.get(API_URL+`getorderenquirybyuser/`,
   {
      headers: authHeader()
   }
   )
};

const getOrderEnquiryByUserProductId = (data) => {
   return axios.post(API_URL+`getorderenquirybyuserproductid/`, data, {headers:authHeader()})
};

const updateOrderEnquiryStatus = (data) => {
   return axios.post(API_URL+`orderenquirytransectionstatus/`, data)
};

const postGetInTouch = (data) => {
   return axios.post(API_URL+`postgetintouch/`, data)
};

const postRequirementQuote = (data) => {
   return axios.post(API_URL+`postrequirementquote/`, data)
};

const downloadOrderData = (data) => {
   return axios.post(API_URL+`downloadorderdata/`, data)
};

const updateProductState = (data) => {
   console.log(data)
   return axios.post(API_URL+`updateproductstate/`, data)
};

const getIvioce = (txn_id) => {
   return axios.get(API_URL+`getinvoice/${txn_id}/`)
};

const getProductMarketplace = (page) => {
   return axios.get(API_URL+`getproductmarketplace/`,
   {
    params: {
        page: page
      }
    }
   )
};

export default {
  getDistricts,
  getMarketPrice,
  getProduct,
  getSellProduct,
  getRequirementProduct,
  getRequirementProductByUser,
  getProductById,
  getProductByUserId,
  getProductStatusByUserId,
  postOrderEnquiry,
  postRequirementEnquiry,
  getRequirementEnquiry,
  getProductCategory,
  postProductData,
  deleteProduct,
  getOrderEnquiry,
  getOrderEnquiryById,
  getOrderEnquiryByUserId,
  getOrderEnquiryByUserProductId,
  updateOrderEnquiryStatus,
  postGetInTouch,
  postRequirementQuote,
  downloadOrderData,
  updateProductState,
  getIvioce,
  getProductMarketplace,
  getMarketPriceUpdate
};