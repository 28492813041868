import React from "react";
import { useNavigate } from "react-router-dom";
import FirstSection from "../Components/WelcomeScreenComponent/FirstSection";
import ThirdSection from "../Components/WelcomeScreenComponent/ThirdSection";
import FourthSection from "../Components/WelcomeScreenComponent/FourthSection";
import FifthSection from "../Components/WelcomeScreenComponent/FifthSection";
import SecondSection from "../Components/WelcomeScreenComponent/SecondSection";
import SixithSection from "../Components/WelcomeScreenComponent/SixthSection";
import SevanthSection from "../Components/WelcomeScreenComponent/SevanthSection";
import Allcategories from "../Components/LandingPageComponents/CategoriesComponents/Allcategories";
import Requriments from "../Components/LandingPageComponents/CategoriesComponents/Requriments";
import AllCardCategoryDetails from '../Components/LandingPageComponents/CategoryDetailCard';
export default function WelcomeScreen(){
    const navigate = useNavigate()
    return(
        <div>
            <FirstSection navigate={navigate} />
            <SecondSection/>
            <AllCardCategoryDetails/>
            <Requriments/>
            <ThirdSection navigate={navigate} />
            <FourthSection navigate={navigate} />
            <FifthSection navigate={navigate} />
            <SixithSection/>
            <SevanthSection/>
        </div>
    )
}