import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from './ScreenLayout';
import productImage from '../assets/fertilizer.png';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userService from '../Services/user.service';

function RequirementProductViewScreen() {
    const location = useLocation()
    const navigate = useNavigate()
    const {user} = useSelector((state)=> state.auth)
    const [loading, setLoading] = useState(false)
    const [requirementEnquiry, setRequirementEnquiry] = useState([]);
    const [availablequantity, setAvailableQuantity] = useState('');
    const [unit, setUnit] = useState('');
    const [pricePerUnit, setPricePerUnit] = useState('');
    const [message, setMessage] = useState('');
    const [submitresponse, setSubmitResponse] = useState([])
    const [product, setProduct] = useState([])
    const [status, setStatus] = useState('')
    const [subtotal, setSubtotal] = useState()
    
    useEffect(()=>{
                  setLoading(true)
                  userService.getProductById(location.state.productId)
                  .then((res)=>{
                      setProduct(res.data)
                      setLoading(false)
                    })
                    .catch((error)=>{
                        console.log(error)
                        setLoading(false)
                  })

                  userService.getRequirementEnquiry(location.state.productId)
                  .then((res)=>{
                      setRequirementEnquiry(res.data)
                      setLoading(false)
                    })
                    .catch((error)=>{
                        console.log(error)
                        setLoading(false)
                  })
            if(submitresponse === 200){
                alert(`Your Quote successfully sent.`)
            }
    },[submitresponse, location.state.productId])

    useEffect(()=>{
        setSubtotal(availablequantity * product.price)
    },[availablequantity, unit, product.price])

    useEffect(()=>{
        window.scrollTo(0,0)
      },[status])
    
    const requirementEnquiryData={
        productId: location.state.productId,
        availableQuantity: availablequantity,
        unit : unit,
        pricePerUnit: pricePerUnit,
        message:message,
    }

    const submitEnquiryForm=(event)=>{
            event.preventDefault();
            console.log('form submitted');
            alert(JSON.stringify(requirementEnquiryData))

            userService.postRequirementEnquiry(requirementEnquiryData)
            .then((response)=>{
                setSubmitResponse(response.status)
            })
            .catch((error)=>{
                console.log(error)
            })
    }
console.log(product && product)
    return (
        <ScreenLayout component={
            <>
                <ScreenHeader headerTitle={loading ? 'Loading...' :product && product.product}/>
                <div className='main-div'>
                    <div style={{width:'100%'}}>
                            <div style={{display:'flex',flexDirection:'row', height:180}}>
                                <img src={product && product.newProduct && product.newProduct.icon} alt='product-icon'
                                    style={{height:150, objectFit:'contain', borderRadius:10, alignSelf:'center'}}
                                />
                                <div style={{marginLeft:30, width:'50%'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <text style={{width:'50%', color:'#808080'}}>Requirement Quantity</text>
                                        <text style={{marginRight:15}}>:</text>
                                        <p style={{fontWeight:500}}>{product.count_in_stock}</p>
                                        <p style={{fontWeight:500}}>{product.unit}</p>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <text style={{width:'50%', color:'#808080'}}>Minimum Order Quantity</text>
                                        <text style={{marginRight:15}}>:</text>
                                        <p style={{fontWeight:500}}>--</p>
                                        <p style={{fontWeight:500}}>--</p>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <text style={{width:'50%', color:'#808080'}}>Max Price Range per {product.unit}</text>
                                        <text style={{marginRight:15}}>:</text>
                                        <p style={{fontWeight:500}}>Rs. {product.price}</p> 
                                    </div>
                                </div>
                            </div>
                        <hr style={{width:'100%',}} />
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <text style={{fontWeight:700, marginBottom:10}}>Product details</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <text style={{width:'50%', color:'#808080'}}>Product type</text>
                                <text style={{marginRight:15}}>:</text>
                                <p style={{fontWeight:500}}>{product.productType && product.productDetail.product_type ? 
                                                                                    product.productDetail.product_type : 
                                                                                    'Not-Define'
                                                            }
                                </p> 
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <text style={{width:'50%', color:'#808080'}}>Production Mode</text>
                                <text style={{marginRight:15}}>:</text>
                                <p style={{fontWeight:500}}>{product.productType && product.productDetail.production_mode ? 
                                                                                    product.productDetail.production_mode : 
                                                                                    'Not-Define'
                                                            }
                                </p> 
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <text style={{width:'50%', color:'#808080'}}>Variety</text>
                                <text style={{marginRight:15}}>:</text>
                                <p style={{fontWeight:500}}>{product.productType && product.productDetail.variety ? 
                                                                                    product.productDetail.variety : 
                                                                                    'Not-Define'
                                                            }
                                </p> 
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <text style={{width:'50%', color:'#808080'}}>Genetically Modified?</text>
                                <text style={{marginRight:15}}>:</text>
                                <p style={{fontWeight:500}}>{product.productType && product.productDetail.genetically_modyfied ? 
                                                                                    product.productDetail.genetically_modyfied : 
                                                                                    'Not-Define'
                                                            }
                                </p> 
                            </div>

                        <hr style={{width:'100%',}} />

                            <div style={{display:'flex', flexDirection:'row'}}>
                                {
                                 product && product.product_image && product.product_image.map((image,key)=>(
                                        <img key={key} src={image.media} style={{margin:5, width:200, borderRadius:10}}/>
                                    ))
                                }
                            </div>
                        <hr style={{width:'100%',}} />
                            <img src={product && product.quality && product.quality.document} style={{margin:5, width:200, borderRadius:10}}/>
                        <hr style={{width:'100%',}} />

                        </div>
                
                        <div style={{width:'100%'}}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div id='quotation-form'>
                                {
                                 product.user !== (user && user.id) &&
                                <div style={{background:'#eef6fa',padding:10}}>
                                <text style={{fontSize:24, fontWeight:500}}>Send a quote</text>

                                
                                <div style={{display:'flex',flexDirection:'row'}}>
                                    <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                                        <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Available Quantity</label>
                                        <input style={{height:'100%',borderRadius:5, border:'1px solid black'}} type='number'
                                            placeholder='Quantity'
                                            onChange={(e)=>setAvailableQuantity(e.target.value)}
                                        />
                                    </div>

                                    <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                                        <label style={{width:'50%',margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Unit</label>
                                        <select style={{height:'100%',borderRadius:5, border:'1px solid black'}} 
                                                onChange={e=>setUnit(e.target.value)}> 
                                            <option value=''>select</option>
                                            <option value={product.unit}>{product.unit}</option>
                                        </select>
                                    </div>
                                </div>
                                <div style={{display:'flex',flexDirection:'row'}}>
                                    <div style={{width:'50%',borderRadius:5,height:80,display:'flex',flexDirection:'column',margin:10}}>
                                        <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Price per Unit</label>
                                        <input style={{height:'100%',borderRadius:5, border:'1px solid black'}} type='number'
                                            placeholder='Price per Unit'
                                            onChange={(e)=>setPricePerUnit(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div style={{width:'100%',borderRadius:5,display:'flex',flexDirection:'column',margin:10}}>
                                        <label style={{height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Message</label>
                                        <textarea 
                                            rows={5}
                                            placeholder='Message for Seller (if any)' 
                                            style={{width:'100%',borderRadius:5, border:'1px solid black'}}
                                            onChange={(e)=>setMessage(e.target.value)}
                                        />
                                </div> 
                                </div> }

                                { 
                                user  ?
                                            product.user === user.id 
                                    ?
                                            <div style={{ borderRadius:5, height:65,marginTop:25, 
                                                display:'flex', justifyContent:'center',
                                                alignItems:'center',fontSize:20 ,fontWeight:500, background:'#5DA7CA', color:'white'
                                            }}>
                                                This product belongs to you.
                                            </div>
                                    :
                                            <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
                                                    <button className='sign-button'
                                                    onClick={(e)=>submitEnquiryForm(e)}
                                                    >
                                                        Send
                                                    </button> 
                                            </div>
                                    :
                                
                                            <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
                                                <button className='sign-button' onClick={()=>navigate('/login')}>
                                                    Sign in
                                                </button> 
                                            </div>
                                }
                            <hr style={{width:'100%'}}/>
                
                            </div>


                            <div style={{display:'flex',flexDirection:'column'}}>
                                <ScreenHeader headerTitle={'All Response'}/>
                                <table style={{border:'solid 1px #BDBDBD', borderCollapse:'collapse',width:'100%'}}>
                                <tr style={{backgroundColor:'rgba(93, 167, 202, 0.2)',textAlign:'center',height:55}}>
                                    <th>User</th>
                                    <th>Amount per kg</th>
                                    <th>Available Quantity</th>
                                </tr>
                                {requirementEnquiry &&
                                [...requirementEnquiry].reverse().map((data, key)=>(
                                    <tr style={{height:40}} key={key}>
                                       {
                                        product.user!==user.id ?
                                        <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>
                                            {data && data.from_user && data.from_user.username == user.username ? 'YOU' : '***10101***'}
                                        </td>
                                                              : 
                                        <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>
                                            {data && data.from_user.username}
                                        </td>
                                        }

                                        <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>
                                            {data && data.price_per_unit}
                                        </td>
                                        <td style={{borderTop:'1px solid #BDBDBD',textAlign:'center'}}>
                                            {data && data.available_quantity} {data && data.unit}
                                        </td>
                                    </tr>
                                    )
                                )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        } 
      />
    );
    }
    
    export default RequirementProductViewScreen;