import React from 'react'
import WhiteCircle from './WhiteCircle';
import phone from '../../assets/Phone_1.png';

export default function ImageArea(){
  return (
    <div className='gradient-background half-screen layout-center'>
        <WhiteCircle/>
        <img src={phone} alt='phone' style={{width:'31vw',height:'43vw',position:'absolute'}}/>
    </div>
  )
}
