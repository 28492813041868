import React from 'react';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { useNavigate } from 'react-router-dom';
function UnauthorizedScreen() {
  const navigate = useNavigate()
  return (
    <>
        <ScreenHeader headerTitle={'UnauthorizedScreen'}/>
        <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
                <button className='sign-button' onClick={()=>navigate('/login')}>
                    back
                </button> 
        </div>
    </>
  );
}

export default UnauthorizedScreen;
