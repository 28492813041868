import React from 'react'
import "../../CSS/SecondScreen.css";
import OurSolutonImg2 from '../../assets/OurSolution-img2.png'
import OurSolutonImg1 from '../../assets/OurSolution-img1.jpg'
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SecondSection = () => {
    const {internationalization} = useSelector(state=>state.language)
  return (
    <div  className="our-solution-main-div"> 
        <label className='our-solution-heading'>{internationalization.our_solution}</label>
        <div className="d-lg-flex justify-content-lg-around">
            <div className="our-solution-farm-to-fork">
                <div style={{borderRadius:10}}>
                    <div style={{backgroundColor:'#000000',height:'100%', width:'100%',position:'absolute', opacity:0.4, borderRadius:10}}/>
                    <Image src={OurSolutonImg1} alt="Card image" className="our-solution-top-image"/>
                </div>
                <div className='our-solution-fist-div-style'>
                    <div  className="farm-to-fork-heading">{internationalization.farm_to_fork}</div>
                    <button className="sotution-btn">{internationalization.register_now}</button>
                    <p className="farm-to-fork-desc">
                        {internationalization.farm_to_fork_detail}
                    </p>
                </div>
            </div>
            <div className="our-solution-bottom-div-imag">
                <img className='sright-img-background' src={OurSolutonImg2} alt="OurSolution-img2.png"
                     style={{
                        width:'100%',
                        height:'100%',
                        padding:10,
                        borderRadius:10,
                    }}
                />
            </div>
        </div>
    </div>
  )
}

export default SecondSection
