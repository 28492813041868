import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from './ScreenLayout';
import { useSelector } from 'react-redux';
import userService from '../Services/user.service';
import Info from '../Components/CommonComponents/Info';
import textData from '../assets/JSON/addProduct.json';
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import './AddProductScreen.css'
import Modal from '../Components/Modal/Modal';
import AddProductModal from '../Components/Modal/AddProductModal';

function AddProductScreen() {
  const location = useLocation()
  const navigate = useNavigate()
  const {user} = useSelector((state)=> state.auth)
  const [loading, setLoading] = useState(false);
  const editProductId = location.state ? location.state.editProductId : null ;
  const [visible, setVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [productImagePreview, setProductImagePreview] = useState([]);
  const [product, setProduct] = useState('');
  const [productList, setProductList] = useState([]);
  const [productAction, setProductAction] = useState(null);
  const [productQuantity, setProductQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [minimumQuantity, setMinimumQuantity] = useState('');
  const [productMaxPrice, setProductMaxPrice] = useState('');
  const [productType, setProductType] = useState('');
  const [variety, setVariety] = useState('');
  const [productionMode, setProductionMode] = useState('');
  const [geneticallyModified, setGeneticallyModified] = useState('');
  const [varietyPurity, setVarietyPurity] = useState('');
  const [moisture, setMoisture] = useState('');
  const [protien, setProtien] = useState('');
  const [caliber, setCaliber] = useState('');
  const [brokenGrains, setBrokenGrains] = useState('');
  const [impuritiesConsistingGrains, setImpuritiesConsistingGrains] = useState('');
  const [spoutedGrains, setSpoutedGrains] = useState('');
  const [miscellanousImpurities, setMiscellanousImpurities] = useState('');
  const [germinationCapacity, setGerminationCapacity] = useState('');
  const [minSpecificWeight, setMinSpecificWeight] = useState('');
  const [grossValue, setGrossValue] = useState('');

  const [logisticsIncoterms, setLogisticsIncoterms] = useState('');
  const [initialDeliveryDate, setInitialDeliveryDate] = useState('');
  const [finalDeliveryDate, setFinalDeliveryDate] = useState('');
  const [originAddress, setOriginAddress] = useState('');
  const [regionOfOrigin, setRegionOfOrigin] = useState('');
  const [countryOfOrigin, setCountryOfOrigin] = useState('');
  const [certificationType, setCertificationType] = useState('');
  const [qualityControlCertification, setQualityControlCertification] = useState([]); //service quatation array-quality
  const [farmerWarehouse, setFarmerWarehouse] = useState([]);
  const [logisticBuyerDelivery, setLogisticBuyerDelivery] = useState([]);
  const [qualityCheck, setQualityCheck] = useState('no');
  const [logisticsCheck, setLogisticsCheck] = useState('no');
  const [logisticsVAS, setLogisticsVAS] = useState([]);  //service quatation array-logistics
  const [offerExpiryDate, setOfferExpiryDate] = useState('');
  const [additionalMessage, setAdditionalMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');
  const [productImage, setproductImage] = useState([])

  const handleSubmit=(e)=>{
    setVisible(true)
    e.preventDefault();
  }

  useEffect(()=>{
    userService.getProductCategory()
               .then((res)=>{
                setProductList(res.data)
               })
               .catch((error)=>{
                console.log(error)
               })

    if(editMode){
      setHeaderTitle(product + "(" + productType + ")")
      setButtonTitle('Update Changes')
    }
    else{
      setButtonTitle('Add Product')
      setHeaderTitle('Add Product Screen')
    }

    async function firstRun(){if(editProductId!==undefined || null){
      userService.getProductById(editProductId)
      .then((res)=>{
         let data = res.data
         setEditMode(true)
         setProduct(data.product)
         setProductAction(data.product_action)
         setProductQuantity(data.count_in_stock)
         setUnit(data.unit)
         setProductMaxPrice(data.price)
         setProductType(data.productDetail.product_type)
         setVariety(data.productDetail.variety)
         setProductionMode(data.productDetail.production_mode)
         setGeneticallyModified(data.productDetail.genetically_modyfied)
         setVarietyPurity(data.productDetail.variety_purify)
         setMoisture(data.productDetail.moisture)
         setProtien(data.productDetail.protien)
         setCaliber(data.productDetail.caliber)
         setBrokenGrains(data.productDetail.broken_grains)
         setImpuritiesConsistingGrains(data.productDetail.impurities_consisting_grains)
         setSpoutedGrains(data.productDetail.spouted_grains)
         setMiscellanousImpurities(data.productDetail.miscellanous_impurities)
         setGerminationCapacity(data.productDetail.germination_capacity)
         setMinSpecificWeight(data.productDetail.min_specific_weight)
         setLogisticsIncoterms(data.logistics.logistics_incoterms)
        //  setLogisticsVAS(data.logistics.service_quotation)
        setInitialDeliveryDate(moment(data.logistics.initial_delivery_date).format('yyyy-MM-DD'))
        setFinalDeliveryDate(moment(data.logistics.final_delivery_date).format('yyyy-MM-DD'))
        setOfferExpiryDate(moment(data.logistics.offer_expiration_date).format('yyyy-MM-DD'))
      })
    }
    else{
      setProduct(location.state.product)
    }}
    firstRun()
  },[editProductId])
  

  const onProductImage=(e)=>{
    console.log('for image thing :: ', e.target.files)
    if(Array.from(e.target.files).length>4){
      setProductImages([])
      setProductImagePreview([])
      e.preventDefault();
      alert(`Cannot upload files more than 5`);
      return;
    }
    else if (!e.target.files || e.target.files.length === 0) {
        setProductImages([])
        setProductImagePreview([])
        return
      }
      else{
      for(let i=0; i<e.target.files.length; i++){
              let newImage = e.target.files[i]
              newImage["id"] = Math.random();
              setProductImages((prevState)=>[...prevState, newImage])
              setProductImagePreview((prevState)=>[...prevState, URL.createObjectURL(newImage)])
          }
    }

  }

  const onSelectFile = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
          setSelectedFile([])
          setPreview([])
          return
        }
        else{
        setSelectedFile(e.target.files[0])
        setPreview([URL.createObjectURL(e.target.files[0])]);
          // for(let i=0; i<e.target.files.length; i++){
          //     let newImage = e.target.files[i]
          //     newImage["id"] = Math.random();
          //     setSelectedFile((prevState)=>[...prevState, newImage])
          // }
      }
  }

    const handleQCC=(value, checked )=>{
        if(checked){
          setQualityControlCertification([...qualityControlCertification, value])
        }
        else{
          setQualityControlCertification(qualityControlCertification.filter((QCC)=> QCC !== value))
        }
      }

    const handleFW=(value, checked )=>{
        if(checked){
          setFarmerWarehouse([...farmerWarehouse, value])
        }
        else{
          setFarmerWarehouse(farmerWarehouse.filter((FW)=> FW !== value))
        }
      }

    const handleLBD=(value, checked )=>{
        if(checked){
          setLogisticBuyerDelivery([...logisticBuyerDelivery, value])
        }
        else{
          setLogisticBuyerDelivery(logisticBuyerDelivery.filter((LBD)=> LBD !== value))
        }
      }

    const handleLogisticsVAS=(value, checked )=>{
        if(checked){
          setLogisticsVAS([...logisticsVAS, value])
        }
        else{
          setLogisticsVAS(logisticsVAS.filter((LBD)=> LBD !== value))
        }
      }

    useEffect(()=>{
        setGrossValue(productMaxPrice * productQuantity)
    },[productMaxPrice, productQuantity, unit])

    let handleUpload = (event) => {
        setLoading(true)
        setVisible(false)
        event.preventDefault();
        //   // JSON.stringify(formValues), 
        //       JSON.stringify({
        //                       user:user && user.id,
        //                       product:product,
        //                       productAction:productAction,
        //                       productQuantity:productQuantity,
        //                       unit:unit,
        //                       productMaxPrice:productMaxPrice,
        //                       productType:productType,
        //                       variety:variety,
        //                       productionMode:productionMode,
        //                       geneticallyModified:geneticallyModified,
        //                       varityPurity:varietyPurity,
        //                       moisture:moisture,
        //                       protien:protien,
        //                       caliber:caliber,
        //                       brokenGrains:brokenGrains,
        //                       impuritiesConsistingGrains:impuritiesConsistingGrains,
        //                       spoutedGrains:spoutedGrains,
        //                       miscellanousImpurities:miscellanousImpurities,
        //                       germinationCapacity:germinationCapacity,
        //                       minSpecificWeight:minSpecificWeight,
        //                       logisticsIncoterms:logisticsIncoterms,
        //                       initialDeliveryDate:initialDeliveryDate,
        //                       finalDeliveryDate:finalDeliveryDate,
        //                       originAddress:originAddress,
        //                       regionOfOrigin:regionOfOrigin,
        //                       countryOfOrigin:countryOfOrigin,
        //                       certificationType:certificationType,
        //                       qualityControlCertification:qualityControlCertification,
        //                       farmerWarehouse:farmerWarehouse,
        //                       logisticBuyerDelivery:logisticBuyerDelivery,
        //                       logisticsVAS:logisticsVAS,
        //                       offerExpiryDate:offerExpiryDate,
        //                       additionalMessage:additionalMessage
        //                       })
        //       ]);
        let data= new FormData()
         data.append("user",user.id)
         data.append("product",product)
         data.append("productAction",productAction)
         data.append("productQuantity",productQuantity)
         data.append("minimumQuantity",minimumQuantity)
         data.append("unit",unit)
         data.append("productMaxPrice",productMaxPrice)
         data.append("productType",productType)
         data.append("variety",variety)
         data.append("productionMode",productionMode)
         data.append("geneticallyModified",geneticallyModified)
         data.append("varietyPurity",varietyPurity)
         data.append("moisture",moisture)
         data.append("protien",protien)
         data.append("caliber",caliber)
         data.append("brokenGrains",brokenGrains)
         data.append("impuritiesConsistingGrains",impuritiesConsistingGrains)
         data.append("spoutedGrains",spoutedGrains)
         data.append("miscellanousImpurities",miscellanousImpurities)
         data.append("germinationCapacity",germinationCapacity)
         data.append("minSpecificWeight",minSpecificWeight)
         for(let i=0; i<productImages.length; i++){
          Boolean(productImages.length) && data.append("productImage",productImages[i], productImages[i].name)
         }
         data.append("logisticsIncoterms",logisticsIncoterms)
         data.append("initialDeliveryDate",initialDeliveryDate)
         data.append("finalDeliveryDate",finalDeliveryDate)
         data.append("originAddress",originAddress)
         data.append("regionOfOrigin",regionOfOrigin)
         data.append("countryOfOrigin",countryOfOrigin)
         data.append("certificationType",certificationType)
         data.append("qualityControlCertification",JSON.stringify(qualityControlCertification))
         selectedFile && data.append("qualityDocument", selectedFile, selectedFile.name)
         data.append("farmerWarehouse",JSON.stringify(farmerWarehouse))
         data.append("logisticBuyerDelivery",JSON.stringify(logisticBuyerDelivery))
         data.append("logisticsVAS",JSON.stringify(logisticsVAS))
         data.append("offerExpiryDate",offerExpiryDate)
         data.append("additionalMessage",additionalMessage)
         data.append("productImage",productImage)
            console.log(data)
            if(editMode){
              console.log("update data post")
            }
            else{
              userService.postProductData(data)
                   .then((response)=>{
                     console.log(response.data)
                     alert('Successfully Product uploaded. Check Product List.')
                     navigate('/dashboard/productlist')
                   })
                   .catch((error)=>{
                     console.log(error)
                   })
            }
    }


  const qualityFunc = [handleQCC, handleFW, handleLBD]
  return (
    <ScreenLayout 
      component={
      <>
      <Modal
        visible={visible}
        children={
        <AddProductModal
          setVisible={setVisible} 
          modalText={'Confirm ?'}
          handleUpload={handleUpload}
          />
        }
        />
        {editMode?
           <button
              className='btn'
              style={{position:'absolute', right:40, height:40, width:100}}
              onClick={()=>setUpdateMode(!updateMode)}
           >
              {updateMode? 'Cancle' : 'Edit'}
           </button> 
        : null}
       <div>
       <ScreenHeader headerTitle={editMode? product + "(" + productType + ")" :'Add Product Screen'}/>
       <hr style={{width:'100%'}}/>
        <div>
        <form onSubmit={handleSubmit}>
        <fieldset disabled={editMode ? !updateMode : false}>
        <div>
          <div className='main-screen'>
            <div className='main-screen-blue'>
              <div className='main-flex-row'>
                  <div className='flex-div-half'>
                      <label className='drop-down-label'>
                        Product
                      </label>
                      <select
                        className='drop-down-select'
                        defaultValue={product}
                        value={product}
                        onChange={(e)=>setProduct(e.target.value)}
                    > 
                        <option value={undefined}>select</option>
                        {
                          productList.map((category)=>(
                            <option value={category.name} >{category.name}</option>
                            ))
                        }
                      </select>
                  </div>
                  <div className='flex-div-half'>
                      <label className='drop-down-label'>
                        Buy Or Sell
                      </label>
                      <select
                        className='drop-down-select'
                        onChange={(e)=>setProductAction(e.target.value)}
                        defaultValue={productAction}
                        value={productAction}
                    > 
                        <option value={null}>select</option>
                        <option value='BUY'>BUY</option>
                        <option value='SELL'>SELL</option>
                      </select>
                  </div>
                </div>

              {
                productAction &&
                <>
                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                  <label className='drop-down-label'>
                    {productAction=='BUY' ? 'Require Quantity': productAction=='SELL' ? 'Available Quantity' : 'First select BUY or SELL'}
                  </label>
                  <input className='input-third' type='number' placeholder='Quantity' 
                         onChange={e=>setProductQuantity(e.target.value)}
                         defaultValue={productQuantity}
                         />
                  </div>

                  <div className='flex-div-half'>
                  <label className='unit-label'>Unit</label>
                  <select
                        className='unit-select'
                        onChange={e=>setUnit(e.target.value)}
                        value={unit}
                  > 
                      <option value={undefined}>select</option>
                      <option value='kg'>kg</option>
                      <option value='quintal'>quintal</option>
                      <option value='mton'>metric ton</option>
                  </select>
                  </div>
              </div>
              
              <div className='main-flex-row'>
                  <div className='div-label'>
                    <label className='drop-down-label'>
                      Price Per Weight Unit(₹)
                    </label>
                    <input className='input-two' type='number' placeholder='Price'
                           defaultValue={productMaxPrice}
                           onChange={e=>setProductMaxPrice(e.target.value)}/>
                  </div>
              </div>
              {productAction=='SELL' &&
              <div className='main-flex-row'>
                  <div className='flex-div-half'>
                  <label className='drop-down-label'>
                     Minimum Order Quantity
                  </label>
                  <input className='input-third' type='number' placeholder='Quantity' 
                         onChange={e=>setMinimumQuantity(e.target.value)}
                         defaultValue={minimumQuantity}
                         />
                  </div> 

                  <div className='flex-div-half'>
                  <label className='unit-label'>Unit</label>
                  <select
                        className='unit-select'
                        onChange={e=>setUnit(e.target.value)}
                        value={unit}
                  > 
                      <option value={undefined}>select</option>
                      <option value='kg'>kg</option>
                      <option value='quintal'>quintal</option>
                      <option value='mton'>metric ton</option>
                  </select>
                  </div>
              </div>
                }

              <div className='main-flex-row'>
                  <div className='gross-div' style={{
                               opacity:grossValue && grossValue ? 1 : 0.5}}>
                    <label className='drop-down-label'>
                     Gross value(₹)
                    </label>
                    <input
                        className='input-two'
                        type='number'
                        value={grossValue}
                        readOnly
                        />
                  </div>
              </div>
              </>
              }
            </div>
            <hr style={{width:'100%'}}/>

           {productAction &&
            <>
              <label className='main-tag'>PRODUCT DETAILS</label>
              <div className='main-screen' >
                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Product Type</label>
                    <input className='input-third'
                        type='text'
                        onChange={(e)=>setProductType(e.target.value)}
                        defaultValue={productType}
                    />
                    {/* <select
                        className='drop-down-select'
                        onChange={(e)=>setProductType(e.target.value)}
                        value={productType}
                    > 
                        <option value={undefined}>select</option>
                        {product==="Tomato" && ["Fresh", "Frozen", "Dehydrated", "Canned"].map((types, key)=>(
                          <option key={key} value={types}>{types}</option>
                        ))
                        }
                        // {product && textData.productTypeData[product].map((types, key)=>(
                        //   <option key={key} value={types}>{types}</option>
                        // ))
                        }
                    </select> */}
                  </div>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Variety <Info infoText={textData.fieldsDetails.variety}/> </label>
                    <input className='input-third'
                        type='text'
                        onChange={(e)=>setVariety(e.target.value)}
                        defaultValue={variety}
                    />
                    {/* <select
                        className='drop-down-select'
                        onChange={(e)=>setVariety(e.target.value)}
                        value={variety}
                    > 
                        <option value={undefined}>select</option>
                        {product==="Tomato" && ["Saaho(TO-3251)", "TO-1458", "Abhilash", "Ganesh", "Heem Sohna"].map((types, key)=>(
                          <option key={key} value={types}>{types}</option>
                        ))
                        }
                    </select> */}
                  </div>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Production Mode</label>
                    <select
                        className='drop-down-select'
                        onChange={(e)=>setProductionMode(e.target.value)}
                        value={productionMode}
                    > 
                        <option value={undefined}>select</option>
                        <option value={'Organic'}>Organic</option>
                        <option value={'Non-organic'}>Non-organic</option>
                    </select>
                  </div>
                </div>

                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Genetically Modified? <Info infoText={textData.fieldsDetails.GM}/> </label>
                    <select
                        className='drop-down-select'
                        onChange={(e)=>setGeneticallyModified(e.target.value)}
                        value={geneticallyModified}
                    > 
                        <option value={undefined}>select</option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </select>
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Variety Purity(min.%) <Info infoText={textData.fieldsDetails.GM}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setVarietyPurity(e.target.value)}
                          defaultValue={varietyPurity}
                          />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Moisture (max.%) <Info infoText={textData.fieldsDetails.moisture}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setMoisture(e.target.value)}
                          defaultValue={moisture}
                    />
                  </div>
                </div>

                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Protein (min.%) <Info infoText={textData.fieldsDetails.minProtein}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setProtien(e.target.value)}
                          defaultValue={protien}
                          />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Protein (max.%) <Info infoText={textData.fieldsDetails.maxProtein}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setProtien(e.target.value)}
                          defaultValue={protien}
                    />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>{'Caliber > 2,2mm (min.%)'} <Info infoText={textData.fieldsDetails.minCaliber}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setCaliber(e.target.value)}
                          defaultValue={caliber}
                          />
                  </div>
                </div>

                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>{'Caliber > 2,2mm (max.%)'} <Info infoText={textData.fieldsDetails.maxCaliber}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setCaliber(e.target.value)}
                          defaultValue={caliber}
                    />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Broken grains (max.%) <Info infoText={textData.fieldsDetails.brokenGrains}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setBrokenGrains(e.target.value)}
                          defaultValue={brokenGrains}
                          />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Impurities consisting of grains (max.%) <Info infoText={textData.fieldsDetails.impurities}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setImpuritiesConsistingGrains(e.target.value)}
                          defaultValue={impuritiesConsistingGrains}
                          />
                  </div>
                </div>

                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Spouted grains(max.%) <Info infoText={textData.fieldsDetails.spoutedGrains}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setSpoutedGrains(e.target.value)}
                          defaultValue={spoutedGrains}
                          />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Miscellanous impurities (max.%) <Info infoText={textData.fieldsDetails.miscellanousImpurities}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setMiscellanousImpurities(e.target.value)}
                          defaultValue={miscellanousImpurities}
                    />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Germination capacity(min.%) <Info infoText={textData.fieldsDetails.germinationCapacity}/></label>
                    <input className='input-third'
                          type='number'
                          onChange={(e)=>setGerminationCapacity(e.target.value)}
                          defaultValue={germinationCapacity}
                          />
                  </div>
                </div>

                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Minimum specific weight(kg/hl) <Info infoText={textData.fieldsDetails.minSpecificWeight}/></label>
                    <input className='min-specific-input'
                          type='number'
                          onChange={(e)=>setMinSpecificWeight(e.target.value)}
                          defaultValue={minSpecificWeight}
                    />
                  </div>
                </div>
                <hr style={{width:'100%'}} />

                <div className='div-label'>
                      <label className='input-third' htmlFor="contained-button-file" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                       click here to upload Product Image (press ctrl to select multiple images)
                      <input className='product-img-input'
                              id="contained-button-file"
                              type='file' multiple placeholder='file-input' onClick={()=>{setProductImages([]); setProductImagePreview([])}} onChange={(e)=>{onProductImage(e)}} style={{display:'none'}}
                        />
                        </label>
                    </div>
                  <div className='div-label' style={{display:'flex', flexDirection:'row'}}>
                      {
                      productImages &&  
                      productImagePreview.map((image, key)=>(
                              <img key={key} src={image} alt='selected-product' className='proof-doc-input' style={{margin:5}}/> 
                          ))
                      }
                  </div>

                <hr style={{width:'100%'}} />
                
                <hr style={{width:'100%'}} />
                <label className='main-tag'>LOGISTICS</label>
                <div className='logistics-main-div'>
                  <div className='logistics-div-two'>
                      <label className='drop-down-label'>Logistics Incoterms</label>
                      <select
                          className='drop-down-select'
                          onChange={(e)=>setLogisticsIncoterms(e.target.value)}
                          value={logisticsIncoterms}
                      > 
                          <option value={undefined}>select</option>
                          { 
                            textData.logisticsIncotermsData && 
                            textData.logisticsIncotermsData.map((incoterms,key)=>(
                              <option key={key} value={incoterms}>{incoterms}</option>
                            ))
                          }
                      </select>
                  </div>

                  <div className='logistics-child-div'>
                    <label className='logistics-label'>Would you like to have service quotation?(Optional)</label>
                    <div  className='main-flex-row'>
                        <label
                       className='yes-no-label'
                        >Yes
                            <input
                                type='radio'
                                style={{margin:20}}
                                name='service-logistics'
                                value='yes'
                                onClick={(e)=>setLogisticsCheck(e.target.value)}
                            />
                        </label>
                        <label
                       className='yes-no-label'
                        >No
                            <input
                                type='radio'
                                style={{margin:20}}
                                name='service-logistics'
                                value='no'
                                onClick={(e)=>{setLogisticsCheck(e.target.value); setLogisticsVAS([])}}
                                defaultChecked
                            />
                        </label>
                    </div>
                  </div>

                  <div style={{pointerEvents: logisticsCheck==='no' ? 'none' : 'auto', opacity: logisticsCheck==='no' ? 0.6 : 1}}>
                    <label className='logistics-check-label'>Optional - request values for :</label>
                    <div className='logistics-check-label-child'>
                        <div className='logistics-lower-child-div'>
                        {textData.vasData['logistics'].map((value, key)=>(
                            <label
                                  key={key}
                                  htmlFor={"logistics_vas_"+key}
                                  className='quality-wrap-label'>
                              <input 
                                id={"logistics_vas_"+key}
                                type='checkbox'
                                value={value}
                                style={{margin:20}}
                                onClick={(e)=>handleLogisticsVAS(e.target.value, e.target.checked)}
                                checked={logisticsCheck==='no' ? false : null}
                              />
                              {value}
                            </label>
                          ))
                          }
                        </div>
                    </div>
                  </div>

                  <div className='main-flex-row'>
                    <div className='div-label'>
                    <label className='drop-down-label'>Initial Delivery Date :</label>
                    <input
                        className='date-input'
                        type='date'
                        onChange={(e)=>setInitialDeliveryDate(e.target.value)}
                        defaultValue={initialDeliveryDate}
                        />
                    </div>

                    <div className='div-label'>
                    <label className='drop-down-label'>Final Delivery Date :</label>
                    <input
                        className='date-input'
                        type='date' 
                        onChange={(e)=>setFinalDeliveryDate(e.target.value)}
                        defaultValue={finalDeliveryDate}
                        />
                    </div>

                    <div className='div-label'>
                    <label className='drop-down-label'>Offer Expiration Date :</label>
                    <input
                        className='date-input'
                        type='date'
                        onChange={e=>setOfferExpiryDate(e.target.value)}
                        defaultValue={offerExpiryDate}
                        />
                    </div>
                  </div>
                </div>

                <hr style={{width:'100%'}} />
                <label className='main-tag'>ADDRESS</label>
                <div className='main-flex-row'>
                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Origin Address</label>
                    <input className='input-third'
                          type='text'
                          onChange={(e)=>setOriginAddress(e.target.value)}
                          defaultValue={originAddress}
                          />
                  </div>

                  <div className='flex-div-half'>
                    <label className='drop-down-label'>Region of Origin</label>
                    <input className='input-third'
                          type='text'
                          onChange={(e)=>setRegionOfOrigin(e.target.value)}
                          />
                  </div>

                  <div className='logistics-div-two'>
                      <label className='drop-down-label'>Country of Origin</label>
                      <select
                          className='drop-down-select'
                          onChange={(e)=>setCountryOfOrigin(e.target.value)}
                      > 
                          <option value={undefined}>select</option>
                          <option value='India'>India</option>
                      </select>
                  </div>
                </div>
                <hr style={{width:'100%'}} />
                
                <label className='main-tag'>PROOF OF QUALITY CERTIFICATION</label>
                <div className='proof-div'>
                  <div className='logistics-div-two'>
                      <label className='drop-down-label'>Certification Type</label>
                      <select
                          className='proof-select'
                          onChange={(e)=>setCertificationType(e.target.value)}
                          value={certificationType}
                      > 
                          <option value={undefined}>select</option>
                          {
                          textData.certificateTypeData && 
                          textData.certificateTypeData.map((certificate, key)=>(
                            <option key={key} value={certificate}>{certificate}</option>
                          )) 
                          }
                      </select>
                    </div>

                    <div className='div-label'>
                      <label className='drop-down-label'>Certification Document</label>
                      <input className='proof-doc-input'
                              type='file' placeholder='file-input' onChange={(e)=>onSelectFile(e)}
                        />
                    </div>
                    <div className='div-label'>
                        {
                        selectedFile &&  
                        preview.map((image, key)=>(
                                <img src={image} alt='selected-product' className='proof-doc-input' /> 
                            ))
                        }
                    </div>
                </div>
                <hr style={{width:'100%'}} />
                
                <label className='main-tag'>QUALITY</label>
                <div className='logistics-child-div'>
                    <label className='logistics-label'>Would you like to have service quotation?(Optional)</label>
                    <div  className='main-flex-row'>
                        <label
                       className='yes-no-label'
                        >Yes
                            <input
                                id='radio-A'
                                type='radio'
                                style={{margin:20}}
                                name='service-qutation'
                                value='yes'
                                onClick={(e)=>setQualityCheck(e.target.value)}
                            />
                        </label>
                        <label
                       className='yes-no-label'
                        >No
                            <input
                                id='radio-B'
                                type='radio'
                                style={{margin:20}}
                                name='service-qutation'
                                value='no'
                                onClick={(e)=>{setQualityCheck(e.target.value);
                                              setQualityControlCertification([]);
                                              setFarmerWarehouse([]);
                                              setLogisticBuyerDelivery([])
                                              }}
                                defaultChecked
                            />
                        </label>
                    </div>
                </div>
                <div style={{pointerEvents: qualityCheck==='no' ? 'none' : 'auto', opacity: qualityCheck==='no' ? 0.6 : 1}}>
                  
                  <div className='quality-main-div'>
                      <div className='quality-main-div-child'>
                        {
                          textData.vasData.quality.map((value,key1)=>{
                            return (
                            <div key={key1} className='quality-tag' >
                              <label className='quality-tag-label'>{Object.keys(value)} :</label>
                              <div className='quality-wrap'>
                                {
                                  Object.keys(value).map((res)=>(
                                  value[res].map((qualityValue,key2)=>(
                                        <label
                                                key={key2}
                                                htmlFor={"quality_vas_"+key1+key2}
                                                className='quality-wrap-label'>
                                            <input 
                                              id={"quality_vas_"+key1+key2}
                                              type='checkbox'
                                              value={qualityValue}
                                              style={{margin:20}}
                                              onClick={(e)=>qualityFunc[key1](e.target.value, e.target.checked)}
                                              checked={qualityCheck==='no' ? false : null}
                                            />
                                              {qualityValue}
                                        </label> 
                                        )
                                      )
                                    ))
                                  }
                              </div>
                            </div>
                            )
                          })
                        }
                      </div>
                  </div>
                </div>
                <hr style={{width:'100%'}} />
                
                <div className='additional-comments'>
                <label className='main-tag'>Additional Comments</label>
                <textarea 
                rows={5}
                onChange={e=>setAdditionalMessage(e.target.value)}/>
                </div>
              </div>
            </>
            }

          </div>
        </div>
        { productAction &&
          <div className="last-btn-div" >
                <button className='sign-button' type='submit'>
                 {editMode?'update changes':'Add Product'} 
                </button> 
        </div>
        }
        </fieldset>
        </form>
        </div>
       </div>
      </>
    }
  />);
  }
        
  export default AddProductScreen;


          {/* <form onSubmit={handleSubmit}>
          
          <div style={{width:'100%',borderRadius:5,height:40,display:'flex',alignItems:'center',margin:10}}>
          <label style={{margin:5,borderRadius:5,display:'flex',alignItems:'center'}}>image</label>
                  <input style={{margin:5,borderRadius:5}}
                        type='file' placeholder='file-input' onChange={(e)=>onSelectFile(e)}
                  />
                      {
                      selectedFile &&  
                      preview.map((image, key)=>(
                              <img key={key} src={image} alt='selected-product' style={{width:100,height:100}} /> 
                          ))
                      }
          </div> */}
          {/* <div style={{width:'100%',borderRadius:5,height:'fit-content',display:'flex',alignItems:'center',margin:10}}>
          <label style={{width:'20%',margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Product video :</label>
          <input type='file' accept="video/mp4,video/x-m4v,video/*" style={{width:'40%',margin:5,height:'100%',borderRadius:5}} placeholder='Product Video' />
        </div> */}


        {/* dynamic adding input fields */}
          {/* <div style={{height:'fit-content',width:'fit-content', display:'flex', flexDirection:'row', border:'1px solid white', borderRadius:5}}>
            {formValues.map((element, index) => (
              <div key={index} style={{width:'95%',borderRadius:5,display:'flex',flexDirection:'column',margin:10,border:'1px solid white'}}>
                <label>variation Name :</label>
                <input style={{margin:5,height:40,borderRadius:5}} type="text" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} />
                <label>variation value :</label>
                <input style={{margin:5,height:40,borderRadius:5}} type="text" name="variation_value" value={element.variation_value || ""} onChange={e => handleChange(index, e)} />
                <label>count in stock :</label>
                <input style={{margin:5,height:40,borderRadius:5}} type="text" name="variation_value" value={element.variation_value || ""} onChange={e => handleChange(index, e)} />
                <label>MRP :</label>
                <input style={{margin:5,height:40,borderRadius:5}} type="text" name="MRP" value={element.MRP || ""} onChange={e => handleChange(index, e)} />
                <label>selling price :</label>
                <input style={{margin:5,height:40,borderRadius:5}} type="text" name="selling_price" value={element.selling_price || ""} onChange={e => handleChange(index, e)} />
                <label>weight</label>
                <input style={{margin:5,height:40,borderRadius:5}} type="number" name="weight" value={element.weight || ""} onChange={e => handleChange(index, e)} />
                <label>unit</label>
                <select>
                  <option>kg</option>
                  <option>L</option>
                </select>
                  <label style={{margin:5,borderRadius:5,display:'flex',alignItems:'center'}}>image-input</label>
                  <input style={{margin:5,borderRadius:5}}
                        type='file' multiple placeholder='file-input' onChange={(e)=>onSelectFile(e)}
                  />
                      {
                      selectedFile &&  
                      preview.map((image, key)=>(
                              <img key={key} src={image} alt='selected-product' style={{width:100,height:100}} /> 
                          ))
                      }
                {
                  index ? 
                    <button style={{width:80,height:30,borderRadius:5,margin:10}} type="button"  onClick={() => removeFormFields(index)}>Remove</button> 
                  : null
                }  
              </div>
            ))}
            </div>
            <div>
                <button style={{width:100,height:30,borderRadius:5,margin:10}}  type="button" onClick={() => addFormFields()}>Add</button>
            </div> */}

