import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import "../../CSS/MarketPrice.css";
import '../../CSS/pagination.css';
import '../../CSS/MarketPriceDashboard.css';
import ReactPaginate from 'react-paginate';
import userService from '../../Services/user.service';
import { StatusButton } from './HSTableComponents/StatusButton';
import { useSelector } from 'react-redux';
import MarketPriceLineChart from '../Charts/MarketPriceLineChart';

function MarketPriceDashboard() {
   const [marketPrice, setMarketPrice] = useState([])
   const [districtList, setDistrictList] = useState([])
   const [marketList, setMarketList] = useState([])
   const [commodityList, setCommodityList] = useState([])
   const [selectedDistrict, setSelectedDistrict] = useState()
   const [selectedMarket, setSelectedMarket] = useState()
   const [selectedCommodity, setSelectedCommodity] = useState()
   const [loading, setLoading] = useState(false)
   const [page, setPage] = useState(1)
   const [show, setShow] = useState(false)
   const {internationalization} = useSelector(state=>state.language)
  
   const getMarketPriceUpdate=(lang, limit, page, state, district, market, commodity)=>{
      userService.getMarketPriceUpdate(lang, limit, page, state, district, market, commodity)
      .then((res)=>{
         setMarketPrice(res.data)
         console.log(res.data)
         districtList.length<=0 && setDistrictList(res.data.district_list) 
         res.data.market_list.length>1 && setMarketList(res.data.market_list)
         commodityList!==res.data.all_commodity_list[0] && setCommodityList(res.data.all_commodity_list)
         setLoading(false)
      })
      .catch((error)=>{
         console.log('error :: ', error)
         setLoading(false)
      })
   }
   
   useEffect(()=>{
      setLoading(true)
      !loading && getMarketPriceUpdate('en', 10, page, 'Chhattisgarh', selectedDistrict, selectedMarket, selectedCommodity)
   },[])

   const TableContent=({num, commodity, priceDate, maxPrice, modalPrice, minPrice})=>{
      return(
         <tr>
             <td className='table-data-cell'>{num}</td>
             <td className='table-data-cell'>{commodity}</td>
             <td className='table-data-cell'>{priceDate}</td>
             <td className='table-data-cell'>Rs. {minPrice}</td>
             <td className='table-data-cell'>Rs. {modalPrice}</td>
             <td className='table-data-cell'>Rs. {maxPrice}</td>
         </tr>
      )
   }

   const handlerDistrict=(value)=>{
      setLoading(true)
      setMarketList()
      setCommodityList()
      setSelectedMarket()
      setSelectedCommodity()
      setPage(1)
      setSelectedDistrict(value)
      getMarketPriceUpdate('en', 10, 1, 'Chhattisgarh', value, undefined, undefined)
   }
   
   
   const handlerMarket=(value)=>{
      setLoading(true)
      console.log(value)
      setSelectedMarket(value)
      getMarketPriceUpdate('en', 10, 1, 'Chhattisgarh', selectedDistrict, value, selectedCommodity)
   }
   
   const handleCommodity=(value)=>{
        setLoading(true)
        setSelectedCommodity(value)
        getMarketPriceUpdate('en', 10, 1, 'Chhattisgarh', selectedDistrict, selectedMarket, value)
   }
   const previousPage=()=>{
      page>=1 && setPage(page-1)
      setLoading(true)
      getMarketPriceUpdate('en', 10, page-1, 'Chhattisgarh', selectedDistrict, selectedMarket, selectedCommodity)
   }
   
   const nextPage=()=>{
      page>=1 && setPage(page+1)
      setLoading(true)
      getMarketPriceUpdate('en', 10, page+1, 'Chhattisgarh', selectedDistrict, selectedMarket, selectedCommodity)
   }

   return (
      <>
         <div className= "tile">
            <div className="tile-section1">
               {!loading && marketPrice?.data?.length && <MarketPriceLineChart marketData={marketPrice}/>}
            </div>
            <div className="tile-section2"/>
         </div>
      <div className='live-market-price' >
           <div className='live-market-upper-section'>
               <div className='live-market-text' >
                  <text className='live-market-text-header' >
                     {internationalization.market_price}
                     <text style={{fontSize:"11px"}}>
                        Chhattisgarh / {selectedDistrict}
                     </text>
                  </text>
               </div>
               <div className='live-market-dropdown-section'>
                  <div className='live-market-dropdown'>
                      <label className='dropdown-label'>
                        {internationalization.state}
                      </label>
                      <select
                        className='dropdown-select'
                      > 
                        <option value='Chhattisgarh'>Chhattisgarh</option>
                      </select>
                  </div>
                 
                  <div className='live-market-dropdown'>
                      <label className='dropdown-label'>
                        {internationalization.district}
                      </label>
                      <select
                        className='dropdown-select'
                        defaultValue={selectedDistrict}
                        value={selectedDistrict}
                        onChange={(e)=>handlerDistrict(e.target.value)}
                       > 
                        <option value='undefined'>select</option>
                        {
                           districtList?.map((district, key)=>(
                              <option key={key} value={district}>{district}</option>
                           ))
                        }
                      </select>
                  </div>

                  <div className='live-market-dropdown'>
                      <label className='dropdown-label'>
                      {internationalization.market}
                      </label>
                      <select
                        className='dropdown-select'
                        value={selectedMarket}
                        onChange={(e)=>handlerMarket(e.target.value)}
                       > 
                        <option value='undefined'>All</option>
                        {
                           selectedDistrict&&marketList?.map((res, key)=>(
                              <option key={key} value={res}>{res}</option>
                              ))
                        }
                      </select>
                  </div>
               </div>
           </div>

         <table className='live-market-price-table' >
           <tr className='table-header'>
             <th className='market-price-table-heading'>#</th>
             <th className='market-price-table-heading'>
               {internationalization.commodity}
               <select
                 disabled={loading}
                 defaultValue={selectedCommodity}
                 className='dropdown-select'
                 onChange={(e)=>{handleCommodity(e.target.value)}}
               >
                  <option value='undefined'>All</option>
                  {
                     selectedDistrict&&selectedMarket&&commodityList?.map((res, key)=>(
                        <option key={key} value={res}>{res}</option>
                     ))
                  }
               </select>
             </th>
             <th className='market-price-table-heading'>{internationalization.price_date}</th>
             <th className='market-price-table-heading'>{internationalization.min_price}</th>
             <th className='market-price-table-heading'>{internationalization.modal_price}</th>
             <th className='market-price-table-heading'>{internationalization.max_price}</th>
          </tr>
          {
            loading &&
          <tr>
             <td colSpan='6' className='table-loading' >loading...</td>
         </tr>
          }
         {marketPrice?.data?.map((item, key)=>(
            <TableContent 
                  key={key}
                  num={!loading && key+1+(5*(page-1))}
                  commodity= {`${item.commodity} ${item.variety!=null ? item.variety : ''}`}
                  priceDate={item.price_date}
                  maxPrice={item.max_price}
                  modalPrice={item.modal_price}
                  minPrice={item.min_price}
            />
          ))}
         </table>

         <div
          style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-around',
            alignItems:'center',
            width:'100%',
            // border:'1px solid black',
            height:'10%',
            margin:10
          }}
         >
            <button
               className="sign-button"
               onClick={()=>previousPage()}
               style={{width:100, fontSize:'1.5vmin', margin:'1vw' }}
               >
                  {internationalization.previous}
            </button>
            <button
               className="sign-button"
               onClick={()=>nextPage()}
               style={{width:100, fontSize:'1.5vmin', margin:'1vw' }}
               >
                  {internationalization.next}
            </button>
         </div>
      </div>
      </>
  );
}

export default MarketPriceDashboard;
