import React, { useEffect } from "react";
import { useLocation } from "react-router";
import FirstViewScreen from "./FirstViewScreen";
import vas from '../../../assets/VAS_BG.webp';
import packaging from '../../../assets/packaging.png';
import grading from '../../../assets/grading.png';
import container from '../../../assets/container.png';
import Cropway from '../../../assets/Cropway.png';
import "../../../CSS/mainFrame.css";
import ValueAddedDetailComponents from "../ValueAddedDetailComponents/ValueAddedDetailComponents";
import valueImg1 from "../../../assets/value-img1.jpg";
import valueImg2 from "../../../assets/value-img2.jpg";
import valueImg3 from "../../../assets/value-img3.jpg";
import LastValueAddedDetailComponents from "../ValueAddedDetailComponents/LastValueAddedDetailComponents";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

export default function ValueAddedDetailScreen(){
    const location = useLocation()
    const navigate = useNavigate()
    const {internationalization} = useSelector(state=>state.language)
    
    useEffect(()=>{
        window.scrollTo(0,0)
    })
    
    const cardData = [
        {
            image: container,
            title: 'Logistics & storage facilities',
            content: `With Cropway's extensive range of services, all value chain participants can effectively 
                      store and transport agricultural goods, resulting in a seamless experience thanks to the digital platform.
                      These offerings include expert consultation, procurement assistance, audit, and monitoring, as well
                      as storage and delivery in India and abroad.`
        },
        {
            image: grading,
            title: 'Packaging, grading & sorting',
            content: `Cropway connects you to a variety of potential packaging, 
                        grading, and sorting opportunities in the agri ecosystem enabled by technology possibilities.
                        We help our clients' value-add processes by acting as gatekeepers for the quality and quantity of the purchased produce. `
        },
        {
            image: packaging,
            title: 'Processing',
            content:` Agriprocessing businesses make significant contributions to the value chain 
                        of several commodities. These businesses must purchase, store, and process large
                        quantities of commodities. Cropway and other Processors collaborate to make procurement 
                        and storage easier for any prospective client through the seller studio.`
        },
]

    return(
    <>   
             <FirstViewScreen
                    header={internationalization.value_added_service}
                    image={vas}
                    textContent={internationalization.vas_haeder} 
            />
                <div className="value-added-service-page-sub-div">
                <ValueAddedDetailComponents
                image={valueImg1}
                para1={internationalization.vas_text_header_1}
                para2={internationalization.vas_text_1}
                />
                <ValueAddedDetailComponents
                image={valueImg2}
                para1={internationalization.vas_text_header_2}
                para2={internationalization.vas_text_2}
                />
                </div>
                <div className="value-added-service-page-sub-div">
                <ValueAddedDetailComponents
                image={valueImg3}
                para1={internationalization.vas_text_header_3}
                para2={internationalization.vas_text_3}
                />
                <LastValueAddedDetailComponents internationalization={internationalization}/>
                </div>
                <div  style={{
                margin:"50px 50px",
                display:'flex',
                justifyContent:"center"
            }}>
                <button
                onClick={()=>navigate('/login')}
                className="register-as-seller"
               >
                    {internationalization.reg_buyer_seller_button}
                </button>
                <button
                onClick={()=>navigate('/')}
                className="get-in-touchh"
                >
                    {internationalization.get_in_touch}
                </button>
            </div>  



    </>
    )
}

