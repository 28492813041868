import React from 'react'
import '../../../CSS/FifthSection.css'
import  WhyUsImg1 from '../../../assets/WhyCUs-img1.jpg'
import  WhyUsImg2 from '../../../assets/WhyCUs-img2.jpg'
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';


function ImageAndTextComponent() {
    const {internationalization} = useSelector(state=>state.language)
  return (
    <div style={{}}>
        <div
            className="d-lg-flex why-choose-us-first-img-sub-div"
        >
            <Image src={WhyUsImg1}  className="why-choose-us-first-img"/>
            <div 
                className="why-choose-us-first-desc"
            >
         {internationalization.why_choose_us_1}
            </div>     
        </div>
        <div
            style={{flexDirection:'row-reverse'}}
            className="d-lg-flex  why-choose-us-first-img-sub-div"
        >
            <Image src={WhyUsImg2}  className="why-choose-us-first-img2"/>
            <div 
               className="why-choose-us-first-desc2 mx-4"
            >
         
         {internationalization.why_choose_us_2}
            </div>     
        </div>
    </div>
  )
}

export default ImageAndTextComponent
