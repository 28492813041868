import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { loginCheck, runLogoutTimer } from "./actions/auth";
import { checkDefaultLanguage } from "./actions/language";

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

(async()=>{
  store.dispatch(runLogoutTimer()).then(()=>{console.log(':: store-logout-timer :: ')}).catch((error)=>{console.log(error)});
  store.dispatch(loginCheck()).then(()=>{console.log(':: store-login-check :: ')}).catch((error)=>{console.log(error)});
  store.dispatch(checkDefaultLanguage()).then(()=>{console.log(':: store-checkDefaultLanguage :: ')}).catch((error)=>{console.log(error)});
})();

export default store;