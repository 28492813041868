import React from "react";
import '../../CSS/FourthSection.css';
import HowToOrder from "../../assets/How-to-order.png";
import HowToOrder2 from "../../assets/orderPotrait.png";
import HeaderTextComponent1 from "./HeaderAndText/HeaderTextComponent1";
import { useSelector } from "react-redux";
export default function FourthSection(){
    const {internationalization} = useSelector(state=>state.language)
    return(
        <div>
        <HeaderTextComponent1 
            header={internationalization.how_to_order}
        />

        <div style={{height:'50%', overflowY:'scroll'}}>
           <img src={HowToOrder} className='how-to-order' />
        </div>
    </div>
    )
}