import React, {useEffect, useState} from "react";
import cropway_logo from '../assets/Cropway.png';
import { ScreenLayout } from "../Screens/ScreenLayout";
import ReactToPdf from 'react-to-pdf';
import userService from "../Services/user.service";
import { useLocation } from "react-router-dom";

function Invoice() {
    const location = useLocation()
    let tableData = [1,2,3,4,5,6]
    const ref = React.createRef();
    let height = window.innerHeight
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [12, 16]
    };
    const [orderDetails, setOrderDetails] = useState([])
    useEffect(()=>{
        userService.getOrderEnquiryById(location.state.orderId)
                   .then((res)=>{
                    setOrderDetails(res.data)
                   })
                   .catch((error)=>{
                    console.log(error)
                   })
    },[location.state.orderId])
return(
    <ScreenLayout component={
    <div>
        <table style={{border:'solid 1px #BDBDBD',width:'100%',minWidth:'100%'}} ref={ref}>
            <thead>
                <tr style={{backgroundColor:'rgba(93, 167, 202, 0.2)',textAlign:'center',height:55}}>
                    <th colSpan='4'>
                        <img src={cropway_logo} alt='logo' style={{width:50,margin:'0% 2%'}}/>
                        INVOICE
                    </th>
                </tr>
            </thead>
            <tbody style={{border:'1px solid #BDBDBD'}}>
                <tr>
                    <th style={{paddingLeft:10,height:30}} >Cropway</th>
                </tr>
                <tr>
                    <th style={{paddingLeft:10,height:30}} >Baoz agri Pvt. Ltd.</th>
                </tr>
                <tr>
                    <th style={{paddingLeft:10,height:30}} >P-22, Sec I, Avanti Vihar,Opp CSEB Substation,Raipur (C.G) - 492006</th>
                </tr>
                <tr>
                    <th style={{paddingLeft:10,height:30}} >Cont. No. : </th>
                </tr>
            </tbody>
            <div style={{display:'flex'}}>
            <table style={{tableLayout:'fixed',width:'50%'}}>
                <tbody>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Name</th>
                        <td>:</td>
                        <td>{orderDetails && orderDetails.to_user && orderDetails.to_user.username}</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Supply Address</th>
                        <td>:</td>
                        <td>v2</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Contact Details</th>
                        <td>:</td>
                        <td>{orderDetails && orderDetails.to_user && orderDetails.to_user.phone}</td>
                    </tr>
                    
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10,whiteSpace:'nowrap'}}>Delivery Addresss</th>
                        <td>:</td>
                        <td>v6</td>
                    </tr>
                </tbody>
            </table>
            <table style={{tableLayout:'fixed',width:'50%'}}>
                <tbody>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Name</th>
                        <td>:</td>
                        <td>{orderDetails && orderDetails.to_user && orderDetails.from_user.username}</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Supply Address</th>
                        <td>:</td>
                        <td>v2</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Contact Details</th>
                        <td>:</td>
                        <td>{orderDetails && orderDetails.to_user && orderDetails.from_user.phone}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <table style={{tableLayout:'fixed',width:'100%'}}>
                <tbody style={{border:'1px solid #BDBDBD',}}>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Invoice Number</th>
                        <td>:</td>
                        <td>{'invoice_0987654'}</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Mode of Payment</th>
                        <td>:</td>
                        <td>v7</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Payment Date & Time</th>
                        <td>:</td>
                        <td>v8</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>Transection Number</th>
                        <td>:</td>
                        <td>v9</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>PAN Number</th>
                        <td>:</td>
                        <td>v10</td>
                    </tr>
                    <tr>
                        <th style={{ width:'30%',paddingLeft:10}}>GST Number</th>
                        <td>:</td>
                        <td>v11</td>
                    </tr>
                </tbody>
            </table>
            <table style={{tableLayout:'fixed', width:'100%'}}>
                <thead>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>S.No.</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Perticular</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Quantity</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Unit Price</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Amount</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Tax Type</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Tax Rate</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Tax Amount</th>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total Amount</th>
                </thead>
                <tbody>
                {
                    tableData.map((data,index)=>(
                    <tr>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>{index+1}</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Shipping</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>100 kg</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>500</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>50000t</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Tax Type</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Tax Rate</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Tax Amount</td>
                        <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total Amount</td>
                    </tr>
                ))
                }
                <tr>
                    <th style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Grand Total</th>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total Shipping</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total qty</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total unit price</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total amount</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>---</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>---</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Total Tax Amount</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center'}}>Grand Total</td>
                </tr>
                </tbody>
                
            </table>
            <table style={{tableLayout:'fixed',width:'100%'}}>
                <tbody>
                    <tr>
                        <th style={{ width:'30%'}}>Total Amount in Words</th>
                        <td>:</td>
                        <td>v12</td>
                    </tr>
                </tbody>
            </table>
            <table style={{tableLayout:'fixed',width:'100%'}}>
                <tr style={{height:80}}>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center', verticalAlign:'bottom', width:'20%'}}>General Manager</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center', verticalAlign:'bottom', width:'20%'}}>Prepared By</td>
                    <td style={{border:'1px solid #BDBDBD', textAlign:'center', verticalAlign:'bottom', width:'20%'}}>Authosired Signatory</td>
                </tr>
            </table>
            <table style={{tableLayout:'fixed',width:'100%'}}>
                <tr style={{height:80}}>
                    <tr>
                        <th style={{width:'100%'}}>Remarks</th>
                    </tr>
                    <tr>
                        <td style={{width:'100%'}}>1. All payments should be in favor of "Cropway boaz agri Pvt. Ltd." payble at Raipur.</td>
                    </tr>
                    <tr>
                        <td style={{width:'100%'}}>2. For each bounced cheque Rs. --- will be changed extra.</td>
                    </tr>
                    <tr>
                        <td style={{width:'100%'}}>3. Subject to --- Jurisdiction.</td>
                    </tr>
                </tr>
            </table>
        </table>

        <div>
    <ReactToPdf targetRef={ref} filename="div-blue.pdf" options={options} x={0.5} y={0.5} >
        {({toPdf}) => (
            <button
            className="sign-button"
            onClick={toPdf}
            style={{position:'absolute', right:35, height:40, width:150, margin:20}}
            >
                Generate pdf
            </button>
        )}
    </ReactToPdf>
    </div>
    </div>
    }
    />
    )
}

export default Invoice