import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/CommonComponents/Footer";
import WelcomeHeader from "../Components/CommonComponents/WelcomeHeader";

export default function WelcomeLayoutScreen(){
    return(
        <div>
            <WelcomeHeader/>
            <Outlet/>
            <Footer/>
        </div>
    )
}