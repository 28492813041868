import languageService from "../Services/language.service";

export const updateLanguage = (language) => async(dispatch) => {
    console.log("action language :: ",language)
    languageService.setDefaultLanguage(language)
    dispatch({type : language})
};

export const checkDefaultLanguage = () => async(dispatch) => {
    languageService.getDefaultLanguage()
               .then((data)=>{
                data && dispatch({type : data.language})
            })
            .catch((error)=>{
                    console.log("error :: ", error)
                })
};


