import React, { useEffect, useState} from "react";
import { useSelector } from "react-redux";
import ScreenHeader from "../Components/CommonComponents/ScreenHeader";
import { ScreenLayout } from "./ScreenLayout";
import totalOrderimg from "../assets/shopping-bag_oderH.png";
import totalBoximg from "../assets/box_orderH.png";
import totalCancellimg from "../assets/cancel_orderH.png";
import moment from "moment";
import userService from "../Services/user.service";
import { CSVLink } from "react-csv";
import DateTimePicker from 'react-datetime-picker';
import { useNavigate} from 'react-router-dom';
import '../CSS/OrderHistoryScreen.css'

function OrderHistoryScreen() {
  const {user} = useSelector((state)=> state.auth)
  const [orderhistory, setOrderHistory] = useState([]);
  const [startDateTime, setStartDateTime] = useState(new Date(Date.parse(new Date()) - (7*24*60*60*1000)));
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [downloadCSVData, setDownloadCSVData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate()
  let activeorder = 0;
  let pendingorder = 0;
  let fulfilledorder = 0;
  let cancelldorder = 0;
  const [image, setImage] = useState([])
  const statusColor=(status)=> {
                                let color = status ==='Active' ? "#54863f" : 
                                status ==='Pending'   ? "#ffdc75"  :
                                status ==='Fulfilled' ? "#a9a9a9"  : 
                                status ==='Cancelled' ?  "#d11919" :
                                status ==='Confirmed' ?  "#000000" : null 
                            return color;
                    }
  
  const DownloadDateTimeRange = async() =>{
        let exportData = {
          "user" : user.id,
          "startDate" : startDateTime,
          "endDate" : endDateTime
        }

        setLoading(true)
        startDateTime && endDateTime
        && 
        await userService.downloadOrderData(exportData)
        .then((res)=>{
                    setDownloadCSVData(res.data)
                    setLoading(false)
                     })
                     .catch((error)=>{
                      console.log(error)
                      setLoading(false)
                     })
  }

  useEffect(()=>{
    setDownloadCSVData([])
    DownloadDateTimeRange()
  },[startDateTime, endDateTime])

  useEffect(()=>{
       userService.getOrderEnquiryByUserId()
       .then((response)=>{
           setOrderHistory(response.data)
       })
       .catch((error)=>{
        console.log(error)
       })
  },[])

  // orderhistory && orderhistory.forEach(item=>{
  //   if(item.order_status===1){
  //     activeorder++
  //   }
  //   else if(item.order_status===2){
  //     pendingorder++
  //   }
  //   else if(item.order_status===3){
  //     fulfilledorder++
  //   }
  //   else{
  //     cancelldorder++
  //   }
  // })

  orderhistory && orderhistory.forEach(item=>{
    if(item.order_status.status==='Active'){
      activeorder++
    } else if(item.order_status.status==='Pending'){
      pendingorder++
    } else if(item.order_status.status==='Fulfilled'){
      fulfilledorder++
    } else if(item.order_status.status='Cancelled'){
      cancelldorder++
    }
  })

  const OrderHistoryComponent = () => {
   
    return (
      <div>
        <div>
          <ScreenHeader headerTitle={"Orders"} />
          <hr style={{width:'100%'}}/>
        </div>
        <div

        className="order-history-data"          
        >
         <div className="total-data-info-div">
         <div
            className="rounded-circle"
            
          >
            <img
              src={totalOrderimg}
              alt="shopping bag "
              className="img-thumbnail "
              style={{ margin: "1rem" }}
            />
          </div>

          <div className=" order-content-div col-md-4">
            <p>Total Orders</p>
            <h2>{orderhistory.length}</h2>
          </div>
         </div>

         <div className="total-data-info-div">
         <div
            className="rounded-circle1"
          >
            {" "}
            <img
              src={totalBoximg}
              alt="box.png "
              className="img-thumbnail"
              style={{ margin: "15px" }}
            />
          </div>

          <div className="order-content-div2 col-md-4">
            <p>Total Pending</p>
            <h2>
              {pendingorder}
            </h2>
          </div>
         </div>

          <div className="total-data-info-div">
          <div
            className="rounded-circle2"
          >
            <img
              src={totalCancellimg}
              alt="cancell.png "
              className="img-thumbnail"
              style={{ margin: "15px" }}
            />
          </div>

          <div className="order-content-div3 col-md-4">
            <p>Total Cancelled</p>
            <h2>{cancelldorder}</h2>
          </div>
          </div>
        </div>
        <div>
          <ScreenHeader headerTitle={"Order List"} />
        </div>

      <div className="order-list-margin-left">
      <div className="order-list-overflow-scroll">
        <table className="table m-lg-4 order-list-th">
          <thead>
            <tr className="table-info">
              <th scope="col"></th>
              <th scope="col">Product Name</th>
              <th scope="col">Order Id</th>
              <th scope="col">Order Date</th>
              <th scope="col">Status</th>
              <th scope="col">Total</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {orderhistory.map((order, key)=>(
                   <tr
                   key={key}
                   onClick={(e)=>(e.target.className==="")? navigate('recivedorder',{state:{orderEnquiryId : order.id, orderStatus : order.order_status.status }} ) : false}
                   >
                   <th scope="row">
                     <img src={order.product.newProduct.icon} alt="..." style={{width:50, height:50, borderRadius:5}} />
                   </th>
                   <td>
                     <p>
                       {order.product && order.product.product} 
                       <br/>
                       Required Quantity : {order.required_quantity}{order.unit}
                     </p>
                   </td>
                   <td>{"Order_"}{order.id}</td>
                   <td>{moment(order.created_at).utc().format('DD/MM/YYYY')}</td>
                   <td>
                     <div
                       style={{ 
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        color:statusColor(order.order_status.status)
                      }}
                     >
                       <span
                         style={{
                           margin:5,
                           height: 8,
                           width: 8,
                           backgroundColor: statusColor(order.order_status.status),
                           borderRadius: "100%",
                         }}
                       />
                       <div>
                       {order.order_status.status}
                        </div>
                     </div>
                   </td>
                   <td>{order.product && order.product.price * order.required_quantity}</td>
                   <td>
                     <button
                       type="button"
                       className=" btn-outline-dark"
                       style={{ border: "none" }}
                       onClick = {(e)=> {e.stopPropagation(); navigate('invoice', {state:{orderId:order.id}})} }
                     >
                      Invoice
                     </button>
                   </td>
                 </tr>
            ))}
            
          </tbody>
        </table>
        </div>
         
        <div className="fiter-order-list-data-div">
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>Start Date</label>
                    <DateTimePicker
                        onChange={(datetime)=>setStartDateTime(datetime)}
                        value={startDateTime}
                        format='dd/MM/yyyy'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        isClockOpen={false}
                        disableClock
                        className='calender'
                        openWidgetsOnFocus={false}
                        returnValue='start'
                        maxDate={new Date()}
                        required
                    />
              </div>
              <div className="fiter-order-list-data-div">
                    <label style={{margin:5,height:'100%',borderRadius:5,display:'flex',alignItems:'center'}}>End Date</label>
                    <DateTimePicker
                        onChange={(datetime)=>setEndDateTime(datetime)}
                        value={endDateTime}
                        format='dd/MM/yyyy'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        isClockOpen={false} 
                        disableClock
                        className='calender'
                        openWidgetsOnFocus={false}
                        returnValue='start'
                        maxDate={new Date()}
                        required
                    />
              </div>
              
            
              {
                downloadCSVData.length > 0 ? 
                <CSVLink
                  data={downloadCSVData}
                  filename={'order_history_data'}
                >
                  Download Order
                </CSVLink>  
                 : 
                 <div className="ms-2 mt-3">
                   {Loading ? "Loading..." : "Order data not found b/w these date range" }
                </div>
              }
      </div>
    </div>
    );
  };
  return <ScreenLayout component={<OrderHistoryComponent />} />;
}

export default OrderHistoryScreen;
