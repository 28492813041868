// register/login/logout actions
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "./type";
import AuthService from "../Services/auth.service";
import jwt_decode from "jwt-decode";

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch(runLogoutTimer())
        return Promise.resolve();
      },
      (error) => {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.detail) ||
          error.message ||
          error.toString();
          dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };

  export const loginCheck = () => (dispatch) => {
      let user = JSON.parse(localStorage.getItem('user'))
      // console.log('check 2 :: ', user)
      if(user){
        let accessToken = user.access
        // console.log('check 2 :: ',accessToken, jwt_decode(accessToken))
        if (jwt_decode(accessToken).exp < Date.now()/1000) {
          console.log('check 2 :: ',accessToken)
          AuthService.logout();
          dispatch({
            type: LOGOUT,
          });
        }
      }
      return Promise.resolve();
    };

  export const runLogoutTimer= () => (dispatch) => {
    const data = JSON.parse(localStorage.getItem('user'))
    if(data){
      console.log(new Date(jwt_decode(data.access).exp *1000))
      const timer = new Date(jwt_decode(data.access).exp *1000) - Date.now()
            setTimeout(()=>{
              dispatch(logout())
            }, timer)
      return Promise.resolve();
    }
    else{
      return Promise.reject();
    }
  }

  export const logout = () => (dispatch) => {
    console.log('logout called :: --')
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
  };