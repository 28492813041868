import React from 'react'

const AddProductModal = ({handleUpload, setVisible, modalText}) => {
  return (
    <>
    <div style={{marginTop: 80, border:'1px solid black', borderRadius:5}}>
            <text >
                {modalText}
            </text>
    </div>

    <div 
        style={{
            position:'absolute',
            left:20,
            right:20,
            bottom:0,
            margin:'auto',
            height:50,
        }}>

    <div
        style={{
            display:'flex',
            justifyContent:'space-between',
            position:'absolute',
            left:0,
            right:0,
            bottom:10,
            margin:'auto',
            }}
            >
            <button style={{padding:10, margin:5, borderRadius:5}} onClick={()=>setVisible(false)}>Cancle</button>
            <button style={{padding:10, margin:5, borderRadius:5}} onClick={handleUpload}>Ok</button>
            </div>
    </div>
    </>
  )
}

export default AddProductModal