import React from 'react';
function ScreenHeader({headerTitle, color}) {
  return (
    <div 
      style={{
         height:"30px",
         display:'flex',
         flexDirection:'column',
         }}
      >
      
     <div
      style={{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        }}
        >
        
      <div style={{width:5,height:29,backgroundColor:'#5DA7CA',margin:3}}/>
      
      <div> <strong style={{color:color ? color : '#000000'}} className='dashboard-heading-font'>{headerTitle}</strong> </div>
     </div>

       {/* <hr
        style={{
            height:10,
            width: '100%',
            color:'#BDBDBD'
        }}
      /> */}
     
    </div>
  );
}

export default ScreenHeader;
