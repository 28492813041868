import React, {useState} from 'react';
import '../CSS/Login.css';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ImageArea from '../Components/LoginComponents/ImageArea';
import cropwaylogo from '../assets/CropwayLogo.png';
import usericon from '../assets/user.png';
import lockicon from '../assets/lock.png';
import {login} from '../actions/auth';
import ReactGA from "react-ga4";

function LoginScreen() {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const locationFrom = location.state?.from?.pathname || "/dashboard";
    const { isLoggedIn } = useSelector(state => state.auth);
    const { internationalization } = useSelector(state => state.language);
    const { message : loginError } = useSelector(state => state.message);

    const submitLoginForm=async(e)=>{
      e.preventDefault();
      setLoading(true)
      dispatch(login(user, password))
      .then(()=>{
        setLoading(false)
        // navigate(locationFrom, { replace:true })
        // window.location.reload();
      })
      .catch(()=>{
        setLoading(false)
      })

      // GA-4
      ReactGA.event({
        action: "login_action",
        category: "authentication_and_authorization",
        label: "login",
        value: "xxxxxx",
      })

    window.gtag("event", "add_to_cart", {
      currency: "USD",
      value: 7.77,
      items: [
        {
          item_id: "SKU_12345",
          item_name: "Stan and Friends Tee",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          currency: "USD",
          discount: 2.22,
          index: 0,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "green",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: 9.99,
          quantity: 1
        }
      ]
    });

    }
    
    // if already logged in and try to go on login page :-> redired to dashboard or the current page
    if (isLoggedIn) {
      return <Navigate to = {locationFrom}/> 
    }
    // set error message
    

  return (
    <div className='flex-row'>
      <div className='half-screen'>
      <div className='first-content'>
        <div className='first-content-width' onClick={()=>navigate('/')}>
          <div className='gradient-text padding-margin-2'>{internationalization.welcome_to}</div>
          <div className='layout-center padding-margin-2'>
            <img alt='cropway-icon' src={cropwaylogo} className='login-img'/>
          </div>
          <div className='gradient-text padding-margin-2' style={{cursor:'pointer'}}>{internationalization.seller_studio}</div>
          <div className='layout-center padding-margin-2 font-lowertext'>{internationalization.login_to_account}</div>
        </div>
      </div>
      <form onSubmit={(e)=>submitLoginForm(e)} className='layout-center'>
        <div className='padding-margin-2 flex-column first-content-width'>
          <div className='padding-margin-2 input-layout'>
            <div className='layout-center' style={{width:'5vw',height:'100%'}}>
            <img alt='user-icon' src={usericon} style={{width:'1.5vw'}}/>
            </div>
            <div className='layout-center' style={{width:'100%'}}>
            <input
             className='input-border-none focus-input-border-none input-box'
             type='text'
             placeholder={internationalization.username}
             onChange={(e)=>setUser(e.target.value)} required/>
          </div>
          </div>
          <div className='padding-margin-2 input-layout'>
            <div className='layout-center' style={{width:'5vw',height:'100%'}}>
            <img alt='lock-icon' src={lockicon} style={{width:'1.5vw'}}/>
            </div>
          <div className='layout-center' style={{width:'100%'}}>
          <input
            className='input-border-none focus-input-border-none input-box' 
            type='password'
            placeholder={internationalization.password}
            required
            onChange={(e)=>setPassword(e.target.value)}
            />
          </div>
          </div>
          <button className='gradient-button' type='submit'>{ loading ? 'SIGNING IN..' : internationalization.sign_in }</button>

          <p className='layout-center padding-margin-2 font-lowertext'>
                  {internationalization.dont_have_account}
                  <a href='https://marketplace.cropway.com/Register' style={{textDecoration:'none',color:'#5DA7CA'}}>{internationalization.sign_up}</a>
          </p>
          {
            loginError && 
            <p style={{textAlign:'center', color:'#b82c2c'}}>
              {loginError}
            </p>
          }
        </div>
      </form>
    </div>
      <ImageArea/>
    </div>
  );
}

export default LoginScreen;
