import React from "react";
import "../../../CSS/ValueAddedDetailComponents.css";

export default function ValueAddedDetailComponents({image,para1,para2,para3}) {
  return (
    <>
      {/* <div className="ValueMainBox">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
          Eveniet ab doloremque consequuntur hic voluptates, obcaecati maxime,
          tempora deserunt ipsa odit harum recusandae atque ullam? Similique
          laudantium animi sequi fugit, iure dignissimos voluptate perspiciatis
          voluptates.
        </p>
      </div> */}
      <div className="valueImg">
        <div
          className="card  text-white  value-added-details-compo-text"
          
        >
          <div className="imgOverlyer" />
          <img
            src={image}
            alt="Card image"
            className="value-added-details-compo-img"
          />
          <div className="card-img-overlay valueparas">
            <p>
              <b>{para1}</b>
            </p>
            <p style={{whiteSpace:'pre-line'}}>{para2} </p>
          </div>
        </div>
      </div>
    </>
  );
}
