import React, { useEffect, useState } from 'react'

export const StatusButton = ({orderEnquiryId, orderStatus, navigate}) => {
  const [buttonBackground, setButtonBackground] = useState()
  const [buttonBorder, setButtonBorder] = useState()
  const [buttonTextColor, setButtonTextColor] = useState()
  
  useEffect(()=>{
    if(orderStatus === 'Active'){
       setButtonBackground('#E1FCEF')
       setButtonBorder('1px solid #E1FCEF')  
       setButtonTextColor('#14804A')
    } 
    else if(orderStatus === 'Pending'){
       setButtonBackground('#FFEBD3')
       setButtonBorder('1px solid #FFEDEF')    
       setButtonTextColor('#FB8700')
    } 
    else if(orderStatus === 'Fulfilled'){
       setButtonBackground('#EFEFEF')
       setButtonBorder('1px solid #EFEFEF')  
       setButtonTextColor('#808080')
    } 
    else if(orderStatus === 'Cancelled'){
       setButtonBackground('#FFEDEF')
       setButtonBorder('1px solid #FFEDEF')   
       setButtonTextColor('#D1293D')
    }
    else if(orderStatus === 'Confirmed'){
       setButtonBackground('#000000')
       setButtonBorder('1px solid #000000')   
       setButtonTextColor('#ffffff')
    }
    else{
      setButtonBackground('#FFEDEF')
      setButtonBorder('1px solid #FFEDEF')  
      setButtonTextColor('#D1293D')
    }
  },[orderStatus])

  const styles={
    buttonStyle:{
      height:'100%',
      justifyContent:'center',
      display:'flex',
      alignItems:'center',
    }
  }

  return (
    <div
        style={{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        }}>
    <button
    onClick={()=>navigate('recivedorder', {state:{orderEnquiryId : orderEnquiryId, orderStatus : orderStatus }})}
    style={{
        minWidth:110,
        height:30, 
        cursor:'pointer',
        backgroundColor: buttonBackground,
        borderRadius:5,
        border: buttonBorder,
        color:buttonTextColor,
        display:'flex',
    }}>
        <div style={{...styles.buttonStyle, width:'20%'}}>
            <div
              style={{
                height:6,
                width: 6,
                backgroundColor:buttonTextColor,
                borderRadius:5,
                }}
              />
        </div>
        <div style={{...styles.buttonStyle, width:'80%', whiteSpace:'nowrap'}}>
            <text>{orderStatus}</text>
        </div>
    </button>
    </div>
  )
}
