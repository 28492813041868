import LandingScreen from './Screens/LandingScreen';
import RequireAuth from './Components/AuthenticationComponents/RequireAuth';
import LoginScreen from './Screens/LoginScreen';
import MainScreen from './Screens/MainScreen';
import ProfileScreen from './Screens/ProfileScreen';
import AddProductScreen from './Screens/AddProductScreen';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AddNewUserScreen from './Screens/AddNewUserScreen';
import ManageUserScreen from './Screens/ManageUserScreen';
import HomeScreen from './Screens/HomeScreen';
import ProductListScreen from './Screens/ProductListScreen';
import OrderHistoryScreen from './Screens/OrderHistoryScreen';
import CropwayGuide from './Screens/CropwayGuide';
import ProductViewScreen from './Screens/ProductViewScreen';
import RequirementProductViewScreen from './Screens/RequirementProductViewScreen';
import RecivedOrderScreen from './Screens/RecivedOrderScreen';
import WelcomeScreen from './Screens/WelcomeScreen';
import WelcomeLayoutScreen from './Screens/WelcomeLayoutScreen';
import UnauthorizedScreen from './Screens/UnauthorizedScreen';
import BuyerSellerDetailScreen from './Components/WelcomeScreenComponent/WelcomeScreenChildrenComponent/BuyerSellerDetailScreen';
import ValueAddedDetailScreen from './Components/WelcomeScreenComponent/WelcomeScreenChildrenComponent/ValueAddedDetailScreen';
import ChooseProductCategoryScreen from './Screens/ChooseProductCategoryScreen';
import SelectProductForAuction from './Screens/Auction/SelectProductForAuction';
import AddToAuctionScreen from './Screens/Auction/AddToAuctionScreen';
import ManageAuctionScreen from './Screens/Auction/ManageAuctionScreen';
import AuctionViewScreen from './Screens/Auction/AuctionViewScreen';
import PageNotFound from './Screens/PageNotFound';
import Invoice from './Components/Invoice';
import ReactGA from "react-ga4";
import {loginCheck} from './actions/auth'
import { useDispatch } from 'react-redux';
// import AuthVerify from './Components/AuthenticationComponents/AuthVerify';

// ReactGA.initialize("G-D20L0T8QLY")
function App() {
  return (
    <div className='App' style={{padding:1}}>
        <Router>
          <Routes>
              {/* public routes */}
              <Route path='/' element={<WelcomeLayoutScreen/>}>
                  <Route index element={<WelcomeScreen/>} />
                  <Route path='aboutbuyer' element={<BuyerSellerDetailScreen/>} />
                  <Route path='aboutseller' element={<BuyerSellerDetailScreen/>} />
                  <Route path='aboutvas' element={<ValueAddedDetailScreen/>} />
                  
              </Route>
              <Route path='dashboard' element={<MainScreen/>}>
                  <Route path='productview' element={<ProductViewScreen/>} />
                  <Route path='requirementproductview' element={<RequirementProductViewScreen/>} />
              </Route>
              <Route path='unauthorized' element={<UnauthorizedScreen/>} />
              {/* <Route path='/sellerstudio' element={<LandingScreen/>}/> */}
              <Route path='login' element={<LoginScreen/>} />
              {/* authorized routes */}
              <Route element={<RequireAuth/>}>
                <Route path='dashboard' element={<MainScreen/>}>
                    <Route path='' element={<HomeScreen/>} />
                    <Route path='profile' element={<ProfileScreen/>} />
                    <Route path='chooseproductcategory'>
                      {/* <Route index element={<ChooseProductActionScreen/>} /> */}
                        <Route index element={<ChooseProductCategoryScreen/>} />
                        <Route path='addproduct' element={<AddProductScreen/>} />
                    </Route>
                    <Route path='orderhistory'>
                      <Route index element={<OrderHistoryScreen/>} />
                      <Route path='recivedorder' element={<RecivedOrderScreen/>} />
                      <Route path='invoice' element={<Invoice/>} />
                    </Route>
                    <Route path='cropwayguide' element={<CropwayGuide/>} />
                    <Route path='auction'>
                    <Route index element={<ManageAuctionScreen/>}/>
                    <Route path='selectproductforauction'>
                        <Route index element={<SelectProductForAuction/>}/>
                        <Route path='addtoauction' element={<AddToAuctionScreen/>}/>
                        <Route path='auctionview' element={<AuctionViewScreen/>} />
                    </Route>
                    </Route>
                    <Route path='auctionview' element={<AuctionViewScreen/>} />
                    <Route path='productlist'>
                       <Route index element={<ProductListScreen/>} />
                       <Route path='editlistedproduct' element={<AddProductScreen/>} />
                    </Route>

                    <Route path='manageuserscreen'>
                      <Route index element={<ManageUserScreen/>}/>
                      <Route path='addnewuser' element={<AddNewUserScreen/>}/>
                    </Route>
                    <Route path='recivedorder' element={<RecivedOrderScreen/>} />
                    <Route path='auction'>
                      <Route index element={<ManageAuctionScreen/>}/>
                      <Route path='selectproductforauction'>
                        <Route index element={<SelectProductForAuction/>}/>
                        <Route path='addtoauction' element={<AddToAuctionScreen/>}/>
                        <Route path='auctionview' element={<AuctionViewScreen/>}/>
                      </Route>
                    </Route>
                </Route>
              </Route>
              <Route path='*' element={<PageNotFound/>} />
          </Routes>
            {/* <AuthVerify/> */}
        </Router>
    </div>
  );
}

export default App;