// Authentication service
import axios from "axios";
import { HOST } from "./host.header";
const API_URL = HOST+"/core/";

const login=async(username, password)=>{
  return axios
    .post(API_URL + "login/", {
      username,
      password,
    })
    .then((response) =>{
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data));
        // localStorage.setItem("role", role);
      }
      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
  // localStorage.removeItem("role");
  return null;
};

export default {
  login,
  logout,
};