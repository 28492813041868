import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import { ScreenLayout } from './ScreenLayout';
import userService from '../Services/user.service';

function RecivedOrderScreen() {
    const location = useLocation()
    const [orderEnquiry, setOrderEnqiry] = useState(null)
    const [accept, setAccept] = useState('Accept')
    const [decline, setDecline] = useState('Cancel')
    const [orderStatus, setOrderStatus] = useState('')
    const [loading, setLoading] = useState(false)
    let orderEnquiryId = location.state.orderEnquiryId
    let forAccepted = ['Pending', 'Confirmed']
    useEffect(()=>{
        forAccepted.includes(orderStatus) ? setAccept('Accepted') :
        orderStatus==='Fulfilled' ? setAccept('Fulfilled') :
        orderStatus==='Cancelled' ? setDecline('Cancelled') : setDecline('Cancel') 
        
        setLoading(true)
        userService.getOrderEnquiryById(location.state.orderEnquiryId)
             .then((response)=>{
                  setOrderEnqiry(response.data)
                  setOrderStatus(response.data.order_status.status)
                  setLoading(false)
             })
             .catch((error)=>{
                console.log(error)
                setLoading(false)
             })

            },[accept, decline, orderStatus, orderEnquiryId])
            
    let cos = orderEnquiry && (orderEnquiry.product.count_in_stock)
    let rq = orderEnquiry && (orderEnquiry.required_quantity)
    let fullfillingRequirement = cos >= rq

    const orderAction=(status)=>{
         let data = {
                    order_enquiry_id : orderEnquiryId,
                    status : status
                }

         userService.updateOrderEnquiryStatus(data)
              .then((res)=>{
                  setOrderStatus(res.data)
              })
    }
    const OrderRecivedComponent=()=>{
        return (
        <>
        <ScreenHeader headerTitle={'Order Action'}/>
        <hr style={{width:'100%'}}/>
        {loading ? <h3>Loading</h3> :
        <div>
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <text style={{width:'50%', color:'#808080'}}>Product</text>
                    <text style={{marginRight:15}}>:</text>
                    <p style={{fontWeight:500}}>{orderEnquiry && orderEnquiry.product.product}</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <text style={{width:'50%', color:'#808080'}}>Product Type</text>
                    <text style={{marginRight:15}}>:</text>
                    <p style={{fontWeight:500}}>{orderEnquiry && orderEnquiry.product.productDetail.product_type}</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <text style={{width:'50%', color:'#808080'}}>Date of Delivery</text>
                    <text style={{marginRight:15}}>:</text>
                    <p style={{fontWeight:500}}>{orderEnquiry && orderEnquiry.date_of_delivery}</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <text style={{width:'50%', color:'#808080'}}>Delivery Location</text>
                    <text style={{marginRight:15}}>:</text>
                    <p style={{fontWeight:500}}>
                        {orderEnquiry && orderEnquiry.delivery_location.origin_address}
                        ,{orderEnquiry && orderEnquiry.delivery_location.region_of_origin}
                        ,{orderEnquiry && orderEnquiry.delivery_location.zipcode}
                    </p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <text style={{width:'50%', color:'#808080'}}>Required Quantity</text>
                    <text style={{marginRight:15}}>:</text>
                    <p style={{fontWeight:500}}>{orderEnquiry && orderEnquiry.required_quantity} <text>{orderEnquiry && orderEnquiry.unit}</text></p>
                    <p style={{fontSize:12,color:'red', marginLeft:10 }}>
                        <text>
                            {forAccepted.includes(orderStatus) || fullfillingRequirement ? 
                            null : 
                            `not fullfilling buyer's requirement {available quantity : ${orderEnquiry && orderEnquiry.product.count_in_stock} ${orderEnquiry && orderEnquiry.product.unit}}`}
                        </text>
                    </p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <text style={{width:'50%', color:'#808080'}}>Message</text>
                    <text style={{marginRight:15}}>:</text>
                    <p style={{fontWeight:500}}>{orderEnquiry && orderEnquiry.message}</p>
                </div>
            </div>
            
            <div style={{display:'flex',alignItems:'center',}}>
                <div style={{borderRadius:5,height:40,alignItems:'center',justifyContent:'center',margin:'2vw',
                            display: orderStatus==='Cancelled'? 'none' : 'block' }}>
                    <button
                    value='Pending'
                    disabled= {forAccepted.includes(orderStatus) || !fullfillingRequirement ? true : false}
                    onClick={(e)=>orderAction(e.target.value)}
                    style={{
                        width:'10vw',
                        height:'3vw',
                        fontSize: '1vw',
                        borderRadius:5,
                        border:forAccepted.includes(orderStatus) ? '1px solid #E1FCEF' : '1px solid #5CA3C5',
                        backgroundColor:forAccepted.includes(orderStatus) ? '#E1FCEF' : '#5CA3C5',
                        color: forAccepted.includes(orderStatus) ? '#14804A' : '#ffffff',
                    }}
                    >
                        {accept}
                    </button>
                </div>
            
                <div style={{borderRadius:5,height:40,alignItems:'center',justifyContent:'center',margin:'2vw',
                            display: forAccepted.includes(orderStatus) ? 'none' : 'block' }}>
                    <button
                        value='Cancelled'
                        onClick={(e)=>orderAction(e.target.value)}
                        style={{
                            width:'10vw',
                            height:'3vw',
                            fontSize: '1vw',
                            borderRadius:5,
                            border:orderStatus==='Cancelled' ? '1px solid #FFEDEF' : '1px solid #5CA3C5', 
                            backgroundColor:orderStatus==='Cancelled' ? '#FFEDEF' : '#5CA3C5',
                            color:orderStatus==='Cancelled' ? '#D1293D' : '#ffffff',
                        }}
                    >
                        {decline}
                    </button>
                </div>
            </div>

            <div style={{display:'flex',alignItems:'center',}}>
            <div style={{borderRadius:5,height:40,alignItems:'center',justifyContent:'center',margin:'2vw',
                            display: orderStatus==='Confirmed'? 'block' :'none'  }}>
                    <button
                    style={{
                        width:'10vw',
                        height:'3vw',
                        fontSize: '1vw',
                        borderRadius:5,
                        border: '1px solid #E1FCEF' ,
                        backgroundColor: '#E1FCEF',
                        color:  '#14804A',
                    }}
                    >
                        {'Payment Confirmed'}
                    </button>
                </div>
            </div>
        </div>
        }
        </>
        )
    }
    return (
        <ScreenLayout component={<OrderRecivedComponent/>} />
    );
    }
    
export default RecivedOrderScreen;