import React, { useEffect, useRef} from "react";
import * as d3 from 'd3';
import useWindowDimensions from "../useWindowDimension";
import userService from "../../Services/user.service";
import { useState } from "react";

export default function LineChart({marketData}){
    const svgRef = useRef()
    const {height, width} = useWindowDimensions()
   
   const chart=()=>{
    const w = width/2.3;
    const h = height/4.5;
    const svg = d3.select(svgRef.current)
                  .attr('width', w)
                  .attr('height', h)
                  .attr('viewBox', `0 0 ${w} ${h}`)
                  .style('border-radius', '5px')
                  .style('background', '#fff')
                  .style('overflow', 'visible')
                //   .style('border', '1px solid blue')

    // setting up scaling
    const xScale = marketData && d3.scaleTime()
        .domain(d3.extent(marketData?.data, function(d){return new Date(d.price_date)}))
        .range([0, w]);
    const yScale = marketData && d3.scaleLinear()
        .domain(d3.extent(marketData?.data, function(d){return d.modal_price}))
        .range([h, 0])

    const generateScaleLine= d3.line()
                                .x((d)=>xScale(new Date(d.price_date)))
                                .y((d)=>yScale(d.modal_price))
                                .curve(d3.curveCardinal);

    // setting the axes
    const xAxis = d3.axisBottom(xScale)
      .ticks(8)
      const yAxis = d3.axisLeft(yScale)
      .ticks(8)
 
 
    // setting up grids
    const xAxisGrid = d3.axisBottom(xScale)
      .ticks(marketData?.data.length)
      .tickSize(-h)
      .tickFormat('')

    const yAxisGrid = d3.axisLeft(yScale)
      .ticks(5)
      .tickSize(-w)
      .tickFormat('')
 
    // axes
    svg.append('g')
        .attr('transform', `translate(0, ${h})`)
        .attr('color', '#000')
        .call(xAxis)
    svg.append('g')
        .attr('color', '#000')
        .call(yAxis)

    // grid
    svg.append('g')
        .attr('transform', `translate(0, ${h})`)
        .attr("stroke-dasharray","4")
        .attr('color', '#000')
        .attr("stroke-width", 0.2)
        .call(xAxisGrid)

    svg.append('g')
        .attr("stroke-dasharray","4")
        .attr('color', '#000')
        .attr("stroke-width", 0.2)
        .call(yAxisGrid)

    //data-points 
    svg.selectAll()
    .data(marketData?.data)
    .enter()
    .append("circle")
        .attr('class', 'data-points')
        .attr("fill", "rgb(93, 167, 202)")
        .attr("stroke", "none")
        .attr("cx", function(d) { return xScale(new Date(d.price_date)) })
        .attr("cy", function(d) { return yScale(d.modal_price) })
        .attr("r", 3)
        .text(d=>d.modal_price)

    // setting up data for svg
    const svg_data = svg.selectAll('.line')
        .data([marketData?.data])
    
    const path = svg_data.join('path')
                    .attr('d', d=>generateScaleLine(d))
                    .attr('fill', 'none')
                    .attr('stroke', 'rgb(93, 167, 202)')
                    .attr("stroke-width", 2)

    const length = path.node().getTotalLength();
       console.log(length)         
    path.attr("stroke-dasharray", length + " " + length)
    .attr("stroke-dashoffset", -length)
    .transition()
    .ease(d3.easeLinear)
    .attr("stroke-dashoffset", 0)
    .duration(1500)
        }

    useEffect(()=>{
       chart()       
      },[marketData, height, width])
    
    d3.selectAll('g').remove()
    d3.selectAll('path').remove()
    d3.selectAll('circle').remove()
    return(
        <div style={{padding:35, display:'flex', justifyContent:'center', alignItems:'center'}}>
            <svg ref={svgRef}></svg>
        </div>
    )
}