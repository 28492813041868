import React, {useState, useEffect, useRef} from 'react';

export function Countdown(props) {
    const Ref = useRef(null);
    const { setTimer, timer, start_date_time, end_date_time } = props
      // The state for our timer
      const getTimeRemaining = (e) => {
          const total = (Date.parse(e) - Date.parse(new Date(start_date_time))) 
          - (Date.parse(new Date()) - Date.parse(new Date(start_date_time)));
          const seconds = Math.floor((total / 1000) % 60);
          const minutes = Math.floor((total / 1000 / 60) % 60);
          const hours = Math.floor((total / 1000 / 60 / 60) % 24);
          const day = Math.floor((total / 1000 / 60 / 60 / 24) % 24);
          return {
              total, day, hours, minutes, seconds
          };
      }
    
    
      const startTimer = (e) => {
          let { total, day, hours, minutes, seconds } 
                      = getTimeRemaining(e);
          if (total >= 0) {
              setTimer(
                  (day > 9 ? day : '0' + day) + ' Day : ' +
                  (hours > 9 ? hours : '0' + hours) + ' h : ' +
                  (minutes > 9 ? minutes : '0' + minutes) + ' m : '+
                  (seconds > 9 ? seconds : '0' + seconds) + ' s'
              )
          }
          else{
            setTimer('Time is over')
          }
      }
    
      const clearTimer = (e) => {
          if (Ref.current) clearInterval(Ref.current);
          const id = setInterval(() => {
              startTimer(e);
          }, 1000)
          Ref.current = id;
      }
    
      const getDeadTime = () => {
          let deadline = new Date(end_date_time);     //end date time
          deadline.setSeconds(deadline.getSeconds()); 
          return deadline;
      }
    
      useEffect(() => {
          clearTimer(getDeadTime());
      }, [start_date_time, end_date_time]);
    
    //   const onClickReset = () => {
    //       clearTimer(getDeadTime());
    //   }
  
    return (
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                {
                timer==='Time is over' ? 
                    timer :
                    timer
                }
        </div>
    );

  }

