import React from "react";
import "../CSS/HomeScreen.css";
import { Accordion, Button } from "react-bootstrap";
import ScreenHeader from "../Components/CommonComponents/ScreenHeader";
import MarketPriceDashboard from "../Components/HomeScreenComponents/MarketPriceDashboard";
import ActiveListingSeller from "../Components/HomeScreenComponents/ActiveListingSeller";
import RecievedQuatation from "../Components/HomeScreenComponents/RecievedQuotation";
import { useNavigate } from "react-router-dom";
import ActiveListingBuyer from "../Components/HomeScreenComponents/ActiveListingBuyer";
import RequirementProductList from "../Components/HomeScreenComponents/RequirementProductList";
import ActiveAuctionList from "./Auction/ActiveAuctionList";
import { useSelector } from "react-redux";

const ScrollableTableDashboard = () => {
  const navigate = useNavigate();
  const {user} = useSelector(state=>state.auth)
  const {internationalization} = useSelector(state=>state.language)
  return (
      <div className="dashboard-table-width">
        <div className="homescreen-welcome-text">
          {internationalization.welcome_user_1} {user && user.first_name} {internationalization.welcome_user_2}
        </div>
        <div className="dashboard-section">
          <MarketPriceDashboard/>
        </div>
      <Accordion
        defaultActiveKey={window.innerWidth < 768 ? ["0"] : ["0","1","2"]}
        alwaysOpen
      >
        <Accordion.Item eventKey="1" className="border-0" style={{backgroundColor:'transparent'}}>
          <Accordion.Header >
            <ScreenHeader headerTitle={internationalization.received_enquiries} color={"green"} />
          </Accordion.Header>
          <Accordion.Body className="text-scrolleble">
            <ActiveListingSeller navigate={navigate} />
            <RecievedQuatation navigate={navigate} />                       
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="border-0" style={{backgroundColor:'transparent'}}>
          <Accordion.Header >
            <ScreenHeader headerTitle={internationalization.active_products} />
          </Accordion.Header>
          <Accordion.Body className="text-scrolleble">
            <ActiveListingBuyer navigate={navigate} />
            <RequirementProductList navigate={navigate} />
            <ActiveAuctionList navigate={navigate} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </div>
  );
};

export default ScrollableTableDashboard;
