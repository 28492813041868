import React from 'react'

export const TableDate = ({date}) => {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div style={{backgroundColor:'rgba(93, 167, 202, 0.4)',width:'fit-content', padding:5,borderRadius:5}}>
                {date}
        </div>
    </div>
  )
}
