import React from 'react';
import { ScreenLayout } from './ScreenLayout';
import ScreenHeader from '../Components/CommonComponents/ScreenHeader';
import './../CSS/ProfileScreen.css';
import { useSelector } from 'react-redux';

function ProfileScreen() {
  const {user, role} = useSelector((state)=> state.auth)
  const {internationalization} = useSelector((state)=> state.language)
  
  const ProfileComponent=()=>{
    return (
    <div>
        <ScreenHeader headerTitle={internationalization.profile}/>
        <hr style={{width:'100%'}}/>
        <div style={{display:'flex', flexDirection:'column'}}>
        <button type="submit" className='btn'>{internationalization.edit}</button>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.name}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>{user.username}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.gender}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>Male</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.contact}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>9595315645</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.email}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>{user.email}</p>
            </div>
        
        <hr width={'100%'}/>
        {/* ************************************************  Address Details ************************** */}
        <ScreenHeader headerTitle={'Address Details'}/>
        <button type="submit" className='btn'>Edit</button>
        <div>
        <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.address}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>{user.username}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.state}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>dsfsdf</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.district}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>9595315645</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.block}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>{user.username}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{width:'30%', color:'#808080'}}>{internationalization.village_city}</p>
                <p style={{marginRight:15}}>:</p>
                <p style={{fontWeight:500}}>{user.username}</p>
            </div>
        </div>
        <hr width={'100%'}/>
        {/* ******************************  Bank Details *************************************** */}
        <ScreenHeader headerTitle={'Bank Details'}/>
        <button type="submit" className='btn'>Edit</button>
        <div>
            <div style={{display:'flex', flexDirection:'row'}}>
                    <p style={{width:'30%', color:'#808080'}}>{internationalization.bank_name}</p>
                    <p style={{marginRight:15}}>:</p>
                    <p style={{fontWeight:500}}>name</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <p style={{width:'30%', color:'#808080'}}>{internationalization.branch_name}</p>
                    <p style={{marginRight:15}}>:</p>
                    <p style={{fontWeight:500}}>branch</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <p style={{width:'30%', color:'#808080'}}>{internationalization.account_number}</p>
                    <p style={{marginRight:15}}>:</p>
                    <p style={{fontWeight:500}}>9595315645</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <p style={{width:'30%', color:'#808080'}}>{internationalization.ifsc}</p>
                    <p style={{marginRight:15}}>:</p>
                    <p style={{fontWeight:500}}>2435234</p>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <p style={{width:'30%', color:'#808080'}}>{internationalization.account_holder_name}</p>
                    <p style={{marginRight:15}}>:</p>
                    <p style={{fontWeight:500}}>{user.username}</p>
                </div>
            </div>
            <hr width={'100%'}/>
        </div>
        {/* *********************************** end ************************************* */}
    </div>
    )
  }
  return (
    <ScreenLayout component={<ProfileComponent/>} />
  );
}

export default ProfileScreen;
