import React from 'react'
import userService from '../../Services/user.service'
import Razorpay from '../PaymentComponent/Razorpay'

const ProductStatusBaseAction = ({orderEnquiryId, status, subtotal, quantity ,submitEnquiryForm}) => {
  const UpdateOrderEnquiry=(e)=>{
    e.preventDefault()

    let data ={
      order_enquiry_id : orderEnquiryId,
      status : e.target.value
    }

    userService.updateOrderEnquiryStatus(data)
               .then((res)=>{
                alert('payment success')
                console.log(res.data)
                window.location.reload()
               })
               .catch((error)=>{
                console.log(error)
               })
  }
  if(status==='Available')
  {
    return (
    <div style={{display:'flex',flexDirection:'column',padding:10}}>
      <div style={{display:'flex',flexDirection:'column',padding:10}}>
          <div style={{width:'44%',borderRadius:5,height:40,display:'flex',flexDirection:'column',margin:10}}>
              <text style={{fontSize:24, fontWeight:500}}>Total</text>
          </div>

          <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
              <text style={{width:'50%', color:'#808080'}}>Subtotal</text>
              <text style={{marginRight:15}}>:</text>
              <p style={{fontWeight:500}}>
                {subtotal ? subtotal : 'Please Enter the Quantity and unit.'}
                </p> 
          </div>
      </div>
      <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
            <button className='sign-button'
            onClick={(e)=>submitEnquiryForm(e)}
            >
                Send
            </button> 
      </div>
    </div>
  )
}
  else if(status==='Active')
  {
    return (
      <div style={{display:'flex',flexDirection:'column',padding:10}}>
        <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
            <text style={{width:'50%', color:'#808080'}}>Quantity</text>
            <text style={{marginRight:15}}>:</text>
            <p style={{fontWeight:500}}>{quantity}</p> 
        </div>
        <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
            <text style={{width:'50%', color:'#808080'}}>Subtotal</text>
            <text style={{marginRight:15}}>:</text>
            <p style={{fontWeight:500}}>{subtotal}</p> 
        </div>
        <div 
            disabled
            style={{borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
            <button className='sign-button' style={{cursor:'not-allowed'}}>
                already send a quote. wait for seller 
            </button> 
        </div>
      </div>
  )
}
  else if(status==='Pending')
  {
    return (
      <div style={{display:'flex',flexDirection:'column',padding:10}}>
      <div style={{width:'100%',borderRadius:5,height:40,display:'flex',flexDirection:'column',margin:10}}>
          <p style={{fontSize:24, fontWeight:500}}>Seller accepted your order, Please make a payment to confirm your order.</p>
      </div>
      <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
            <text style={{width:'50%', color:'#808080'}}>Quantity</text>
            <text style={{marginRight:15}}>:</text>
            <p style={{fontWeight:500}}>{quantity}</p> 
        </div>
      <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
          <text style={{width:'50%', color:'#808080'}}>Subtotal</text>
          <text style={{marginRight:15}}>:</text>
          <p style={{fontWeight:500}}>{subtotal}</p> 
      </div>
      <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
          {/* <button className='sign-button' value='Confirmed' onClick={(e)=>UpdateOrderEnquiry(e)}> */}
              <Razorpay orderEnquiryId={orderEnquiryId} amount={subtotal}/>
          {/* </button>  */}
      </div>
    </div>
  )
}
  else if(status==='Confirmed')
  {
    return (
    <div style={{display:'flex',flexDirection:'column',padding:10}}>
          <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
                <button className='sign-button'
                >
                    Payment success. Order is being ready to dispatch
                </button> 
          </div>
    </div>
  )
}

  else if(status==='Cancelled')
  {
    return (
    <div style={{display:'flex',flexDirection:'column',padding:10}}>
          <div style={{ borderRadius:5, height:65,marginTop:25, display:'flex', justifyContent:'center',alignItems:'center'}}>
                <button className='sign-button'
                >
                    Order Cancelled by seller
                </button> 
          </div>
    </div>
  )
}

return(
  <>Loading...</>
)

}

export default ProductStatusBaseAction