
const setDefaultLanguage=async(language)=>{
    localStorage.setItem('language', language)
  }

async function getDefaultLanguage() {
   const language = localStorage.getItem('language')
    return language ? 
     {'language':language}
    :
     null
  }


  export default({
    setDefaultLanguage,
    getDefaultLanguage
  })
