import React from "react";
import { LANGUAGE_EN, LANGUAGE_HI } from "../actions/type";

const initialState = {
    internationalization:require(`../i18n/hi.json`),
    lang:LANGUAGE_HI
}

export default function(state = initialState ,action){
    const { type } = action;
    switch(type){
        case LANGUAGE_EN:
            return{
                internationalization: require(`../i18n/en.json`),
                lang:LANGUAGE_EN
            }
        case LANGUAGE_HI: 
            return{
                internationalization: require(`../i18n/hi.json`),
                lang:LANGUAGE_HI
            }
        default:
            return state;
    }
}